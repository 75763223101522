import React, { useState, useEffect, useRef } from 'react';
import {  BrowserRouter,  Route, Routes } from 'react-router-dom';

import Home from './views/Home';
import NotFound from './views/NotFound';

/* Usuarios */
import UsuariosCrear from './views/UsuariosCrear';
import UsuariosEditar from './views/UsuariosEditar';  
import UsuariosId from './views/UsuariosId';
import Usuarios from './views/Usuarios';

/* Empresa */
import EmpresasCrear from './views/EmpresasCrear';
import EmpresasId from './views/EmpresaId';
import EmpresasEditar from './views/EmpresasEditar';

/* Centros */
import CentrosCrear from './views/CentrosCrear';
import CentrosId from './views/CentrosId';
import Centros from './views/Centros';
import CentrosEditar from './views/CentrosEditar';

/* Inspecciones */
import LInspeccionesId from './views/LInspeccionesId';
import LInspeccionesCrear from './views/LInspeccionesCrear';
import LInspeccionesEditar from './views/LInspeccionesEditar';
import LInspeccionesEditarPhotos from './views/LInspeccionesEditarPhotos';
import LInspeccionesCargarPhotos from './views/LInspeccionesCargarPhotos';

/* Login */
import Login from './views/Login';
import Reset from './views/Reset';

/* Servicios */
import ServicioCrear from './views/ServiciosCrear';
import ServicioId from './views/ServicioId';
import ServicioEditar from './views/ServicioEditar';
import Servicios from './views/Servicios';

/*Documentos */
import DocumentosCrear from './views/DocumentosCrear';
import DocumentosEditar from './views/DocumentosEditar';

/* PDF */
import PortadaPDF from './pdf/service';

/* Context */
import Context  from './context';

const App = () => {

  // Recuperar los datos del usuario del localStorage
  const storedUser = localStorage.getItem('user');
  const [user, setUser] = useState(storedUser ? JSON.parse(storedUser) : []);

  // Función para reiniciar el temporizador de inactividad
  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);

    // Configurar un nuevo temporizador después de 30 minutos (1800000 milisegundos)
    inactivityTimer = setTimeout(() => {
      localStorage.removeItem('user');
      window.location.href = '/login';
    }, 30 * 60 * 1000); // 30 minutos en milisegundos
  };

  // Inicializar el temporizador de inactividad
  let inactivityTimer;
  useEffect(() => {
    resetInactivityTimer();

    // Agregar event listeners para detectar actividad
    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);

    // Limpia los event listeners cuando el componente se desmonta
    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
    };
  }, []);

  useEffect(() => {
    // Guardar los datos del usuario en el localStorage
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return ( 
    <div>
      <BrowserRouter>
        <Context.Provider value={{user, setUser}}>
        <Routes>
          <Route path="/" element={<Home/>} />          
          <Route path="*" element={<NotFound/>} />    
          <Route path="/usuarios-crear" element={<UsuariosCrear/>}/>    
          <Route path="/usuarios/:id" element={<UsuariosId/>}/>   
          <Route path="/usuarios" element={<Usuarios/>}/>
          <Route path="/usuarios/:id/editar" element={<UsuariosEditar/>}/> 
          <Route path="/empresas-crear" element={<EmpresasCrear/>}/> 
          <Route path="/empresas/:id" element={<EmpresasId/>}/>
          <Route path="/empresas/:id/editar" element={<EmpresasEditar/>} /> 
          <Route path="/empresas/:id/centros-crear" element={<CentrosCrear/>}/>
          <Route path="/empresas/:id/centros/:idCentro/crear-servicio" element={<ServicioCrear/>}/>
          <Route path="/empresas/:id/centros/:idCentro/crear-documento" element={<DocumentosCrear/>}/>
          <Route path='/empresas/:id/centros/:idCentro/editar-documento/:idDocumento' element={<DocumentosEditar/>}/>
          <Route path="/empresas/:id/centros/:idCentro" element={<CentrosId />} />
          <Route path="/empresas/:id/centros/:idCentro/editar" element={<CentrosEditar />} />
          {/*<Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio" element={<ServicioId />} />*/}         
          <Route path="/centros" element={<Centros/>}/>
          <Route path="/servicios" element={<Servicios/>}/>
          <Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio/editar" element={<ServicioEditar/>}/>
          <Route path="/empresas/:id/centros" element={<Centros/>}/>
          <Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio" element={<ServicioId/>}/>
          <Route path="/servicios/:idServicio" element={<ServicioId/>}/>
          <Route path="/pdf" element={<PortadaPDF/>}/>
          <Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio/inspecciones-linea/:idInspeccion" element={<LInspeccionesId/>}/>
          <Route path="/servicios/:idServicio/inspecciones-linea/:idInspeccion" element={<LInspeccionesId/>}/>
          <Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio/inspecciones-linea-crear/" element={<LInspeccionesCrear/>}/>
          <Route path="/servicios/:idServicio/inspecciones-linea-crear/" element={<LInspeccionesCrear/>}/>
          <Route path="/servicios/:idServicio/inspecciones-linea/:idInspeccion/editar" element={<LInspeccionesEditar/>}/>
          <Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio/inspecciones-linea/:idInspeccion/editar" element={<LInspeccionesEditar/>}/>
          <Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio/inspecciones-linea/:idInspeccion/editar-photos" element={<LInspeccionesEditarPhotos/>}/>
          <Route path="/empresas/:id/centros/:idCentro/servicios/:idServicio/inspecciones-linea/:idInspeccion/cargar-photos" element={<LInspeccionesCargarPhotos/>}/>
          <Route path="/login" element={<Login/>}/> 
          <Route path="/reset" element={<Reset/>}/>       
        </Routes>  
        </Context.Provider>    
      </BrowserRouter>    
    </div>       
  );
}

export default App;
