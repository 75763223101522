import { Container } from 'react-bootstrap';
import {Link} from 'react-router-dom';

const NotFound  = () => {
  return (
  
      <Container className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center text-center">
        <section >
          <h1>404</h1>
          <h2>Esta página no existe</h2>
          <a className="btn btn-primary">
            <Link to='/' style={{ color: '#FFFFFF', textDecoration: 'none', }} className="text-light">
              Regresar al Inicio
            </Link>            
          </a>                
        </section>
      </Container>  
  );
}

export default NotFound;
