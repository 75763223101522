/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

import { Card, Button, Form, Breadcrumb, BreadcrumbItem, Modal  } from 'react-bootstrap';

/* Rutas */
import {useParams, Link, useNavigate} from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Authentication */
import { getAuth} from "firebase/auth";

/*Firestore */
import { collection, doc, updateDoc, getDoc, getDocs, deleteField} from "firebase/firestore";
import {db} from '../firebase';

const UsuariosEditar = () =>{

  const navigate = useNavigate();

  const [empresas, setEmpresas] = useState([]); 

  /*Modal de espera mientras de Guarda Empresa */
  const [showModal, setShowModal] = useState(false);

  const getEmpresas = async () => {
    try{
      const querySnapshot = await getDocs(collection(db, "empresa"));
      const empresas = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setEmpresas(empresas);
    }catch(error){
      console.log("Error al cargar empresas",error);
    }
  };

  useEffect(() => {
    getEmpresas();
  }, []);

  const { id } = useParams(); 

  /* Aunteticacion */
  const auth = getAuth();
  // Configurar la inicialización de Firebase  

  const [users , setUsers] = useState({
    name: '',
    email: '',   
    rol: '',
    status: '',
    empresa: {
      id: '',
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {    
      const fetchUser = async () => {
          if (!id) return;
              const userRef = doc(db, "usuarios", id);        
          try {
              const userDoc = await getDoc(userRef);            
          if (userDoc.exists()) {
            if (userDoc.data().empresa == null) {
              const userDocEmpresa = { id: '' };
              setUsers({ id: userDoc.id, empresa: userDocEmpresa, ...userDoc.data() });
            }else {
              setUsers({ id: userDoc.id, empresa: userDoc.data().empresa, ...userDoc.data() });
            }              
          } else {
              setUsers(null);
          }
          } catch (error) {
          console.log('Error al obtener el usuario:', error);
          } finally {
          setIsLoading(false);
          }
      };  
    fetchUser();
  }, []); 

  if (isLoading) {
    return (
      <div>Cargando...</div>
    )
  } 

  const handleChange = async (e) =>{
    const { name, value } = e.target;       
    setUsers((prevState) => ({
        ...prevState,        
        [name]: value,
    })); 
  };  

  const handleSubmit = async () => {
    try {
      if (!users.name || !users.email || !users.rol) {
        alert("Rellene nos campos obligatorios");
        return;
      } 
      setShowModal(true)
  
      const userRef = doc(db, "usuarios", id);
  
      let docRef = {
        name: users.name,
        email: users.email,
        rol: users.rol,
        status: users.status,
      };
  
      if (users.rol === 'Cliente') {
        docRef.empresa = doc(db, "empresa", users.empresa.id);
      } else {
        // Puedes omitir la referencia a 'empresa' si no es un cliente
        docRef.empresa = deleteField();
      }
  
      await updateDoc(userRef, docRef);
      
      setShowModal(false)
      alert(`${users.name} ha sido guardado con éxito`);
      navigate(`/usuarios/${id}`);
    } catch (error) {
      console.error("Error al guardar usuario:", error);
    }
  };

  return (
      <>
        <Navbar/>
          <main id="main" className="main ">
          <div className="pagetitle">
            <h1>Editar Usuarios</h1>
            <Breadcrumb>
              <BreadcrumbItem >
                <Link to="/" style={{ textDecoration: 'none' }}>
                  Inicio
                </Link>                
              </BreadcrumbItem>
              <BreadcrumbItem>
              <Link to="/usuarios">
                Usuarios
              </Link>  
              </BreadcrumbItem>
              <BreadcrumbItem active >Editar Usuarios</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <section className="section">
          <div className="row" style={{display: 'flex', justifyContent: 'center', paddingRight: '20px'}}>
              <div class="col-12">
              <Card className="card" style={{ maxWidth:"100%"}}>
              <div className="card-body">
              <h5 className="card-title">Usuario</h5>

              {/* Vertical Form */}
              <Form >
                <Form.Group className="mb-3" >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" name='name' value={users.name} onChange={handleChange} placeholder="Ingresa el nombre" />
                </Form.Group>

                <Form.Group className="mb-3" >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" disabled name='email' value={users.email} onChange={handleChange}  placeholder="Ingresa el email" />
                </Form.Group>

                <Form.Group className="mb-3" >
                  <Form.Label>Rol</Form.Label>
                  <Form.Control as="select" name='rol' value={users.rol} onChange={handleChange} required  id="status">
                      <option value="">Selecciona una opción</option>
                      <option>Cliente</option>
                      <option>Colaborador</option>
                      <option>Administrador</option>
                      <option>Piloto</option>
                    </Form.Control>
                </Form.Group>
                {users.rol === 'Cliente' && ( // Condición para mostrar el campo empresa
                  <Form.Group className="mb-3" >
                  <Form.Label>Empresa</Form.Label>
                  <Form.Control as="select" name='empresa' value={users.empresa.id} onChange={handleChange} required  id="status">
                      <option value="">Selecciona una opción</option>
                      {empresas.map((empresa) => (
                        <option key={empresa.id} value={empresa.id}>{empresa.name}</option>
                      ))}                     
                    </Form.Control>
                </Form.Group>
                )}
                <Form.Group className="mb-3" >
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" name="status" value={users.status} onChange={handleChange} required  id="status">
                        <option value="">Selecciona una opción</option>
                        <option>Activo</option>
                        <option>Inactivo</option>
                    </Form.Control>
                </Form.Group>                 
                <div className="text-center" style={{ display:"flex", gap:"5px"}}>
                  <Button variant="primary" onClick={handleSubmit}>
                    Guardar
                  </Button>                  
                </div>
                <Modal
                  show={showModal}
                  centered
                  backdrop="static"
                  keyboard={false}
              >
                  <Modal.Header>
                      <Modal.Title>Actualizando Usuario</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      {`Los datos de ${users.name} se está actualizando, por favor espere...`}
                  </Modal.Body>
                  <Modal.Footer>
                  </Modal.Footer>
                </Modal>
              </Form>
              {/* Vertical Form */}
            </div>
          </Card> 
          </div>         
          </div>    
        </section>           
        </main>  
        <Footer/>             
      </>
  );
}

export default UsuariosEditar;
