/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

import { Link, useNavigate } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { collection, getDocs, doc, getDoc, } from "firebase/firestore";
import { db } from '../firebase';

/*Context */
import Context from "../context";

/* Export CSV */
import { mkConfig, generateCsv, download } from "export-to-csv";

const Home = () => {

  /* Navegar a través de las páginas */
  const navigate = useNavigate();

  //Fecha formato unix
  const unixTimeToDateString = (unixTime) => {
    try {
      const date = new Date(unixTime.seconds * 1000);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Agrega un cero inicial si es necesario
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      return `${formattedDay}-${formattedMonth}-${year}`;
    }
    catch (error) {
      return '';
    }
  }

  const { user } = useContext(Context);

  useEffect(() => {
    if (user.length === 0) {
      navigate('/login');     
    }else { 
      getDataUser();
      getCentros();
      fetchPost();
    }    
  }, [user]);

  const getDataUser = async () => {
    try {
      const docRef = doc(db, "usuarios", user[0].id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  };

  const [empresas, setEmpresas] = useState([]);
  const [centrosActivos, setCentrosActivos] = useState([]);
  const [serviciosActivos, setServiciosActivos] = useState(0);
  const [servicosIncompletos, setServiciosIncompletos] = useState(0);

  // is loading
  const [isLoading, setIsLoading] = useState(true);

  /* Empresa filtrada de la vista Cliente */
  const [filteredEmpresas, setFilteredEmpresas] = useState([]);

  /* Get Empresas */
  const fetchPost = async () => {
    let docSnapUser;

    try {
      const docRef = doc(db, "usuarios", user[0].id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        docSnapUser = docSnap.data();        
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }

    if (docSnapUser) {
      getDocs(collection(db, "empresa"))
        .then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          setEmpresas(newData);
          setFilteredEmpresas(newData.filter(empresa => empresa.id === docSnapUser.empresa.id));
        })
        .catch((error) => {
          console.log("Error fetching empresa documents:", error);
        });
    }
  }
  /* Get Servicios */
  const [servicios, setServicios] = useState([]);

  /* Get Centros */
  const getCentros = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "centro"));
      const querySnapshotServices = await getDocs(collection(db, "servicios"));
      const centrosArray = [];
      const centrosActives = [];

      querySnapshot.forEach((doc) => {
        centrosArray.push({ ...doc.data(), id: doc.id });
        // Lógica para gestionar centros activos
        if (doc.data().status === "Activo") {
          centrosActives.push(doc.data());
        }
      });

      let totalActivos = 0;
      let totalNoActivos = 0;
      let serviciosUsuario = [];

      querySnapshotServices.forEach((doc) => {
        if (doc.data().etapa === "Completado") {
          totalActivos++;
        } else if (doc.data().etapa === "Inspección") {
          totalNoActivos++;
        }
        if (doc.data().autoriza.id == user[0].id || doc.data().responsable.id == user[0].id || doc.data().revisa.id == user[0].id) {
          const centroName = centrosArray.find((centro) => centro.id === doc.data().centro.id).name;
          serviciosUsuario.push({ ...doc.data(), id: doc.id, centroName: centroName });
        }
      });
  
      setCentrosActivos(centrosActives);
      setServiciosActivos(totalActivos);
      setServiciosIncompletos(totalNoActivos);
      setServicios(serviciosUsuario);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching centers:", error);
    }
  };

  //Ordenar Servicios
  // Dentro de tu componente de React
  const [sortedColumn, setSortedColumn] = useState(null); // Columna actualmente ordenada
  const [sortDirection, setSortDirection] = useState("asc"); // Dirección de ordenamiento

  // Función para cambiar la dirección de ordenamiento
  const toggleSortDirection = () => {
    if (sortDirection === "asc") {
      setSortDirection("desc");
    } else {
      setSortDirection("asc");
    }
  };

  // Función para realizar la ordenación
  const sortServicios = (column) => {
    if (sortedColumn === column) {
      toggleSortDirection(); // Cambiar la dirección si ya se está ordenando por la misma columna
    } else {
      setSortedColumn(column);
      setSortDirection("asc"); // Comenzar ordenando de manera ascendente
    }

    const copyOfServicios = [...servicios]; // Hacer una copia del array original
    copyOfServicios.sort((a, b) => {
      if (column === "etapa" || column === "centroName") {
        // Lógica de ordenamiento para campos de texto
        return sortDirection === "asc"
          ? a[column].localeCompare(b[column])
          : b[column].localeCompare(a[column]);
      } else {
        // Lógica de ordenamiento para campos numéricos o de fecha
        return sortDirection === "asc" ? a[column] - b[column] : b[column] - a[column];
      }
    });

    // Actualizar el array de servicios con la nueva ordenación
    setServicios(copyOfServicios);
  };

  /* Paginacion Servicios */
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentServicios = servicios.slice(indexOfFirstItem, indexOfLastItem);

  /* <CSV></CSV> */
  const downloadCsv = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "empresas" });
    const filterEmpresaCsv = empresas.map(empresa => ({
      rut: empresa.rut,
      empresa: empresa.name,
      status: empresa.status,
    }));
    const csv = generateCsv(csvConfig)(filterEmpresaCsv);
    download(csvConfig)(csv);
  };

  const downloadCsvServicios = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "servicios" });
    const filterServiciosCsv = servicios.map(servicio => ({
      folio: servicio.folio,
      date: unixTimeToDateString(servicio.date),
      etapa: servicio.etapa,
      centroName: servicio.centroName,
    }));
    const csv = generateCsv(csvConfig)(filterServiciosCsv);
    download(csvConfig)(csv);
  };
  /* Render Is Loading*/
  if (isLoading) {
    return <div>Cargando...</div>
  }

  return (
    <div>
      <div>
        <Navbar />
        <main id="main" className="main" style={{ width: '100%' }}>
          <div className="pagetitle">
            <h1>Sistema de Gestión Pacific Rov</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Inicio</Link>
                </li>
              </ol>
            </nav>
          </div>
          {/* End Page Title */}
          {user[0].rol == "Administrador" ? (
            <>
            {/*Dashboard Home-------------------------------------------------------------------------------------------------------- */}
              {/* Rol Administrador */}
              <section className="section dashboard">
                <div className="row">
                  {/* DashBoard --------------------------------------*/}
                  <div className="col-lg-12 responsive-home">
                    <div className="d-flex flex-wrap">
                      {/* Centros Card */}
                      <div className="col-lg-4 col-md-6 mb-4 cards" style={{ paddingRight: '5px' }}>
                        <div className="card info-card sales-card">
                          <div className="card-body">
                            <h5 className="card-title">
                              Centros <span>| Activos</span>
                            </h5>
                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <i className="bi bi-grid-3x2" />
                              </div>
                              <div className="ps-3">
                                <h6>{centrosActivos.length}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Centros Card */}
                      {/* Ins Completadas Card */}
                      <div className="col-lg-4 col-md-6 mb-4 cards" style={{ paddingRight: '5px' }}>
                        <div className="card info-card revenue-card">
                          <div className="card-body">
                            <h5 className="card-title">
                              Servicios <span>| Completados</span>
                            </h5>
                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <i className="bi bi-file-earmark-check-fill" />
                              </div>
                              <div className="ps-3">
                                <h6>{serviciosActivos}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Ins Completadas Card */}
                      {/* Ins en proceso Card */}
                      <div className="col-lg-4 col-md-6 mb-4 cards" style={{ paddingRight: '5px' }}>
                        <div className="card info-card customers-card">
                          <div className="card-body">
                            <h5 className="card-title">
                              Servicios <span>| En Proceso</span>
                            </h5>
                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <i className="bi bi-file-earmark-check" />
                              </div>
                              <div className="ps-3">
                                <h6>{servicosIncompletos}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Ins en Proceso Card */}
                    </div>
                  </div>
                  {/* End Dashboard ----------------------------------*/}
                </div>
                <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                  {/* Tabla Empresas */}
                  <div className="col-lg-12 " >
                    <div className="card recent-sales overflow-auto table-responsive">
                      <div className="filter">
                        <a className="icon" href="#" data-bs-toggle="dropdown">
                          <i className="bi bi-three-dots" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">
                          Empresas
                        </h5>
                        <table className="table table-borderless datatable">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" className="d-none d-sm-table-cell">RUT</th>
                              <th scope="col">Empresa</th>
                              <th scope="col">Status</th>
                              <th scope="col">Entrar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {empresas.map((empresa, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <div>#{index + 1}</div>
                                </th>
                                <td className="d-none d-sm-table-cell">{empresa.rut}</td>
                                <td>
                                  <div className="text-primary">
                                    <Link to={`/empresas/${empresa.id}`} > 
                                    {empresa.name}
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <span className={`badge ${empresa.status === 'Activo' ? 'bg-success' : 'bg-danger'}`}>{empresa.status}</span>
                                </td>
                                <td>
                                  <button className="btn btn-primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/empresas/${empresa.id}`)}>Entrar</button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/*Exportar CSV*/}
                        <div className="d-flex justify-content">
                          <button className="btn btn-primary" onClick={downloadCsv}>Exportar CSV</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Empresas */}
                </div>
              </section>
            </>
          ) : user[0].rol === "Colaborador" ? (
            <>
              {/* Rol Colaborador */}
              <section className="section dashboard">            
                <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                  {/* Empresas */}
                  <div className="col-12 " >
                    <div className="card recent-sales overflow-auto table-responsive">
                      <div className="filter">
                        <a className="icon" href="#" data-bs-toggle="dropdown">
                          <i className="bi bi-three-dots" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">
                          Servicios
                        </h5>
                        <table className="table table-borderless datatable">
                          <thead>
                            <tr>
                              <th scope="col" className="d-none d-sm-table-cell" >#</th>
                              <th scope="col" onClick={() => sortServicios("folio")}>Folio</th>
                              <th scope="col" className="d-none d-sm-table-cell" onClick={() => sortServicios("date")}>Fecha</th>
                              <th scope="col" onClick={() => sortServicios("etapa")}>Status</th>
                              <th scope="col" onClick={() => sortServicios("centroName")}>Centro</th>
                              <th scope="col">Entrar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentServicios.map((servicio, index) => (
                              <tr key={index}>
                                <th scope="row" className="d-none d-sm-table-cell">
                                  <div >#{index + 1}</div>
                                </th>
                                <td>{servicio.folio}</td>
                                <td className="d-none d-sm-table-cell">
                                  <div className="text-primary" >
                                    {unixTimeToDateString(servicio.date)}
                                  </div>
                                </td>
                                <td>
                                  <span className={`badge ${servicio.etapa === 'Completado' ? 'bg-success' : 'bg-danger'}`}>{servicio.etapa}</span>
                                </td>
                                <td>{servicio.centroName}</td>
                                <td>
                                  <button className="btn btn-primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/servicios/${servicio.id}`)}>Entrar</button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="pagination">
                          <button
                            className="btn btn-link"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            Anterior
                          </button>
                          <button
                            className="btn btn-link"
                            disabled={indexOfLastItem >= servicios.length}
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            Siguiente
                          </button>
                        </div>
                        <div className="d-flex justify-content">
                          <button className="btn btn-primary" onClick={downloadCsvServicios}>Exportar CSV</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Empresas */}
                </div>
              </section>
            </>
          ) : user[0].rol === "Cliente" ? (
            <>
              {/* Rol Cliente */}
              <section className="section dashboard">
                <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                  {/* Empresas */}
                  <div className="col-12 " >
                    <div className="card recent-sales overflow-auto table-responsive">
                      <div className="filter">
                        <a className="icon" href="#" data-bs-toggle="dropdown">
                          <i className="bi bi-three-dots" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">
                          Empresas
                        </h5>
                        <table className="table table-borderless datatable">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" className="d-none d-sm-table-cell"> RUT</th>
                              <th scope="col">Empresa</th>
                              <th scope="col">Status</th>
                              <th scope="col">Entrar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={1}>
                              <th scope="row">
                                <div>#{1}</div>
                              </th>
                              <td className="d-none d-sm-table-cell" >{filteredEmpresas[0].rut}</td>
                              <td>
                                <div className="text-primary">
                                  {filteredEmpresas[0].name}
                                </div>
                              </td>
                              <td>
                                <span className={`badge ${filteredEmpresas[0].status === 'Activo' ? 'bg-success' : 'bg-danger'}`}>{filteredEmpresas[0].status}</span>
                              </td>
                              <td>
                                <button className="btn btn-primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/empresas/${filteredEmpresas[0].id}`)}>Entrar</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* End Empresas */}
                </div>
              </section>
            </>

          ) : null}            

        </main>
        {/* End #main */}
      </div>
      <Footer />
    </div>

  );
}

export default Home;
