/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

/* Bootstrap */
import { Button, Modal } from 'react-bootstrap';

/* Tabs */
import 'react-tabs/style/react-tabs.css';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { collection, doc, getDoc, query, where, getDocs, deleteDoc, } from "firebase/firestore";
import { db } from '../firebase';

/* Rutas */
import { useParams } from 'react-router-dom';

/* Link */
import { Link, useNavigate } from 'react-router-dom';

/*Context */
import Context from "../context";

/*PDF */
import servicePDF from '../pdf/service';

/* Export CSV */
import { mkConfig, generateCsv, download } from "export-to-csv";

const ServiciosId = () => {

    const { user } = useContext(Context);    

    // Modal
    const [showModal, setModalShow] = useState(false);

    const unixTimeToDateString = (unixTime) => {
        try {
            const date = new Date(unixTime.seconds * 1000);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            // Agrega un cero inicial si es necesario
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;

            return `${formattedDay}-${formattedMonth}-${year}`;
        }
        catch (error) {
            return '';
        }
    }

    const navigate = useNavigate();

    const { id, idCentro, idServicio } = useParams()

    const [isLoading, setIsLoading] = useState(true);

    //Numero random para que se cargue bien la imagen
    const randomNumber = Math.floor(Math.random() * 10000);

    // Datos
    const [centro, setCentro] = useState([]);
    const [empresa, setEmpresa] = useState([]);
    const [servicio, setServicio] = useState([]);
    const [inspecciones, setInspecciones] = useState([]);
    const [documentos, setDocumentos] = useState([]); 

    const [responsable, setResponsable] = useState([]);
    const [revisa, setRevisa] = useState([]);
    const [autoriza, setAutoriza] = useState([]);

    // Ordenamiento
    const [sortType, setSortType] = useState("asc");
    const [sortField, setSortField] = useState("");

    //Paginacion
    const [currentPage, setCurrentPage] = useState(1);  

    useEffect(() => {
        const fetchDataCentro = async () => {
            const centroRef = doc(db, "centro", idCentro)
            const centroSnapshot = await getDoc(centroRef)
            if (!centroSnapshot.exists()) return;
            const centroData = centroSnapshot.data()
            setCentro(centroData)
            const empresaRef = doc(db, "empresa", centroData.empresa.id)
            const empresaSnapshot = await getDoc(empresaRef)
            if (!empresaSnapshot.exists()) return;
            const empresaData = empresaSnapshot.data()
            setEmpresa(empresaData)

            setIsLoading(false);
        }
        fetchDataCentro();
    }, [])

    useEffect(() => {
        const fetchDataServicio = async () => {
            const servicioRef = doc(db, "servicios", idServicio)
            const servicioSnapshot = await getDoc(servicioRef)

            if (!servicioSnapshot.exists()) return;
            const servicioData = servicioSnapshot.data()
            console.log(servicioData.revisa.id)
            setServicio(servicioData)

            const revisa = doc(db, "usuarios", servicioData.revisa.id)
            const revisaSnapshot = await getDoc(revisa)
            const revisaData = revisaSnapshot.data();
            setRevisa(revisaData);

            const autoriza = doc(db, "usuarios", servicioData.autoriza.id);
            const autorizaSnapshot = await getDoc(autoriza);
            const autorizaData = autorizaSnapshot.data();
            setAutoriza(autorizaData);

            const responsable = doc(db, "usuarios", servicioData.responsable.id);
            const responsableSnapshot = await getDoc(responsable);
            const responsableData = responsableSnapshot.data();
            setResponsable(responsableData);

            const centro = doc(db, "centro", servicioData.centro.id);
            const centroSnapshot = await getDoc(centro);
            const centroData = centroSnapshot.data();
            setCentro(centroData)
            setIsLoading(false);

        }
        const fetchDataInspecciones = async () => {
            const inspeccionesRef = collection(db, "linspecciones")
            const servicioRef = doc(db, "servicios", idServicio);
            const q = query(inspeccionesRef, where("folio_servicio", "==", servicioRef))
            const inspeccionesSnapshot = await getDocs(q)
            const inspeccionesData = inspeccionesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            const inspeccionesDataSort = inspeccionesData.sort((a, b) => a.folio - b.folio);
            setInspecciones(inspeccionesDataSort)
            setIsLoading(false);
        }

        const fecthDocumentos = async () => {
            const centroRef = doc(db, "centro", idCentro);
            const documentosRef = collection(db, "documentos");
            const documentosQuery = query(documentosRef, where("centro", "==", centroRef));
            try {
                const documentosSnapshot = await getDocs(documentosQuery);
                const documentosList = documentosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setDocumentos(documentosList);
            }
            catch (error) {
                console.log('Error al obtener los documentos:', error);
            }
        };
        fecthDocumentos();
        fetchDataInspecciones()
        fetchDataServicio();       
    }, [])

    if (isLoading) {
        return (
            <div>Cargando...</div>
        )
    }

    // Función para eliminar una inspección
    const deleteInspeccion = async (idInspeccion) => {
        // Muestra un cuadro de diálogo de confirmación al usuario
        const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar esta inspección?");

        if (confirmDelete) {
            const inspeccionRef = doc(db, "linspecciones", idInspeccion);

            try {
                await deleteDoc(inspeccionRef);
                alert('Inspección eliminada correctamente.');
                window.location.reload();
            } catch (error) {
                console.error('Error al eliminar la inspección: ', error);
            }
        } else {
            console.log('Eliminación cancelada por el usuario.');
        }
    };

    const handleSort = (field) => {
        // Verifica si se está ordenando por el mismo campo para invertir el tipo de ordenamiento
        const newSortType = sortField === field && sortType === "asc" ? "desc" : "asc";

        // Actualiza el campo y el tipo de ordenamiento
        setSortField(field);
        setSortType(newSortType);

        // Realiza el ordenamiento de los centros según el campo y el tipo de ordenamiento
        const sortedServicios = [...inspecciones].sort((a, b) => {
            const valueA = getValueByField(a, field);
            const valueB = getValueByField(b, field);

            if (valueA < valueB) {
                return newSortType === "asc" ? -1 : 1;
            }
            if (valueA > valueB) {
                return newSortType === "asc" ? 1 : -1;
            }
            return 0;
        });
        setInspecciones(sortedServicios);
    }

    // Función auxiliar para obtener el valor de un campo anidado
    const getValueByField = (object, field) => {
        const fields = field.split(".");
        let value = object;
        fields.forEach((f) => {
            value = value[f];
        });
        return value;
    };

    /* <CSV></CSV> */
    const downloadCsv = () => {
        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "inspecciones" });
        const filterInspeccionesCsv = inspecciones.map(inspeccion => ({
            folio: inspeccion.folio,
            linea: inspeccion.nombre,
            comentario: inspeccion.comentario,
            observacion: inspeccion.observacion,
            status: inspeccion.status,

        }));
        const csv = generateCsv(csvConfig)(filterInspeccionesCsv);
        download(csvConfig)(csv);
    };
    const generatePDF = async () => {
        setModalShow(true);
    };

    /* Paginación */
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentInspecciones = inspecciones.slice(indexOfFirstItem, indexOfLastItem);    

    // Filtrar inspecciones observadas
    const inspeccionesObservadasCriticas = inspecciones.filter((inspeccion) => inspeccion.observacion !=="");       

    const inspeccionesObservadasLeves = inspecciones.filter((inspeccion) => inspeccion.hasOwnProperty('observacion_leve') && inspeccion.observacion_leve !== "");

    const inspeccionesObservadas = inspeccionesObservadasCriticas.length + inspeccionesObservadasLeves.length; 

    // Filtrar inspecciones sin observaciones
    const inspeccionesSinObservaciones = inspecciones.filter((inspeccion) => inspeccion.status === "Sin Observación");

    return (
        <>
            <Navbar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Servicio</h1>
                    {/*User Rol */}
                    {user[0].rol === "Administrador" || user[0].rol === "Cliente" ? (
                        <>
                            {/* Contenido para el rol de Administrador */}
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Inicio</Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/empresas/${id}`}>{empresa.name}</Link></li>
                                    <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}`}>{centro.name}</Link></li>
                                    <li className="breadcrumb-item active">{`Servicio N°${servicio.folio}`}</li>
                                </ol>
                            </nav>
                        </>
                    ) : user[0].rol === "Colaborador" ? (
                        <>
                            {/* Contenido para el rol de Colaborador */}
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Inicio</Link>
                                    </li>
                                    <li className="breadcrumb-item active">{`Servicio N°${servicio.folio}`}</li>
                                </ol>
                            </nav>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <section className="section profile">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body pt-3">
                                    {/* Bordered Tabs */}
                                    <ul className="nav nav-tabs nav-tabs-bordered">
                                        <li className="nav-item">
                                            <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">
                                                Descripción
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-2">
                                        <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                            <h5 className="card-title">Servicio</h5>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label ">Folio</div>
                                                <div className="col-lg-9 col-md-8">{servicio.folio}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Centro</div>
                                                <div className="col-lg-9 col-md-8">{centro.name}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Fecha de Creación</div>
                                                <div className="col-lg-9 col-md-8">{unixTimeToDateString(servicio.date)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Fecha inicio de Faena</div>
                                                <div className="col-lg-9 col-md-8">{unixTimeToDateString(servicio.date_inicio_faena)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Fecha término de Faena</div>
                                                <div className="col-lg-9 col-md-8">{unixTimeToDateString(servicio.date_fin_faena)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Etapa</div>
                                                <div className="col-lg-9 col-md-8">{servicio.etapa}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Tipo</div>
                                                <div className="col-lg-9 col-md-8">{servicio.tipo}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Piloto Rov</div>
                                                <div className="col-lg-9 col-md-8">{responsable.name}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Usuario que autoriza</div>
                                                <div className="col-lg-9 col-md-8">{autoriza.name}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Usuario que revisa</div>
                                                <div className="col-lg-9 col-md-8">{revisa.name}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Rov utilizado</div>
                                                <div className="col-lg-9 col-md-8">{servicio.rov}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Conclusiones</div>
                                                <div className="col-lg-9 col-md-8">{servicio.comentario}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Informe</div>
                                                <div className="col-lg-9 col-md-8">
                                                <Link to={`${servicio.informe}?=${randomNumber}`} target={"_blank"}>
                                                    Descargar
                                                </Link>
                                                </div>
                                            </div>
                                            <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                                {user[0].rol === "Administrador" ? (
                                                    <>
                                                        {/* Contenido para el rol de Administrador */}
                                                        <Button variant="primary" onClick={() => navigate(`editar`)}>
                                                            Editar
                                                        </Button>
                                                        <Button variant="primary" onClick={() => generatePDF()}>
                                                            Generar Reporte
                                                        </Button>
                                                    </>
                                                ) : user[0].rol === "Colaborador" ? (
                                                    <>
                                                        {/* Contenido para el rol de Colaborador */}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                               
                                                {showModal && (
                                                    <Modal
                                                        show={showModal}
                                                        centered
                                                        backdrop="static"
                                                        keyboard={false}
                                                        onEntered={() => {
                                                            if (showModal) {
                                                                servicePDF(servicio, centro, empresa, inspecciones,  documentos, responsable.name, idServicio);
                                                            }
                                                        }}
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Generando PDF</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            {`Esto podría tardar unos minutos. Por favor no cierre esta página`}
                                                        </Modal.Body>
                                                        <Modal.Footer >

                                                        </Modal.Footer>
                                                    </Modal>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Cards */}
                    <section className="section dashboard">
                        <div className="row"></div>
                        <div className="col-lg-12 responsive-home">
                            <div className="d-flex flex-wrap">
                                <div className="col-lg-4 col-md-6 mb-4 cards" style={{ paddingRight: '5px' }}>
                                    <div className="card info-card revenue-card">
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Líneas <span>| Inspeccionadas</span>
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i style={{ color: "green" }} className="bi bi-file-earmark-check-fill" />
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{inspecciones.length}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4 cards" style={{ paddingRight: '5px' }}>
                                    <div className="card info-card inspecciones-card">
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Inspecciones <span>| Observadas</span>
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i className="bi bi-file-earmark-check-fill" />
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{inspeccionesObservadas}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4 cards" style={{ paddingRight: '5px' }}>
                                    <div className="card info-card revenue-card">
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                Inspecciones <span>| Sin Observación</span>
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <div style={{ color: "green" }} className="card-icon rounded-circle d-flex align-items-center justify-content-center green">
                                                    <i style={{ color: "green" }} className="bi bi-file-earmark-check" />
                                                </div>
                                                <div className="ps-3">
                                                    <h6>{inspeccionesSinObservaciones.length}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Inspecciones */}
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12 " >
                            <div className="card recent-sales overflow-auto table-responsive">
                                <div className="filter">
                                    <a className="icon" href="#" data-bs-toggle="dropdown">
                                    </a>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Inspecciones
                                    </h5>
                                    <table className="table table-borderless datatable">
                                        <thead>
                                            <tr style={{ textAlign: 'center' }}>
                                                <th scope="col" onClick={() => handleSort("folio")}>Folio</th>
                                                <th scope="col" onClick={() => handleSort("nombre")}>Línea</th>
                                                <th scope="col" style={{ width: "28%" }} className="d-none d-md-table-cell d-md-block" >Comentario</th>
                                                <th scope="col" style={{ width: "28%" }}  className="d-none d-md-table-cell d-md-block" >Observación</th>
                                                <th scope="col" onClick={() => handleSort("etapa")}  className="d-none d-md-table-cell d-md-block" >Estado</th>
                                                <th scope="col">Entrar</th>
                                                {user[0].rol === "Administrador" ? (
                                                    <>
                                                        <th scope="col">Eliminar</th>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentInspecciones.map((inspeccion) => (
                                                <tr key={inspeccion.folio} style={{ textAlign: 'center' }}>
                                                    <td>{inspeccion.folio} </td>
                                                    <td>{inspeccion.nombre}</td>
                                                    <td style={{ textAlign: "left", fontSize: '0.85rem' }}  className="d-none d-md-table-cell d-md-block" >{inspeccion.comentario}</td>
                                                    <td style={{ textAlign: "left", fontSize: '0.85rem' }}  className="d-none d-md-table-cell d-md-block" >
                                                    {inspeccion.observacion_leve ? inspeccion.observacion_leve :
                                                    inspeccion.observacion}
                                                    </td>
                                                    <td  className="d-none d-md-table-cell d-md-block" >
                                                        <span className={`badge ${inspeccion.status === "Sin Observación" ? "bg-success" :
                                                            inspeccion.status === "Observación Leve" ? "bg-warning" :
                                                                inspeccion.status === "Observación Crítica" ? "bg-danger" : 
                                                                inspeccion.status === "Observación Levantada" ? "bg-primary" : ""
                                                            }`}>
                                                            {inspeccion.status}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        {user[0].rol === "Administrador" || user[0].rol === "Cliente" ? (
                                                            <>
                                                                {/* Contenido para el rol de Administrador */}
                                                                <Button variant="primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}/inspecciones-linea/${inspeccion.id}`)}>
                                                                    Entrar
                                                                </Button>
                                                            </>
                                                        ) : user[0].rol === "Colaborador" ? (
                                                            <>
                                                                {/* Contenido para el rol de Colaborador */}
                                                                <Button variant="primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/servicios/${idServicio}/inspecciones-linea/${inspeccion.id}`)}>
                                                                    Entrar
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </td>
                                                    {user[0].rol === "Administrador" ? (
                                                        <>
                                                            <td>
                                                                <Button variant="danger" style={{ fontSize: '0.65rem' }} onClick={() => deleteInspeccion(inspeccion.id)}>
                                                                    Eliminar
                                                                </Button>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    <div className="pagination">
                                        <button
                                            className="btn btn-link"
                                            disabled={currentPage === 1}
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                        >
                                            Anterior
                                        </button>
                                        <button
                                            className="btn btn-link"
                                            disabled={indexOfLastItem >= inspecciones.length}
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                        >
                                            Siguiente
                                        </button>
                                    </div>    
                                    <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                        {/*User Rol */}
                                        {user[0].rol === "Administrador" ? (
                                            <>
                                                {/* Contenido para el rol de Administrador */}
                                                <Button variant="primary" onClick={() => navigate(`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}/inspecciones-linea-crear/`)}>
                                                    Crear Inspección
                                                </Button>
                                            </>
                                        ) : user[0].rol === "Colaborador" ? (
                                            <>
                                                {/* Contenido para el rol de Colaborador */}
                                                <Button variant="primary" onClick={() => navigate(`/servicios/${idServicio}/inspecciones-linea-crear/`)}>
                                                    Crear Inspección
                                                </Button>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="d-flex justify-content">
                                            <button className="btn btn-primary" onClick={downloadCsv}>Exportar CSV</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
            </main>
        </>
    )
}

export default ServiciosId
