const convertirLatitudDMSaDecimal = (latitudDMS) => {
    const partes = latitudDMS.match(/(\d+)°(\d+)\'([\d.]+)\"([NS])$/);    
    const grados = parseFloat(partes[1]);
    const minutos = parseFloat(partes[2]);
    const segundos = parseFloat(partes[3]);
    const direccion = partes[4] === 'N' ? 1 : -1;
    const latitudDecimal = direccion * (grados + (minutos / 60) + (segundos / 3600));
    return latitudDecimal;
};

const convertirLongitudDMSaDecimal = (longitudDMS) => {
    const partes = longitudDMS.match(/(\d+)°(\d+)\'([\d.]+)\"([EW])$/);   
    const grados = parseFloat(partes[1]);
    const minutos = parseFloat(partes[2]);
    const segundos = parseFloat(partes[3]);
    const direccion = partes[4] === 'E' ? 1 : -1;
    const longitudDecimal = direccion * (grados + (minutos / 60) + (segundos / 3600));
    return longitudDecimal;
};

const convertirLatitudDecimalADMS = (latitudDecimal) => {
if (latitudDecimal < -90 || latitudDecimal > 90) {
    throw new Error('La latitud debe estar en el rango de -90 a 90 grados.');
}  
const grados = Math.floor(latitudDecimal);
const minutosDecimal = (latitudDecimal - grados) * 60;
const minutos = Math.floor(minutosDecimal);
const segundos = ((minutosDecimal - minutos) * 60).toFixed(1);

const direccion = latitudDecimal >= 0 ? 'N' : 'S';

return `${Math.abs(grados)}°${minutos}'${segundos}"${direccion}`;
};

const convertirLongitudDecimalADMS = (longitudDecimal) => {
if (longitudDecimal < -180 || longitudDecimal > 180) {
    throw new Error('La longitud debe estar en el rango de -180 a 180 grados.');
}

const grados = Math.floor(longitudDecimal);
const minutosDecimal = (longitudDecimal - grados) * 60;
const minutos = Math.floor(minutosDecimal);
const segundos = ((minutosDecimal - minutos) * 60).toFixed(1);

const direccion = longitudDecimal >= 0 ? 'E' : 'W';

return `${Math.abs(grados)}°${minutos}'${segundos}"${direccion}`;
};  

export { convertirLatitudDMSaDecimal, 
        convertirLongitudDMSaDecimal,
        convertirLatitudDecimalADMS,
        convertirLongitudDecimalADMS }