/* React */
import React, { useState } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

/* React Bootstrap  */
import { Card, Button, Form, Breadcrumb, BreadcrumbItem, Modal } from 'react-bootstrap';

/* React Router  */
import { Link, useNavigate } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { collection, doc, addDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../firebase';
import { getStorage, ref, uploadBytes} from "firebase/storage";

const EmpresasCrear = () => {

    /* Forms */
    const [formData, setFormData] = useState({
        name: '',
        rut: '',
        address: '',
        status: '',
        file: '',
        fileExtension: '',
    })

    /*Modal de espera mientras de Guarda Empresa */
    const [showModal, setShowModal] = useState(false);

    /* navigate */
    const navigate = useNavigate();

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "rut") {
            // Formatear el RUT
            const rutFormatted = value
                .replace(/[^\dkK]/gi, "")
                .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
            setFormData((prevState) => ({
                ...prevState,
                [name]: rutFormatted,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    /* FileUpload */
    const storage = getStorage();

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        // Verificar si la extensión es jpg o png
        if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
            // Continuar con la carga del archivo
            setFormData((prevState) => ({
                ...prevState,
                file: file,
                fileExtension: fileExtension,
            }));
        } else {
            alert('Solo se permiten imágenes JPG o PNG');
            // Resetear el input de archivo
            event.target.value = null;
        }
    };

    /* Submit */
    const handleSubmit = async () => {
        try {
            console.log("handleSubmit function called.");
            if (!formData.name || !formData.rut || !formData.address || !formData.file) {
                alert('Los campos son obligatorios');
                return;
            }
            setShowModal(true);

            const docRef = await addDoc(collection(db, "empresa"), {
                name: formData.name,
                rut: formData.rut,
                address: formData.address,
                status: "Activo",
                date_create: serverTimestamp()
            });

            const docId = docRef.id;
                                             
            const storageRef = ref(storage, `empresa/${docId}.${formData.fileExtension}`);
            await uploadBytes(storageRef, formData.file);
            const downloadUrl = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/empresa/${docId}_640x360.${formData.fileExtension}`

            /* Update logo */
            const empresaRef = doc(db, "empresa", docId);
            const data = { logo: downloadUrl };


            await updateDoc(empresaRef, data);

            setShowModal(false)
            alert(`La empresa ${formData.name} ha sido guardada`);
            navigate('/')
        } catch (error) {
            alert(`Error al actualizar Empresa: ${error}`);
        }
    };

    console.log(formData)

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Crear Empresa</h1>
                    <Breadcrumb>
                        <BreadcrumbItem >
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                Inicio
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>Empresa</BreadcrumbItem>
                        <BreadcrumbItem active to="/empresas-crear">Crear Empresa</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Empresa</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Nombre<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Ingresa el nombre" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Rut<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="rut" value={formData.rut} onChange={handleChange} placeholder="Ej: 99.999.999-9" maxLength={12} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Dirección<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Ingresa dirección" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Logo Empresa<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="file" name="document" required id="document" onChange={(event) => handleFileUpload(event, "docId")} />
                                        </Form.Group>
                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>                                            
                                            <Modal
                                                show={showModal}
                                                centered
                                                backdrop="static"
                                                keyboard={false}
                                            >
                                                <Modal.Header>
                                                    <Modal.Title>Guardando Empresa</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {`La empresa ${formData.name} se está guardando, por favor espere...`}
                                                </Modal.Body>
                                                <Modal.Footer>

                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </Form>
                                    {/* Vertical Form */}
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default EmpresasCrear;