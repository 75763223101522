/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

import { Card, Button, Form, Modal } from 'react-bootstrap';

import { set, useForm } from 'react-hook-form';

/* React Router  */
import { Link, useNavigate, useParams } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Time */
import moment from 'moment-timezone';

/*Firestore */
import { doc, updateDoc, getDoc, } from "firebase/firestore";
import { db } from '../firebase';
import { getStorage, ref, uploadBytes, } from "firebase/storage";

/* Toast */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LInspeccionesCargarPhotos = () => {

    /* Parametros */
    const { id, idCentro, idServicio, idInspeccion } = useParams();

    /*Time */
    const date = moment().tz('America/Santiago').toDate();

    /*Modal de espera mientras de Editar Inspección */
    const [showModal, setShowModal] = useState(false);

    /* Formulario de puntos cardinales */
    const { register, watch, formState: { errors } } = useForm();

    /* Forms */
    const [formData, setFormData] = useState({
        name: '',
        composicion: '',
        anclaje: '',
        suelo: '',
        profundidad: '',
        video_url: '',
        obs_photo1: '',
        obs_photo2: '',
        obs_photo3: '',
        obs_photo4: '',
        obs_photo5: '',
        obs_photo6: '',
        obs_photo7: '',
        obs_photo8: '',
        obs_photo9: '',
        obs_photo10: '',
        observaciones: '',
        comentarios: '',
        lev_observaciones: '',
    })

    /* Obtener los datos de la inspección */
    const [inspeccion, setInspeccion] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [empresa, setEmpresa] = useState([]);
    const [centro, setCentro] = useState([]);
    const [servicio, setServicio] = useState([]);

    useEffect(() => {
        // Función para manejar la finalización de las consultas
        let completedQueries = 0;

        const handleQueryCompletion = () => {
            completedQueries++;
            if (completedQueries === 4) {
                setIsLoading(false);
            }
        };

        // Consulta para obtener los datos de la empresa
        const getEmpresa = async () => {
            try {
                const empresaRef = doc(db, "empresa", id);
                const empresaSnap = await getDoc(empresaRef);

                // Verificar si el documento existe
                if (empresaSnap.exists()) {
                    const empresaData = empresaSnap.data();
                    setEmpresa(empresaData);
                } else {
                    console.log("No existe la empresa");
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener la empresa:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos del centro
        const getCentro = async () => {
            try {
                const centroRef = doc(db, "centro", idCentro);
                const centroSnap = await getDoc(centroRef);

                if (centroSnap.exists()) {
                    const centroData = centroSnap.data();
                    setCentro(centroData);
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener el centro:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos del servicio
        const getServicio = async () => {
            try {
                const servicioRef = doc(db, "servicios", idServicio);
                const servicioSnap = await getDoc(servicioRef);

                if (servicioSnap.exists()) {
                    const servicioData = servicioSnap.data();
                    setServicio(servicioData);
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener el servicio:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos de la inspección
        const fetchInspeccion = async () => {
            if (!idInspeccion) return;

            try {
                const inspeccionRef = doc(db, "linspecciones", idInspeccion);
                const inspeccionDoc = await getDoc(inspeccionRef);

                if (inspeccionDoc.exists()) {
                    const inspeccionData = inspeccionDoc.data();
                    const folioServicioRef = inspeccionData.folio_servicio;
                    const servicioDoc = await getDoc(folioServicioRef);

                    if (servicioDoc.exists()) {
                        const servicioData = servicioDoc.data();
                        const servicio = servicioData.folio;
                        const inspeccionConFolio = {
                            id: inspeccionDoc.id,
                            ...inspeccionData,
                            servicio
                        };
                        setInspeccion(inspeccionConFolio);
                    } else {
                        console.log("No existe el documento de servicio asociado");
                    }
                } else {
                    setInspeccion(null);
                }

                handleQueryCompletion();
            } catch (error) {
                console.log('Error al obtener la inspección:', error);
                handleQueryCompletion();
            }
        };

        // Ejecutar todas las consultas al cargar el componente
        getEmpresa();
        getCentro();
        getServicio();
        fetchInspeccion();

    }, []);

    useEffect(() => {
        if (inspeccion) {
            setFormData(
                {
                    name: inspeccion.nombre,
                    composicion: inspeccion.composicion,
                    anclaje: inspeccion.anclaje,
                    suelo: inspeccion.suelo,
                    profundidad: inspeccion.profundidad,
                    video_url: inspeccion.video_url,
                    obs_photo1: inspeccion.ob_photo1,
                    obs_photo2: inspeccion.ob_photo2,
                    obs_photo3: inspeccion.ob_photo3,
                    obs_photo4: inspeccion.ob_photo4,
                    obs_photo5: inspeccion.ob_photo5,
                    obs_photo6: inspeccion.ob_photo6,
                    obs_photo7: inspeccion.ob_photo7,
                    obs_photo8: inspeccion.ob_photo8,
                    obs_photo9: inspeccion.ob_photo9,
                    obs_photo10: inspeccion.ob_photo10,
                    observaciones: inspeccion.observacion,
                    comentarios: inspeccion.comentario,
                    lev_observaciones: inspeccion.lev_observacion,
                }
            );
        }
    }, [inspeccion]);

    /* navigate */
    const navigate = useNavigate();

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    /* FileUpload */
    const storage = getStorage();

    console.log("form data", formData)

    const handleFileUpload = async (event, fieldName) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const allowedExtensions = ['jpg', 'jpeg', 'png'];

        if (allowedExtensions.includes(fileExtension)) {
            // Continuar con la carga del archivo       
            setFormData((prevState) => ({
                ...prevState,
                [fieldName]: {
                    file: file,
                    fileExtension: fileExtension,
                }
            }));
        } else {
            alert('Solo se permiten imágenes JPG, PNG o JPEG.');
            // Resetear el input de archivo
            event.target.value = null;
        }
    };

    /* Función para subir las fotos */
    const uploadAndGenerateDownloadUrl = async (storage, docId, formData, photoNumber) => {
        const storageRef = ref(
            storage,
            `linspecciones/${docId}_photo${photoNumber}.${formData[`photo${photoNumber}`]['fileExtension']}`
        );
        await uploadBytes(storageRef, formData[`photo${photoNumber}`]['file']);
        toast.info(`La Foto ${photoNumber} ha sido guardada`, { autoClose: 5000 });
        const downloadUrl = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/linspecciones/${docId}_photo${photoNumber}_640x360.${formData[`photo${photoNumber}`]['fileExtension']}`;
        return downloadUrl;
    }
    /* Submit */
    const handleSubmit = async () => {
        try {
            const inspeccionesRef = doc(db, "linspecciones", idInspeccion)
            console.log("handleSubmit function called.");

            //Validar de que la foto tenga una observacion
            for(let i = 1; i <= 10; i++) {
                if(formData[`photo${i}`]) {
                    if(formData[`obs_photo${i}`] === "") {
                        alert(`La foto ${i} debe tener una observación para poder guardar la inspección`);
                        return;
                    }
                }
            }
      
            setShowModal(true);
            const docData = {
                ob_photo1: formData.obs_photo1,
                ob_photo2: formData.obs_photo2,
                ob_photo3: formData.obs_photo3,
                ob_photo4: formData.obs_photo4,
                ob_photo5: formData.obs_photo5,
                ob_photo6: formData.obs_photo6,
                ob_photo7: formData.obs_photo7,
                ob_photo8: formData.obs_photo8,
                ob_photo9: formData.obs_photo9,
                ob_photo10: formData.obs_photo10,
            };

            const downloadUrls = [];

            for (let photoNumber = 1; photoNumber <= 10; photoNumber++) {
                try {
                    const downloadUrl = await uploadAndGenerateDownloadUrl(storage, idInspeccion, formData, photoNumber);
                    if (downloadUrl !== "") {
                        downloadUrls.push({ key: `photo${photoNumber}`, value: downloadUrl });
                    }
                } catch (error) {
                    console.error(`Error al cargar la foto ${photoNumber}: ${error}`);
                }
            }

            /* Update photos */
            downloadUrls.forEach(item => {
                if (item.value !== '') {
                    docData[item.key] = item.value;
                }
            });

            console.log("data", docData)

            await updateDoc(inspeccionesRef, docData);
            setShowModal(false);

            alert(`La Inspección de Linea N°${inspeccion.folio} ha sido actualizada`);
            navigate(`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}/inspecciones-linea/${idInspeccion}`)
        } catch (error) {
            alert(`Error al agregar la nueva inspección: ${error}`);
        }
    };
    // Mostrar "Cargando..." mientras se están obteniendo los datos
    if (isLoading) {
        return (
            <div>Cargando...</div>
        );
    }

    console.log(formData, "form data")

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Editar Inspección de Línea</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Inicio</Link>
                            </li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}`}>{empresa.name}</Link></li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}`}>{centro.name}</Link></li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}`}>{`Servicio N°${servicio.folio}`}</Link></li>
                            <li className='breadcrumb-item active'>{`Inspección N°${inspeccion.folio}`}</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Inspecciones de Línea</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 1</Form.Label>
                                            <Form.Control type="file" name="photo1" required id="photo1" onChange={(e) => handleFileUpload(e, 'photo1')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 1</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo1" value={formData.obs_photo1} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 2</Form.Label>
                                            <Form.Control type="file" name="photo2" required id="photo2" onChange={(e) => handleFileUpload(e, 'photo2')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 2</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo2" value={formData.obs_photo2} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 3</Form.Label>
                                            <Form.Control type="file" name="photo3" required id="photo3" onChange={(e) => handleFileUpload(e, 'photo3')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 3</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo3" value={formData.obs_photo3} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 4</Form.Label>
                                            <Form.Control type="file" name="photo4" required id="photo4" onChange={(e) => handleFileUpload(e, 'photo4')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 4</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo4" value={formData.obs_photo4} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 5</Form.Label>
                                            <Form.Control type="file" name="photo5" required id="photo5" onChange={(e) => handleFileUpload(e, 'photo5')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 5</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo5" value={formData.obs_photo5} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 6</Form.Label>
                                            <Form.Control type="file" name="photo6" required id="photo6" onChange={(e) => handleFileUpload(e, 'photo6')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 6</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo6" value={formData.obs_photo6} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 7</Form.Label>
                                            <Form.Control type="file" name="photo7" required id="photo7" onChange={(e) => handleFileUpload(e, 'photo7')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 7</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo7" value={formData.obs_photo7} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 8</Form.Label>
                                            <Form.Control type="file" name="photo8" required id="photo8" onChange={(e) => handleFileUpload(e, 'photo8')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 8</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo8" value={formData.obs_photo8} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 9</Form.Label>
                                            <Form.Control type="file" name="photo9" required id="photo9" onChange={(e) => handleFileUpload(e, 'photo9')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 9</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo9" value={formData.obs_photo9} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Foto 10</Form.Label>
                                            <Form.Control type="file" name="photo10" required id="photo10" onChange={(e) => handleFileUpload(e, 'photo10')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Foto 10</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="obs_photo10" value={formData.obs_photo10} onChange={handleChange} placeholder="Ingresa observación" />
                                        </Form.Group>
                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>
                                        </div>
                                        <Modal
                                            show={showModal}
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Actulizando Inspección</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {`La Inspección de Línea N°${inspeccion.folio} se está actualizando, por favor espere...`}
                                            </Modal.Body>
                                            <Modal.Footer>
                                            </Modal.Footer>
                                        </Modal>
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default LInspeccionesCargarPhotos;