/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

/* React Router Rom */
import { useNavigate, Link } from 'react-router-dom';

/* Firebase Auth */
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

//FireStore
import { collection, getDocs} from "firebase/firestore";
import {db} from '../firebase';

import  Context  from "../context";

const Reset = ()  =>{ 

    const navigate = useNavigate();

    const [state, setState] = useState({
        username: '',       
    });


    const [usersEmails, setUsersEmails] = useState([]);
    const getUsersEmails = async () => {
        try {

            const querySnapshot = await getDocs(collection(db, 'usuarios'));
            const usersEmails = querySnapshot.docs
            .map(doc => doc.data().email) // Obtener los correos electrónicos
            .filter(email => email) // Filtrar los correos electrónicos que no son undefined
            .map(email => email.toLowerCase()); // Convertir a minúsculas
            setUsersEmails(usersEmails);


        }
        catch (error) {
            console.log('Error getting documents: ', error);
        }
    }   

    useEffect(() => {
        getUsersEmails();
    }, []);

    const handleChange = (e) => {
        const value = e.target.value.toLowerCase(); // Convertir a minúsculas
        setState({
            ...state,
            [e.target.name]: value
        });
    }   

    //Variables globales de usuario
    const { user, setUser } = useContext(Context);

    useEffect(() => {

        if (user.length > 0 || user === null) {
            navigate('/');
        }
    }, [user]);   

    /* Recuperar contraseña */
    const resetPassword = async (e, usersEmails, email) => {
        
        console.log("email",email)       
        console.log("usersEmails",usersEmails)
        if (!usersEmails.includes(email)) {
            console.log(email)
            alert("El correo ingresado no pertenece a ningún usuario registrado");
            return;
        }
        e.preventDefault(); 
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
            // Email sent
            alert("Correo de recuperación enviado a " + email);
            navigate("/login"); 
            })
            .catch((error) => {
            // Error occurred
            const errorCode = error.code;
            const errorMessage = error.message;
            alert("Error no se pudo enviar el correo de recuperación")

            alert(errorCode + ": " + errorMessage); 
            });
       
    };

    console.log("correo de usuario",state.username)


    return (
        <div>
            <main>
                <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <div className="d-flex justify-content-center py-4">
                            <a href="index.html" className="logo d-flex align-items-center w-auto">
                            <img src={process.env.PUBLIC_URL + '/img/logo.jpg'} alt="" />
                            <span className="d-none d-lg-block">Pacific Rov</span>
                            </a>
                        </div>{/* End Logo */}
                        <div className="card mb-3">
                            <div className="card-body">
                            <div className="pt-4 pb-2">
                                <h5 className="card-title text-center pb-0 fs-4">Recuperar Contraseña</h5>
                                <p className="text-center small">Ingresa tu correo para recuperarla</p>
                            </div>
                            <form className="row g-3 needs-validation" noValidate>
                                <div className="col-12">
                                <label htmlFor="yourUsername" className="form-label">Email</label>
                                <input type="email" 
                                    name="username" 
                                    className="form-control" 
                                    id="yourUsername" 
                                    onChange={handleChange}
                                    required />
                                <div className="invalid-feedback">Ingresa tu correo</div>                              
                                </div>                             
                                <div className="col-12">
                                    <button className="btn btn-primary w-100" onClick={(e) => resetPassword(e, usersEmails, state.username)}>Enviar</button>
                                </div>
                                <div className="col-12">
                                    <Link to="/login" >Regresar al Login</Link>
                                </div>
                                {/*<div className="col-12">
                                <p className="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                                </div>*/}
                            </form>
                            </div>
                        </div>
                        <div className="credits">                           
                            Diseñado por <a href="https://solveraustral.com/">Solver Austral</a>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </main>{/* End #main */}
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
        </div>
    );
}

export default Reset; 