/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

import { Button, Dropdown, ButtonGroup, Modal } from 'react-bootstrap';

import { Link, useNavigate } from 'react-router-dom';

/*Context */
import Context from "../context";

/*Firestore */
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';

/* Components */
import Sidebar from './Sidebar';

/* UnixTimeToDate */
import unixTimeToDateString from './UnixTimeToDateString';

const Navbar = () => {

  /* Context */
  const { user, setUser } = useContext(Context);

  const [showSidebar, setShowSidebar] = useState(false);

  /*Suscripción */
  const [suscripcion, setSuscripcion] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [caducada, setCaducada] = useState(false);

  /*Navigate */
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    if (user[0].rol === "Administrador") {
      setShowSidebar(!showSidebar);
    } else {
      setShowSidebar(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "usuarios"), where("uid", "==", user[0]))
        );

        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            const userData = doc.data();

          });
        } else {
          console.log("No documents found with the specified UID.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    const fetchSuscripcion = async () => {
      try {
        const docRef = doc(db, "suscripcion", 'WaiOM7SOCPohzxN7l7M1');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setSuscripcion(docSnap.data());
          //Mensaje suscripcion caducada 
          if ((docSnap.data().deadline.seconds) < Date.now() / 1000) {
            setShowModal(true);
            setCaducada(true);
          } else if (user[0].modal === true) {
            if (((docSnap.data().deadline.seconds) - Date.now() / 1000) < 5 * 24 * 60 * 60) {
              setShowModal(true);
              setCaducada(false);
            }
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchSuscripcion();

    fetchUser();    
  }, [user]);

  /*Cerrar Sesión */
  const logout = () => {
    localStorage.removeItem('user');
    setUser([]);
    navigate('/login')
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--main-padding-left', showSidebar ? '35%' : '5%');
    if (user.length === 0) {
      navigate('/login');
    }
  }, [showSidebar]);

  const suscripcionCaducada = () => {
    return (
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Suscripción Caducada
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Estimado usuario</h4>
          <p>
            {`Le informamos que su suscripción ha caducado el ${unixTimeToDateString(suscripcion.deadline)}. 
          Si desea continuar disfrutando de todas las ventajas que ofrece nuestro servicio, le recomendamos renovarla lo antes posible`}
          </p>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    )
  }

  const closeModal = () => {
    setShowModal(false);
    setUser([{ ...user[0], modal: false }])
  }

  const suscripcionPorCaducar = () => {
    return (
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={() => closeModal()}>
          <Modal.Title id="contained-modal-title-vcenter">
            Suscripción por Caducar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Estimado usuario</h4>
          <p>
            {`Le informamos que su suscripción caducará el ${unixTimeToDateString(suscripcion.deadline)}. 
            Si desea continuar disfrutando de todas las ventajas que ofrece nuestro servicio, le recomendamos renovarla antes de esa fecha.`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => closeModal()}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  console.log("caducada",caducada)

  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <a>
            <Link to="/" className="logo d-flex align-items-center">
              <img src={process.env.PUBLIC_URL + '/img/logo.jpg'} alt="" />
              <span className="d-none d-lg-block">Pacific Rov</span>
            </Link>
          </a>
          <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSidebar}></i>
        </div>

        {/* End Logo */}

        {/* End Search Bar */}
        <nav className="header-nav ms-auto">
          <Dropdown as={ButtonGroup} style={{ paddingRight: "15px", }}>
            <Button variant="primary" style={{ fontSize: '0.85rem' }} >{user[0].name}</Button>
            {caducada === true ? suscripcionCaducada() : suscripcionPorCaducar()}
            <Dropdown.Toggle split variant="primary" style={{ fontSize: '0.85rem' }} id="dropdown-split-basic" />
            <Dropdown.Menu style={{ fontSize: '0.85rem' }}>
              <Dropdown.Item style={{ fontSize: '0.85rem' }} onClick={logout}>Cerrar Sesión</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </nav>
        {/* End Icons Navigation */}
      </header>
      {showSidebar && <Sidebar />}
    </>
  );
}

export default Navbar;