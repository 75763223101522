/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

/* React Bootstrap  */
import { Card, Button, Form, Breadcrumb, BreadcrumbItem, Modal } from 'react-bootstrap';

/* React Router  */
import { Link, useNavigate } from 'react-router-dom';

/* Rutas */
import { useParams } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { doc, updateDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const EmpresasEditar = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    /*Modal de espera mientras de Guarda Empresa */
    const [showModal, setShowModal] = useState(false);

    /* Forms */
    const [formData, setFormData] = useState({
        name: '',
        rut: '',
        address: '',
        status: '',
        file: '',
        fileExtension: '',
    })

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "rut") {
            // Formatear el RUT
            const rutFormatted = value
                .replace(/[^\dkK]/gi, "")
                .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
            setFormData((prevState) => ({
                ...prevState,
                [name]: rutFormatted,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    /* FileUpload */
    const storage = getStorage();

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        // Verificar si la extensión es jpg o png
        if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
            // Continuar con la carga del archivo
            const storageRef = ref(storage, `empresa/${id}.${fileExtension}`);
            await uploadBytes(storageRef, file);
            const downloadUrl = await getDownloadURL(storageRef);
            setFormData((prevState) => ({
                ...prevState,
                file: file,
                fileExtension: fileExtension,
            }));
        } else {
            alert('Solo se permiten imágenes JPG o PNG');
            // Resetear el input de archivo
            event.target.value = null;
        }
    };

    /* Submit */
    const handleSubmit = async () => {
        try {
            console.log("handleSubmit function called.");
            if (!formData.name || !formData.rut || !formData.address) {
                alert('Rellene los campos obligatirios');
                return;
            }

            setShowModal(true)
            const empresaRef = doc(db, "empresa", id);

            const docRef = {
                name: formData.name,
                rut: formData.rut,
                address: formData.address,
                status: formData.status,
                date_update: serverTimestamp()
            };

            const docRefUpdate = await updateDoc(empresaRef, docRef);

            /* Save the photo */
            if (formData.fileExtension || formData.file) {
                const storageRef = ref(storage, `empresa/${id}.${formData.fileExtension}`);
                await uploadBytes(storageRef, formData.file);
                const downloadUrl = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/empresa/${id}_640x360.${formData.fileExtension}`
                /* Update logo */
                const data = { logo: downloadUrl };
                await updateDoc(empresaRef, data);
            }
            setShowModal(false);

            alert(`La empresa ${formData.name} ha sido actualizada`);
            navigate(`/empresas/${id}`)
        } catch (error) {
            alert(`Error al agregar nueva empresa: ${error}`);
        }
    };

    const [empresa, setEmpresa] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEmpresa = async () => {
            if (!id) return;
            const empresaRef = doc(db, "empresa", id);
            try {
                const empresaDoc = await getDoc(empresaRef);
                if (empresaDoc.exists()) {
                    setEmpresa({ id: empresaDoc.id, ...empresaDoc.data() });
                } else {
                    setEmpresa(null);
                }
            } catch (error) {
                console.log('Error al obtener la empresa:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEmpresa();
    }, []);


    useEffect(() => {
        if (empresa) {
            setFormData({
                name: empresa.name,
                rut: empresa.rut,
                address: empresa.address,
                status: empresa.status,
                file: '',
                fileExtension: '',
            });
        }
    }, [empresa]);


    if (isLoading) {
        return (
            <div>Cargando...</div>
        )
    }

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Editar Empresa</h1>
                    <Breadcrumb>
                        <BreadcrumbItem >
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                Inicio
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{empresa.name}</BreadcrumbItem>
                        <BreadcrumbItem active to="/empresas-crear">Editar Empresa</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Empresa</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Nombre<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Ingresa el nombre" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Rut<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="rut" value={formData.rut} onChange={handleChange} placeholder="Ej: 99.999.999-9" maxLength={12} />
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Dirección<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Ingresa dirección" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control as="select" name="status" value={formData.status} onChange={handleChange} required id="status">
                                                <option value="">Selecciona una opción</option>
                                                <option>Activo</option>
                                                <option>Inactivo</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Logo Empresa<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="file" name="document" required id="document" onChange={(event) => handleFileUpload(event)} />
                                        </Form.Group>
                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>
                                        </div>
                                        <Modal
                                            show={showModal}
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Actualizando Empresa</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {`La empresa ${formData.name} se está actualizando, por favor espere...`}
                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>
                                        </Modal>
                                    </Form>
                                    {/* Vertical Form */}
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default EmpresasEditar;