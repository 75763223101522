//Fecha formato unix
const unixTimeToDateString = (unixTime) => {
    try {
        const date = new Date(unixTime.seconds * 1000);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        // Agrega un cero inicial si es necesario
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    }
    catch (error) {
        return '';
    }
}

export default unixTimeToDateString;