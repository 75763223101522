import React, { useEffect } from 'react';

export default function GoogleMapsComponent({ name, description, coordinates }) {
  const [map, setMap] = React.useState(null);

  useEffect(() => {
    // Esta función se ejecutará una vez que el componente se monte en el DOM.
    function initMap() {
      const mapOptions = {
        zoom: 15,
        center: coordinates,
        mapTypeId: 'satellite', // Establece el tipo de mapa en satélite
      };

      const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);

      const contentString = `
        <div id="content">
          <div id="siteNotice"></div>
          <h1 id="firstHeading" class="firstHeading">${name}</h1>
          <div id="bodyContent">
            ${description}
          </div>
        </div>
      `;

      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
        ariaLabel: description,
      });

      const marker = new window.google.maps.Marker({
        position: coordinates,
        map,
        title: description,
      });

      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });

      // Guarda la referencia al mapa en el estado local
      setMap(map);
    }

    if (window.google) {
      // Si Google Maps ya está cargado, inicia el mapa directamente.
      initMap();
    } else {
      // Si Google Maps aún no se ha cargado, espera a que se cargue.
      window.initMap = initMap;
    }
  }, [description, coordinates]);

  return <div id="map" style={{ width: '100%', height: '500px' }} />;
}
