/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

import { Link, useNavigate } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { collection, getDocs, getDoc } from "firebase/firestore";
import { db } from '../firebase';

/* Export CSV */
import { mkConfig, generateCsv, download } from "export-to-csv";

const Centros = () => {

  const [centros, setCentros] = useState([]);

  useEffect(() => {
    const fetchCentros = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "centro"));
        const newData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const centroData = doc.data();
            const empresaRef = centroData.empresa;
            const empresaDoc = await getDoc(empresaRef);
            const newData = {
              ...centroData,
              id: doc.id,
              empresa: {
                id: empresaRef.id,
                name: empresaDoc.data().name
              }
            };
            return newData;
          })
        );

        setCentros(newData);
        console.log(newData);
      } catch (error) {
        console.log('Error al obtener los centros:', error);
      }
    };

    fetchCentros();
  }, []);

  /* Ordenar los centros */
  const [sortType, setSortType] = useState("asc");
  const [sortField, setSortField] = useState("");


  const handleSort = (field) => {
    // Verifica si se está ordenando por el mismo campo para invertir el tipo de ordenamiento
    const newSortType = sortField === field && sortType === "asc" ? "desc" : "asc";

    // Actualiza el campo y el tipo de ordenamiento
    setSortField(field);
    setSortType(newSortType);

    // Realiza el ordenamiento de los centros según el campo y el tipo de ordenamiento
    const sortedCentros = [...centros].sort((a, b) => {
      const valueA = getValueByField(a, field);
      const valueB = getValueByField(b, field);

      if (valueA < valueB) {
        return newSortType === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return newSortType === "asc" ? 1 : -1;
      }
      return 0;
    });

    // Actualiza los centros ordenados
    setCentros(sortedCentros);
  };

  // Función auxiliar para obtener el valor de un campo anidado
  const getValueByField = (object, field) => {
    const fields = field.split(".");
    let value = object;

    fields.forEach((f) => {
      value = value[f];
    });

    return value;
  };

  /* Paginación */
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCentros = centros.slice(indexOfFirstItem, indexOfLastItem);

  /* Navegar a través de las páginas */
  const navigate = useNavigate();

  /* <CSV></CSV> */
  const downloadCsv = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "centros" });
    const filterCentrosCsv = centros.map(centro => ({
      centro: centro.name,
      empresa: centro.empresa.name,
      status: centro.status,
    }));
    const csv = generateCsv(csvConfig)(filterCentrosCsv);
    download(csvConfig)(csv);
  };

  return (
    <>
      <>
        <Navbar />
        <main id="main" className="main" style={{ width: '100%' }}>
          <div className="pagetitle">
            <h1>Centros</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Inicio</Link>
                </li>
              </ol>
            </nav>
          </div>
          {/* End Page Title */}
          <section className="section dashboard">
            <div className="row">
              {/* Left side columns */}
              <div className="col-lg-8">
                <div className="d-flex">
                </div>
              </div>
              {/* End Right side columns */}
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
              {/* Centros */}
              <div className="col-12 " >
                <div className="card recent-sales overflow-auto table-responsive">
                  <div className="filter">
                    <a className="icon" href="#" data-bs-toggle="dropdown">
                      <i className="bi bi-three-dots" />
                    </a>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      Centros
                    </h5>
                    <table className="table table-borderless datatable">
                      <thead>
                        <tr>
                          <th scope="col" onClick={() => handleSort("name")}>Centro</th>
                          <th scope="col" className="d-none d-sm-table-cell" onClick={() => handleSort("empresa.name")}>Empresa</th>
                          <th scope="col" onClick={() => handleSort("status")}>Status</th>
                          <th scope="col">Entrar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentCentros
                          .map((centro, index) => (
                            <tr key={index}>
                              <td>{centro.name}</td>
                              <td className="d-none d-sm-table-cell">
                                <a href="#" className="text-primary">
                                  <Link to={`/empresas/${centro.empresa.id}`}>
                                    {centro.empresa.name}
                                  </Link>
                                </a>
                              </td>
                              <td>
                                <span
                                  className={`badge ${centro.status === "Activo" ? "bg-success" : "bg-danger"
                                    }`}
                                >
                                  {centro.status}
                                </span>
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  style={{ fontSize: "0.65rem" }}
                                  onClick={() => navigate(`/empresas/${centro.empresa.id}/centros/${centro.id}`)}
                                >
                                  Entrar
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button
                        className="btn btn-link"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Anterior
                      </button>
                      <button
                        className="btn btn-link"
                        disabled={indexOfLastItem >= centros.length}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Siguiente
                      </button>
                    </div>
                    <div className="d-flex justify-content">
                      <button className="btn btn-primary" onClick={downloadCsv}>Exportar CSV</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Empresas */}
            </div>
          </section>
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
}

export default Centros;
