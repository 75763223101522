/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

import { Link, useNavigate } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { collection, getDocs, getDoc } from "firebase/firestore";
import { db } from '../firebase';

/* Export CSV */
import { mkConfig, generateCsv, download } from "export-to-csv";

/* Export Unix */
import unixTimeToDateString from '../components/UnixTimeToDateString';
import { set } from 'react-hook-form';

const Servicios = () => {

  const [servicios, setServicios] = useState([]);   
  const [centros, setCentros] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  const [servFiltrados, setServFiltrados] = useState([]);

  const fetchServicios = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "servicios"));
      const newData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const servicioData = doc.data();
          const centroRef = doc.data().centro;
          const centroDoc = await getDoc(centroRef);
          const empresaRef = centroDoc.data().empresa;
          const empresaDoc = await getDoc(empresaRef);
          const newData = {
            ...servicioData,
            id: doc.id,
            folio: doc.data().folio,
            date_service : doc.data().date.seconds,
            centro: {
              id: centroRef.id,
              name: centroDoc.data().name
            },
            empresa: {
              id: empresaRef.id,
              name: empresaDoc.data().name
            }
          };
          
          return newData;
        })
      );
      newData.sort((a, b) => b.folio - a.folio);
      setServicios(newData);  
      setServFiltrados(newData);        
    } catch (error) {
      console.log('Error al obtener los servicios:', error);
    }
  };
  
  useEffect(() => {   
  
    const fetchCentros = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "centro"));
        const newData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const centroData = doc.data();
            const empresaRef = centroData.empresa;
            const empresaDoc = await getDoc(empresaRef);
            const newData = {             
              id: doc.id,
              centro: doc.data().name,
              empresa: empresaDoc.data().name 
            };
            return newData;
          })
        );
        newData.sort((a, b) => a.centro.localeCompare(b.centro, undefined, { sensitivity: 'base' }));
        setCentros(newData);       
      } catch (error) {
        console.log('Error al obtener los centros:', error);
      }

      filtrarServicios();
    };

    const fetchEmpresa = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "empresa"));
        const newData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const empresaData = doc.data();
            const newData = {
              id: doc.id,
              name: empresaData.name
            };
            return newData;
          })
        );
        newData.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        setEmpresas(newData);
      } catch (error) {

      }
    };
    fetchServicios();  
    
    fetchEmpresa();
    fetchCentros();    
    filtrarCentro();
  }, []); 
  

  /* Ordenar los centros */
  const [sortType, setSortType] = useState("asc");
  const [sortField, setSortField] = useState("");

  const handleSort = (field) => {
    // Verifica si se está ordenando por el mismo campo para invertir el tipo de ordenamiento
    const newSortType = sortField === field && sortType === "asc" ? "desc" : "asc";

    // Actualiza el campo y el tipo de ordenamiento
    setSortField(field);
    setSortType(newSortType);

    // Realiza el ordenamiento de los centros según el campo y el tipo de ordenamiento
    const sortedServicios = [...servFiltrados].sort((a, b) => {
      const valueA = getValueByField(a, field);
      const valueB = getValueByField(b, field);

      if (valueA < valueB) {
        return newSortType === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return newSortType === "asc" ? 1 : -1;
      }
      return 0;
    });

    // Actualiza los centros ordenados
    setServFiltrados(sortedServicios);
  };

  // Función auxiliar para obtener el valor de un campo anidado
  const getValueByField = (object, field) => {
    const fields = field.split(".");
    let value = object;

    fields.forEach((f) => {
      value = value[f];
    });

    return value;
  };

  /* Filtros */
  const filtrarServicios = () => {    
    const serviciosFiltrados = servicios.filter((servicio) => {
      const filtroEmpresaValido = filtroEmpresa === "Todos" || servicio.empresa.name === filtroEmpresa;
      const filtroCentroValido = filtroCentro === "Todos" || servicio.centro.name === filtroCentro;
      const filtroFechaInicialValido = !filtroFechaInicial || servicio.date_service >= new Date(filtroFechaInicial).getTime()/1000 ;
      const filtroFechaFinalValido = !filtroFechaFinal || servicio.date_service <= new Date(filtroFechaFinal).getTime()/1000 ;
      
      return filtroEmpresaValido && filtroCentroValido && filtroFechaInicialValido && filtroFechaFinalValido ;     
    });
    setServFiltrados(serviciosFiltrados);
    
  }     

  /* Paginación */
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentServicios = servFiltrados.slice(indexOfFirstItem, indexOfLastItem);

  // Centros
  
  const [filtroEmpresa, setFiltroEmpresa] = useState("Todos");
  const [filtroCentro, setFiltroCentro] = useState("Todos");
  const [filtroFechaInicial, setFiltroFechaInicial] = useState(null);
  const [filtroFechaFinal, setFiltroFechaFinal] = useState(null);

  const [centrosFiltrados, setCentrosFiltrados] = useState(centros);
  const filtrarCentro = () => { 
    if (filtroEmpresa === "Todos") {     
      setCentrosFiltrados(centros); 
    } else { 
      const filtros = centros.filter((centro) => centro.empresa === filtroEmpresa);      
      setCentrosFiltrados(filtros);
    } 
  };  

  /* Navegar a través de las páginas */
  const navigate = useNavigate();

  /* <CSV></CSV> */
  const downloadCsv = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "servicios" });
    const filterCentrosCsv = servicios.map(servicio => ({
      servicio: servicio.folio,
      empresa: servicio.empresa.name,
      centro: servicio.centro.name,
      etapa: servicio.etapa,
    }));
    const csv = generateCsv(csvConfig)(filterCentrosCsv);
    download(csvConfig)(csv);
  };

  return (
    <>
      <>
        <Navbar />
        <main id="main" className="main" style={{ width: '100%' }}>
          <div className="pagetitle">
            <h1>Servicios</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Inicio</Link>
                </li>
              </ol>
            </nav>
          </div>
          {/* End Page Title */}
          <section className="section dashboard">
            <div className="row">
              {/* Left side columns */}
              <div className="col-lg-8">
                <div className="d-flex">
                </div>
              </div>
              {/* End Right side columns */}
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
              {/* Servicios */}
              <div className="col-12 " >
                <div className="card recent-sales overflow-auto table-responsive">
                  <div className="filter">
                    <a className="icon" data-bs-toggle="dropdown">
                      <i className="bi bi-three-dots" />
                    </a>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      Servicios
                    </h5>
                    <div className="row" style={{ display: 'flex', paddingRight: '20px' }}>
                    {/* Filtro Empresa */}
                    <div className="mb-3 col-sm-3" style={{ fontSize: "0.75rem"}}>
                      <div style={{paddingBottom : "0.2rem"}} >Empresas</div>
                      <select className="form-select" 
                        style={{ fontSize: "0.65rem", width: '100%' }} 
                        value={filtroEmpresa} 
                        onChange={(e) => {
                          setFiltroEmpresa(e.target.value);                    
                          filtrarCentro();         
                          setFiltroCentro("Todos");              
                        }}
                        onBlur={() => filtrarCentro()}
                      aria-label="Default select example">
                        <option selected>Todos</option>
                        {empresas.map((empresa) => (
                          <option key={empresa.id}>{empresa.name}</option>
                        ))}
                      </select>             
                    </div>                      
                    {/* Filtro Centros */}                   
                    <div className="mb-3 col-sm-3" style={{ fontSize: "0.75rem"}}>
                      <div style={{paddingBottom : "0.2rem"}} >Centros </div>
                      <select className="form-select" style={{ fontSize: "0.65rem", width: '100%' }} value={filtroCentro} onChange={(e) => setFiltroCentro(e.target.value)} aria-label="Default select example">
                        <option selected>Todos</option>
                        { centrosFiltrados.map((centro) => (
                          <option key={centro.id}>{centro.centro}</option>
                        ))}
                      </select>            
                    </div>                    
                    {/* Filtro fechas */}
                    <div className="mb-3 col-sm-2" style={{ fontSize: "0.75rem" }}>  
                      <div style={{paddingBottom : "0.2rem"}} >Fecha Inicial </div>              
                      <input type="date" className="form-control" value={filtroFechaInicial} onChange={(e) => setFiltroFechaInicial(e.target.value)} style={{ fontSize: "0.65rem", width: '100%' }} />
                    </div>
                    
                    <div className="mb-3 col-sm-2" style={{ fontSize: "0.75rem"}}>
                      <div style={{paddingBottom : "0.2rem"}} >Fecha Final </div>
                      <input type="date" className="form-control" value={filtroFechaFinal} onChange={(e) => setFiltroFechaFinal(e.target.value)} style={{ fontSize: "0.65rem", width: '100%' }} />
                    </div>
                    
                    <div className="mb-3 col-sm-2" style={{ paddingTop: '1.2rem' }}>
                      <button className="btn btn-primary" style={{ fontSize: "0.65rem", width: '100%' }} onClick={() => filtrarServicios()}>Filtrar</button>
                    </div>
                  </div>
                    {/* Tabla */}
                    <table className="table table-borderless datatable">
                      <thead>
                        <tr>
                          <th scope="col" onClick={() => handleSort("folio")}>Folio</th>
                          <th scope="col" className="d-none d-sm-table-cell" onClick={() => handleSort("empresa.name")}>Empresa</th>
                          <th scope="col" onClick={() => handleSort("centro.name")}>Centro</th>
                          <th scope="col" className="d-none d-sm-table-cell" onClick={() => handleSort("etapa")}>Status</th>
                          <th scope="col" className="d-none d-sm-table-cell" onClick={() => handleSort("date")} >Fecha</th>
                          <th scope="col">Entrar</th>
                          <th scope="col" className="d-none d-sm-table-cell">Informe</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentServicios.map((servicio) => (
                          <tr key={servicio.id}>
                            <td>{servicio.folio}</td>
                            <td className="d-none d-sm-table-cell" >{servicio.empresa.name}</td>
                            <td>{servicio.centro.name}</td>
                            <td className="d-none d-sm-table-cell">{servicio.etapa}</td>
                            <td className="d-none d-sm-table-cell">{unixTimeToDateString(servicio.date)}</td>                           
                            <td>
                              <button
                                className="btn btn-primary"
                                style={{ fontSize: "0.65rem" }}
                                onClick={() => navigate(`/empresas/${servicio.empresa.id}/centros/${servicio.centro.id}/servicios/${servicio.id}`)}
                              >
                                Entrar
                              </button>
                            </td>
                            <td className="d-none d-sm-table-cell">
                              <button
                                className="btn btn-danger"
                                style={{ fontSize: "0.65rem" }}
                                onClick={() => window.open(servicio.informe, '_blank')}
                              >
                                PDF
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button
                        className="btn btn-link"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Anterior
                      </button>
                      <button
                        className="btn btn-link"
                        disabled={indexOfLastItem >= servicios.length}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Siguiente
                      </button>
                    </div>
                    <div className="d-flex justify-content">
                      <button className="btn btn-primary" onClick={downloadCsv}>Exportar CSV</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Empresas */}
            </div>
          </section>
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
}

export default Servicios;
