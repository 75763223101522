/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

import { Link, useNavigate } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { collection, getDocs} from "firebase/firestore";
import { db } from '../firebase';

/* Export CSV */
import { mkConfig, generateCsv, download } from "export-to-csv";

const Usuarios = () => {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "usuarios"));
        const fetchedUsers = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setUsers(fetchedUsers);
      } catch (error) {
        console.log('Error al obtener los usuarios:', error);
      }
    };
    fetchUsers();
  }, []);

  /* Ordenar los usuarios */
  const [sortType, setSortType] = useState("asc");
  const [sortField, setSortField] = useState("");

  const handleSort = (field) => {
    // Verifica si se está ordenando por el mismo campo para invertir el tipo de ordenamiento
    const newSortType = sortField === field && sortType === "asc" ? "desc" : "asc";

    // Actualiza el campo y el tipo de ordenamiento
    setSortField(field);
    setSortType(newSortType);

    // Realiza el ordenamiento de los centros según el campo y el tipo de ordenamiento
    const sortedUsers = [...users].sort((a, b) => {
      const valueA = getValueByField(a, field);
      const valueB = getValueByField(b, field);

      if (valueA < valueB) {
        return newSortType === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return newSortType === "asc" ? 1 : -1;
      }
      return 0;
    });

    // Actualiza los centros ordenados
    setUsers(sortedUsers);
  };

  // Función auxiliar para obtener el valor de un campo anidado
  const getValueByField = (object, field) => {
    const fields = field.split(".");
    let value = object;
    fields.forEach((f) => {
      value = value[f];
    });

    return value;
  };

  /* Paginación */
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstItem, indexOfLastItem);

  /* Navegar a través de las páginas */
  const navigate = useNavigate();

  /* <CSV></CSV> */
  const downloadCsv = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "usuarios" });
    const filterUsuarioCsv = users.map(usuario => ({
      usuario: usuario.name,
      email: usuario.email,
      rol: usuario.rol,
      status: usuario.status
    }));
    const csv = generateCsv(csvConfig)(filterUsuarioCsv);
    download(csvConfig)(csv);
  };

  return (
    <>
      <>
        <Navbar />
        <main id="main" className="main" style={{ width: '100%' }}>
          <div className="pagetitle">
            <h1>Usuarios</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Inicio</Link>
                </li>
              </ol>
            </nav>
          </div>
          {/* End Page Title */}
          <section className="section dashboard">
            <div className="row">
              {/* Left side columns */}
              <div className="col-lg-8">
                <div className="d-flex">
                </div>
              </div>
              {/* End Right side columns */}
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
              {/* Centros */}
              <div className="col-12 " >
                <div className="card recent-sales overflow-auto table-responsive">
                  <div className="filter">
                    <a className="icon"  data-bs-toggle="dropdown">
                      <i className="bi bi-three-dots" />
                    </a>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      Usuarios
                    </h5>
                    <table className="table table-borderless datatable">
                      <thead>
                        <tr>
                          <th scope="col" onClick={() => handleSort("name")}>Usuario</th>
                          <th scope="col" className="d-none d-sm-table-cell" onClick={() => handleSort("email")}>Email</th>
                          <th scope="col" className="d-none d-sm-table-cell" onClick={() => handleSort("rol")}>Rol</th>
                          <th scope="col" onClick={() => handleSort("status")}>Status</th>
                          <th scope="col">Entrar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers
                          .map((user, index) => (
                            <tr key={index}>
                              <td>{user.name}</td>
                              <td className="d-none d-sm-table-cell">
                                <div className="text-primary">
                                  {user.email}
                                </div>
                              </td>
                              <td className="d-none d-sm-table-cell">
                                <div className="text-primary">
                                  {user.rol}
                                </div>
                              </td>
                              <td>
                                <span
                                  className={`badge ${user.status === "Activo" ? "bg-success" : "bg-danger"
                                    }`}
                                >
                                  {user.status}
                                </span>
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  style={{ fontSize: "0.65rem" }}
                                  onClick={() => navigate(`/usuarios/${user.id}`)}
                                >
                                  Entrar
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button
                        className="btn btn-link"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Anterior
                      </button>
                      <button
                        className="btn btn-link"
                        disabled={indexOfLastItem >= users.length}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Siguiente
                      </button>
                    </div>
                    <div className="d-flex justify-content" style={{ gap: '5px' }}>
                      <button className="btn btn-primary" onClick={() => navigate('/usuarios-crear')}>Añadir Usuario</button>
                      <button className="btn btn-primary" onClick={downloadCsv}>Exportar CSV</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Empresas */}
            </div>
          </section>
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
}

export default Usuarios;
