/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

import { Card, Button, Form, Modal } from 'react-bootstrap';

import { useForm } from 'react-hook-form';

/* Rutas */
import { useParams } from 'react-router-dom';

/* React Router  */
import { Link, useNavigate } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Time */
import moment from 'moment-timezone';

/*Firestore */
import { collection, doc, addDoc, updateDoc, getDoc, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from '../firebase';
import { getStorage, ref, uploadBytes} from "firebase/storage";

/* Toast */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LInspeccionesCrear = () => {

    /*Time */
    const date = moment().tz('America/Santiago').toDate();

    /* Obtiene el id de la empresa */
    const { id, idCentro, idServicio } = useParams();

    /* Formulario de puntos cardinales */
    const { register, watch, formState: { errors } } = useForm();

    /*Modal de espera mientras de Guarda Inspección */
    const [showModal, setShowModal] = useState(false);

    /* Forms */
    const [formData, setFormData] = useState({
        name: '',
        composicion: '',
        anclaje: '',
        suelo: '',
        profundidad: '',
        video_url: '',
        obs_photo1: '',
        obs_photo2: '',
        obs_photo3: '',
        obs_photo4: '',
        obs_photo5: '',
        obs_photo6: '',
        obs_photo7: '',
        obs_photo8: '',
        obs_photo9: '',
        obs_photo10: '',
        observaciones: '',
        observacion_leve: '',
        comentarios: '',
        lev_observaciones: '',
    })

    /* Obtener los datos de la inspección */
    const [isLoading, setIsLoading] = useState(true);
    const [empresa, setEmpresa] = useState([]);
    const [centro, setCentro] = useState([]);
    const [servicio, setServicio] = useState([]);
    const [folioMax, setFolioMax] = useState(0);

    useEffect(() => {
        // Función para manejar la finalización de las consultas
        let completedQueries = 0;

        const handleQueryCompletion = () => {
            completedQueries++;
            if (completedQueries === 5) {
                setIsLoading(false);
            }
        };

        // Consulta para obtener los datos de la empresa
        const getEmpresa = async () => {
            try {
                const empresaRef = doc(db, "empresa", id);
                const empresaSnap = await getDoc(empresaRef);

                // Verificar si el documento existe
                if (empresaSnap.exists()) {
                    const empresaData = empresaSnap.data();
                    setEmpresa(empresaData);
                } else {
                    console.log("No existe la empresa");
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener la empresa:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos del centro
        const getCentro = async () => {
            try {
                const centroRef = doc(db, "centro", idCentro);
                const centroSnap = await getDoc(centroRef);

                if (centroSnap.exists()) {
                    const centroData = centroSnap.data();
                    setCentro(centroData);
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener el centro:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos del servicio
        const getServicio = async () => {
            try {
                const servicioRef = doc(db, "servicios", idServicio);
                const servicioSnap = await getDoc(servicioRef);

                if (servicioSnap.exists()) {
                    const servicioData = servicioSnap.data();
                    setServicio(servicioData);
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener el servicio:", e);
                handleQueryCompletion();
            }
        };

        // Función para obtener el próximo folio
        const getNextFolio = async () => {
            try {
                // Referencia a la colección 
                const linspeccionesRef = collection(db, "linspecciones");

                // Consulta ordenada por folio descendente 
                const q = query(
                    linspeccionesRef,
                    orderBy("folio", "desc"),
                    limit(1)
                );

                // Ejecutar la consulta
                const querySnapshot = await getDocs(q);

                // Mapear los datos
                const linspecciones = querySnapshot.docs.map(doc => doc.data())

                setFolioMax(linspecciones[0]['folio'] );
                handleQueryCompletion();

            } catch (error) {
                console.log(error);
                handleQueryCompletion();

            }
        };

        // Ejecutar todas las consultas al cargar el componente
        const fetchData = async () => {
            await getNextFolio();
            await getEmpresa();
            await getCentro();
            await getServicio();
        };

        fetchData();
        setIsLoading(false);

    }, []);

    /* navigate */
    const navigate = useNavigate();

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        let newFormData = { ...formData };
      
        if (name === 'observaciones') {
          newFormData = {
            ...newFormData,
            observaciones: value,
            observacion_leve: '', // Borra el contenido de "Observación Leve"
          };
        } else if (name === 'observacion_leve') {
          newFormData = {
            ...newFormData,
            observaciones: '', // Borra el contenido de "Observación Crítica"
            observacion_leve: value,
          };
        } else {
          newFormData = {
            ...newFormData,
            [name]: value,
          };
        }
      
        setFormData(newFormData);
      };
      
      

    /* FileUpload */
    const storage = getStorage();

    const handleFileUpload = async (event, fieldName) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const allowedExtensions = ['jpg', 'jpeg', 'png'];

        if (allowedExtensions.includes(fileExtension)) {
            // Continuar con la carga del archivo       
            setFormData((prevState) => ({
                ...prevState,
                [fieldName]: {
                    file: file,
                    fileExtension: fileExtension,
                }
            }));
        } else {
            alert('Solo se permiten imágenes JPG, PNG o JPEG');
            // Resetear el input de archivo
            event.target.value = null;
        }
    };

    /* Función para subir las fotos */
    const uploadAndGenerateDownloadUrl = async (storage, docId, formData, photoNumber) => {
        const storageRef = ref(
            storage,
            `linspecciones/${docId}_photo${photoNumber}.${formData[`photo${photoNumber}`]['fileExtension']}`
        );
        await uploadBytes(storageRef, formData[`photo${photoNumber}`]['file']);
        toast.info(`La Foto ${photoNumber} ha sido guardada`, { autoClose: 2000 });
        const downloadUrl = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/linspecciones/${docId}_photo${photoNumber}_640x360.${formData[`photo${photoNumber}`]['fileExtension']}`;
        return downloadUrl;
    }
    /* Submit */
    console.log("folio max", folioMax)
    const handleSubmit = async () => {
        try {
            console.log("handleSubmit function called.");
            if (!formData.name) {
                alert('Rellene los campos obligatorios');
                return;
            }
            setShowModal(true);

            let status = "Sin Observación";

            if (formData.lev_observaciones) {
            status = "Observación Levantada";
            } else if (formData.observacion_leve) {
            status = "Observación Leve"; 
            } else if (formData.observaciones) {
            status = "Observación Crítica";
            }

            const docData = {
                nombre: formData.name,
                composicion: formData.composicion,
                anclaje: formData.anclaje,
                suelo: formData.suelo,
                profundidad: formData.profundidad,
                video_url: formData.video_url,
                ob_photo1: "",
                ob_photo2: "",
                ob_photo3: "",
                ob_photo4: "",
                ob_photo5: "",
                ob_photo6: "",
                ob_photo7: "",
                ob_photo8: "",
                ob_photo9: "",
                ob_photo10: "",
                observacion: formData.observaciones,
                observacion_leve: formData.observacion_leve,
                comentario: formData.comentarios,
                lev_observacion: formData.lev_observaciones,
                date_created: date,
                date_updated: date,
                folio_servicio: doc(db, "servicios", idServicio),
                folio: folioMax + 1,
                status: status,
            };

            console.log(docData)

            const docRef = await addDoc(collection(db, "linspecciones"), docData);

            console.log("Document written with ID: ", docRef.id)

            const docId = docRef.id;

            let downloadUrl1 = "";
            let downloadUrl2 = "";
            let downloadUrl3 = "";
            let downloadUrl4 = "";
            let downloadUrl5 = "";
            let downloadUrl6 = "";
            let downloadUrl7 = "";
            let downloadUrl8 = "";
            let downloadUrl9 = "";
            let downloadUrl10 = "";

            try {
                /* Save the photo photo1 */
                downloadUrl1 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 1);
                downloadUrl2 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 2);
                downloadUrl3 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 3);
                downloadUrl4 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 4);
                downloadUrl5 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 5);
                downloadUrl6 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 6);
                downloadUrl7 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 7);
                downloadUrl8 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 8);
                downloadUrl9 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 9);
                downloadUrl10 = await uploadAndGenerateDownloadUrl(storage, docId, formData, 10);
            } catch {
                console.log('')
            }

            /* Update photos */
            const inspeccionesRef = doc(db, "linspecciones", docId);
            const data = {
                photo1: downloadUrl1,
                photo2: downloadUrl2,
                photo3: downloadUrl3,
                photo4: downloadUrl4,
                photo5: downloadUrl5,
                photo6: downloadUrl6,
                photo7: downloadUrl7,
                photo8: downloadUrl8,
                photo9: downloadUrl9,
                photo10: downloadUrl10,
            };
            await updateDoc(inspeccionesRef, data);
            setShowModal(false)
            alert(`La Inspección de Linea ${formData.name} ha sido guardada`);
            navigate(`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}`)
        } catch (error) {
            alert(`Error al agregar la nueva inspección: ${error}`);
        }
    };

    // Mostrar "Cargando..." mientras se están obteniendo los datos
    if (isLoading) {
        return (
            <div>Cargando...</div>
        );
    }

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Crear Inspección de Línea</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Inicio</Link>
                            </li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}`}>{empresa.name}</Link></li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}`}>{centro.name}</Link></li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}`}>{`Servicio N°${servicio.folio}`}</Link></li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Inspecciones de Línea</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nombre<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Ingresa el nombre" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Composición Fondeo</Form.Label>
                                            <Form.Control type="text" name="composicion" value={formData.composicion} onChange={handleChange} placeholder="Ingresa composición" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Anclaje</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="anclaje"
                                                value={formData.anclaje}
                                                onChange={handleChange}
                                                placeholder="Selecciona el tipo de anclaje"                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="Ancla">Ancla</option>
                                                <option value="Perno Costa">Perno Costa</option>
                                                <option value="Peso Muerto">Peso Muerto</option>
                                                <option value="Muerto Ancla">Muerto Ancla</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tipo Suelo</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="suelo"
                                                value={formData.suelo}
                                                onChange={handleChange}
                                                placeholder="Selecciona el tipo de suelo"                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="Arena">Arena</option>
                                                <option value="Roca">Roca</option>
                                                <option value="Piedra">Piedra</option>
                                                <option value="Fango">Fango</option>
                                                <option value="No observado">No observado</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Profundidad</Form.Label>
                                            <Form.Control type="number" name="profundidad" value={formData.profundidad} onChange={handleChange} placeholder="Ingresa la profundidad" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Video Link</Form.Label>
                                            <Form.Control type="text" name="video_url" value={formData.video_url} onChange={handleChange} placeholder="Ingresa link de video" />
                                        </Form.Group>                                        
                                        <Form.Group className="mb-3">
                                            <Form.Label>Comentarios</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="comentarios" value={formData.comentarios} onChange={handleChange} placeholder="Ingresa comentarios" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Crítica</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="observaciones" value={formData.observaciones} onChange={handleChange} placeholder="Ingresa observaciones" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Leve</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="observacion_leve" value={formData.observacion_leve} onChange={handleChange} placeholder="Ingresa observaciones" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Levantantamiento Observaciones</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="lev_observaciones" value={formData.lev_observaciones} onChange={handleChange} placeholder="Ingresa levantamiento observaciones" />
                                        </Form.Group>
                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>                                        
                                        </div>
                                        <Modal
                                            show={showModal}
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Guardando Inspección</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {`La Inspección de Línea N°${folioMax + 1} se está guardando, por favor espere...`}
                                            </Modal.Body>
                                            <Modal.Footer>
                                            </Modal.Footer>
                                        </Modal>
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default LInspeccionesCrear;