/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

/* Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';

/* Material-UI */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Card, Button, Form, Modal as ModalB, Col, Breadcrumb, BreadcrumbItem } from 'react-bootstrap';

import { useForm } from 'react-hook-form';

/* Rutas */
import { useParams } from 'react-router-dom';

/* React Router  */
import { Link, useNavigate } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Regiones */
import regionOptions from '../data/regiones';

/* Time */
import moment from 'moment-timezone';

/*Firestore */
import { collection, doc, addDoc, updateDoc, GeoPoint, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import { getStorage, ref, uploadBytes } from "firebase/storage";

/* Coordenadas */
import {convertirLatitudDMSaDecimal, convertirLongitudDMSaDecimal} from '../components/Coordenadas';

library.add(fas);

const CentrosCrear = () => {

    /*Time */
    const date = moment().tz('America/Santiago').toDate();

    /* Obtiene el id de la empresa */
    const { id } = useParams();

    /* Formulario de puntos cardinales */
    const { register, watch, formState: { errors } } = useForm();

    /*Modal de espera mientras de Guarda Empresa */
    const [showModal, setShowModal] = useState(false);

    /* Forms */
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        region: '',
        description: '',
        status: '',
        video: '',
        jaulas: '',
        modulos: '',
        nModulos: '',
        longitud1: '',
        latitud1: '',
        longitud2: '',
        latitud2: '',
        longitud3: '',
        latitud3: '',
        longitud4: '',
        latitud4: '',
        latitud: '',
        longitud: '',
        file: '',
        fileExtension: '',
    })

    //Datos empresa
    const [empresa, setEmpresa] = useState([])

    useEffect(() => {

        const fetchEmpresa = async () => {
          if (!id) return;
    
          const empresaRef = doc(db, "empresa", id);
          const empresaSnapshot = await getDoc(empresaRef);
    
    
          if (empresaSnapshot.exists()) {
            const empresaData = empresaSnapshot.data();
            const nombreEmpresa = empresaData.name;
            const idEmpresa = empresaSnapshot.id;
    
            const emp = {
              name: nombreEmpresa,
              id: idEmpresa
            };
    
            setEmpresa(emp);
          }
        };
        fetchEmpresa();
      }, []);

    /*Basic Modal */
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    /* navigate */
    const navigate = useNavigate();

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Valida la latitud
    const esLatitudValida = (latitud) => {
        if (typeof latitud !== 'string') {
        return false; // No es una cadena, por lo tanto, no es válida
        }  
        const match = latitud.match(/(\d+)°(\d+)\'([\d.]+)\"([NS])$/);
        return !!match; // Devuelve true si el formato de latitud es válido, de lo contrario, false.
    };

    //Valida la longitud
    const esLongitudValida = (longitud) => {
        if (typeof longitud !== 'string') {
        return false; // No es una cadena, por lo tanto, no es válida
        }
    
    const match = longitud.match(/(\d+)°(\d+)\'([\d.]+)\"([EW])$/);
    return !!match; // Devuelve true si el formato de longitud es válido, de lo contrario, false.
  };

    /* FileUpload */
    const storage = getStorage();

    const handleFileUpload = async (event, fieldName) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        let allowedExtensions;

        if (fieldName === 'imgCentro' || fieldName === 'planoTecnico1' || fieldName === 'planoTecnico2' ) {
            allowedExtensions = ['jpg', 'jpeg', 'png'];
        } else {
            allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
        }

        if (allowedExtensions.includes(fileExtension)) {
            // Continuar con la carga del archivo       
            setFormData((prevState) => ({
                ...prevState,
                [fieldName]: {
                    file: file,
                    fileExtension: fileExtension,
                }
            }));
        } else {
            alert('Solo se permiten imágenes JPG, PNG o JPEG para "Imagen Centro", "Plano Técnico 1" y "Plano Técnico 2". Para los demás campos se permiten imágenes JPG, PNG, JPEG y archivos PDF.');
            // Resetear el input de archivo
            event.target.value = null;
        }
    };

    /* Submit */
    const handleSubmit = async () => {
        try {
            console.log("handleSubmit function called.");
            if (!formData.name || !formData.address || !formData.region) {
                alert('Rellene los campos obligatorios');
                return;
            }

            if (formData.latitud && !esLatitudValida(formData.latitud)) {
                alert('Formato de latitud incorrecto. Debe ser "xx°xx\'xx.x\"N/S".');
                return;
            }
            
            if (formData.longitud && !esLongitudValida(formData.longitud)) {
                alert('Formato de longitud incorrecto. Debe ser "xx°xx\'xx.x\"E/W".');
                return;
            }
            setShowModal(true);
            const docData = {
                name: formData.name,
                empresa: doc(db, "empresa", id),
                address: formData.address,
                region : formData.region,
                status: "Activo",
                video: formData.video,
                date_create: date,
                date_update: date,
                description: formData.description,
                jaulas: formData.jaulas,
                modulos: formData.modulos,
                nModulos: formData.nModulos,                
            };



       
            if (watch('latitud2') && watch('longitud2')) {
                docData.geopoint2 = new GeoPoint(parseFloat(watch('latitud2')), parseFloat(watch('longitud2')));
            }
            if (watch('latitud3') && watch('longitud3')) {
                docData.geopoint3 = new GeoPoint(parseFloat(watch('latitud3')), parseFloat(watch('longitud3')));
            }
            if (watch('latitud4') && watch('longitud4')) {
                docData.geopoint4 = new GeoPoint(parseFloat(watch('latitud4')), parseFloat(watch('longitud4')));
            }

            const docRef = await addDoc(collection(db, "centro"), docData);

            const docId = docRef.id;

            let downloadUrl1 = "";
            let downloadUrl2 = "";
            let downloadUrl3 = "";
            let downloadUrl4 = "";
            let downloadUrl5 = "";
            let downloadUrl6 = "";
            let downloadUrl7 = "";

            try {
                /* Save the photo img centro */
                const storageRef1 = ref(storage, `centros/${docId}_centro.${formData.imgCentro['fileExtension']}`);
                await uploadBytes(storageRef1, formData.imgCentro['file']);
                downloadUrl1 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_centro_640x360.${formData.imgCentro['fileExtension']}`

                /* Save the photo plano1 */
                const storageRef2 = ref(storage, `centros/${docId}_plano1.${formData.planoTecnico1['fileExtension']}`);
                await uploadBytes(storageRef2, formData.planoTecnico1['file']);
                downloadUrl2 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_plano1_640x360.${formData.planoTecnico1['fileExtension']}`

                /* Save the photo plano2 */
                const storageRef3 = ref(storage, `centros/${docId}_plano2.${formData.planoTecnico2['fileExtension']}`);
                await uploadBytes(storageRef3, formData.planoTecnico2['file']);
                downloadUrl3 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_plano2_640x360.${formData.planoTecnico2['fileExtension']}`

                /* Save the photo certificado inspección */
                const storageRef4 = ref(storage, `centros/${docId}_certificado.${formData.cerInspeccion['fileExtension']}`);
                await uploadBytes(storageRef4, formData.cerInspeccion['file']);
                if (formData.cerInspeccion['fileExtension'] === 'pdf') {
                    downloadUrl4 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_certificado.${formData.cerInspeccion['fileExtension']}`
                } else {
                    downloadUrl4 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_certificado_640x360.${formData.cerInspeccion['fileExtension']}`
                }

                /* Save the photo plano observación */
                const storageRef5 = ref(storage, `centros/${docId}_plano_observacion.${formData.planoObservacion['fileExtension']}`);
                await uploadBytes(storageRef5, formData.planoObservacion['file']);
                if (formData.planoObservacion['fileExtension'] === 'pdf') {
                    downloadUrl5 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_plano_observacion.${formData.planoObservacion['fileExtension']}`
                } else {
                    downloadUrl5 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_plano_observacion_640x360.${formData.planoObservacion['fileExtension']}`
                }

                /* Save the photo mapa centro*/
                const storageRef6 = ref(storage, `centros/${docId}_mapa_centro.${formData.mapaCentro['fileExtension']}`);
                await uploadBytes(storageRef6, formData.mapaCentro['file']);
                if (formData.mapaCentro['fileExtension'] === 'pdf') {
                    downloadUrl6 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_mapa_centro.${formData.planoObservacion['fileExtension']}`
                } else {
                    downloadUrl6 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_mapa_centro_640x360.${formData.planoObservacion['fileExtension']}`
                }

                /* Save the photo esquema */
                const storageRef7 = ref(storage, `centros/${docId}_esquema.${formData.esquema['fileExtension']}`);
                await uploadBytes(storageRef7, formData.esquema['file']);
                if (formData.esquema['fileExtension'] === 'pdf') {
                    downloadUrl7 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_esquema.${formData.esquema['fileExtension']}`
                } else {
                    downloadUrl7 = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/centros/${docId}_esquema_640x360.${formData.esquema['fileExtension']}`
                }
            } catch {
                console.log('')
            }

            /* Update photos */
            const centroRef = doc(db, "centro", docId);
            const data = {
                img_centro: downloadUrl1,
                plano1: downloadUrl2,
                plano2: downloadUrl3,
                cer_inspeccion: downloadUrl4,
                plano_observacion: downloadUrl5,
                mapa_centro: downloadUrl6,
                esquema: downloadUrl7
            };
            if (formData.latitud && formData.longitud) {        
                const geopoint1 = new GeoPoint(convertirLatitudDMSaDecimal(formData.latitud), convertirLongitudDMSaDecimal(formData.longitud));
                await updateDoc(centroRef, { geopoint1: geopoint1,
                                            geopoint1_lat : formData.latitud,
                                            geopoint1_lon : formData.longitud,    
                });
            }else {
                    await updateDoc(centroRef,{
                        geopoint1: new GeoPoint(0, 0),
                    geopoint1_lat : formData.latitud,
                    geopoint1_lon : formData.longitud, 
                });
            }
        
            await updateDoc(centroRef, data);
            setShowModal(false)
            alert(`El centro ${formData.name} ha sido guardado`);
            navigate(`/empresas/${id}`)
        } catch (error) {
            alert(`Error al agregar nuevo cliente o servicio: ${error}`);
        }
    };

    console.log(formData)

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Crear Centro</h1>
                    <Breadcrumb>
                        <BreadcrumbItem >
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                Inicio
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                        <Link to={`/empresas/${empresa.id}`}>{empresa.name}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Crear Centro</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Centro</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Nombre<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Ingresa el nombre" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Dirección<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Ingresa dirección" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Región<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control
                                            as="select" 
                                            name="region"
                                            value={formData.region}
                                            onChange={handleChange}
                                            >
                                            <option value="">Selecciona una región</option>
                                            {regionOptions.map((region, index) => (
                                                <option key={index} value={region}>
                                                {region}
                                                </option>
                                            ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Descripción</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="description" value={formData.description} onChange={handleChange} placeholder="Ingresa descripción" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Número de Jaulas</Form.Label>
                                            <Form.Control type="number" name="jaulas" value={formData.jaulas} onChange={handleChange} placeholder="Ingresa número de jaulas" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Formato de Módulos</Form.Label>
                                            <Form.Control type="text" name="modulos" value={formData.modulos} onChange={handleChange} placeholder="Ingresa formato de módulos" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Número de Módulos</Form.Label>
                                            <Form.Control type="number" name="nModulos" value={formData.nModulos} onChange={handleChange} placeholder="Ingresa número de módulos" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Link Video</Form.Label>
                                            <Form.Control type="text" name="video" value={formData.video} onChange={handleChange} placeholder="Ingresa link de video" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Imagen Centro</Form.Label>
                                            <Form.Control type="file" name="img" required id="img" onChange={(e) => handleFileUpload(e, 'imgCentro')} />
                                        </Form.Group>                                       
                                        <Form.Group className="mb-3">
                                            <Form.Label>Mapa del Centro</Form.Label>
                                            <Form.Control type="file" name="mapa" required id="mapa" onChange={(e) => handleFileUpload(e, 'mapaCentro')} />
                                        </Form.Group>
                                        {/* Latitud y Longitud */}               
                                        <Form.Group className="mb-3">
                                            <Form.Label>Latitud y Longitud <span onClick={handleOpen} ><FontAwesomeIcon icon="fas fa-solid fa-circle-question" /></span></Form.Label>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                                    Latitud y Longitud
                                                </Typography>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    "Por favor, ingrese la latitud y longitud en el formato sexagesimal. Por ejemplo, para latitud 20°35'27.6"N y longitud 100°23'27.6"W."
                                                </Typography>
                                                </Box> 
                                            </Modal>
                                            </Form.Group>            
                                            <Form.Group className="mb-3 row">
                                            <Form.Label column sm="2">
                                                Latitud
                                            </Form.Label>
                                            <Col sm="4">
                                                <Form.Control
                                                type="text"                          
                                                value={formData.latitud}
                                                name="latitud"
                                                onChange={handleChange}
                                                placeholder="Ingresa la latitud"                         
                                                />                     
                                            </Col>
                                            <Form.Label column sm="2">
                                                Longitud
                                            </Form.Label>
                                            <Col sm="4">
                                                <Form.Control
                                                type="text"                         
                                                value={formData.longitud}
                                                name="longitud"
                                                onChange={handleChange}
                                                placeholder="Ingresa la longitud"                        
                                                />
                                                
                                            </Col>
                                        </Form.Group>      
                                            {/* End Latitud y longitud */}
                                                    
                                                                <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                                                    <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>
                                        </div>
                                        <ModalB
                                            show={showModal}
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <ModalB.Header>
                                                <ModalB.Title>Guardando Centro</ModalB.Title>
                                            </ModalB.Header>
                                            <ModalB.Body>
                                                {`El centro ${formData.name} se está guardando, por favor espere...`}
                                            </ModalB.Body>
                                            <ModalB.Footer>
                                            </ModalB.Footer>
                                        </ModalB>
                                    </Form>
                                    {/* Vertical Form */}
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default CentrosCrear;