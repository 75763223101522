/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

/* Bootstrap */
import { Button } from 'react-bootstrap';

/* Tabs */
import 'react-tabs/style/react-tabs.css';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';

/* Rutas */
import { useParams } from 'react-router-dom';

/* Link */
import { Link, useNavigate } from 'react-router-dom';

/* Firebase Auth */
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const UsuariosId = () => {

  const { id } = useParams();

  const navigate = useNavigate();

  const [usuario, setUsuario] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      if (!id) return;

      const userRef = doc(db, "usuarios", id);

      try {
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          console.log("Usuario encontrado:", userDoc.data());
          setUsuario({ id: userDoc.id, empresaName: "", ...userDoc.data() });



          const empresaRef = doc(db, "empresa", userDoc.data().empresa.id);
          const empresaDoc = await getDoc(empresaRef);

          if (empresaDoc.exists()) {
            console.log("Empresa encontrada:", empresaDoc.data());
            const empresaName = empresaDoc.data().name;
            console.log("empresa name:", empresaName);
            setUsuario({ id: userDoc.id, empresaName: empresaName, ...userDoc.data() });
          } else {
            console.log("Empresa no encontrada");

          }


        } else {
          console.log("Usuario no encontrado");
          setUsuario(null);
        }
      } catch (error) {
        console.log('Error al obtener el usuario:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  /* Recuperar contraseña */
  const resetPassword = async (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Correo de recuperación de contraseña enviado a " + email)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("Error al enviar el correo de recuperación de contraseña")
        console.log(errorCode, errorMessage)
      });
  };



  if (isLoading) {
    return (
      <div>Cargando...</div>
    )
  }

  return (
    <>
      <Navbar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Usuario</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Inicio</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/usuarios">
                  Usuarios
                </Link>
              </li>
              <li className="breadcrumb-item active">{usuario.name}</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

                  <h2 style={{ paddingBottom: '10px' }}>{usuario.name}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body pt-3">
                  {/* Bordered Tabs */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Descripción
                      </button>
                    </li>
                    <li className="nav-item">
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <div
                      className="tab-pane fade show active profile-overview"
                      id="profile-overview"
                    >
                      <h5 className="card-title">Usuario</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label ">Nombre</div>
                        <div className="col-lg-9 col-md-8">{usuario.name}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Email</div>
                        <div className="col-lg-9 col-md-8">{usuario.email}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Rol</div>
                        <div className="col-lg-9 col-md-8">{usuario.rol}</div>
                      </div>
                      {usuario.rol === 'Cliente' && (
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Empresa</div>
                          <div className="col-lg-9 col-md-8">{usuario.empresaName}</div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Status</div>
                        <div className="col-lg-9 col-md-8">{usuario.status}</div>
                      </div>
                      <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                        <Button variant="primary" onClick={() => navigate(`/usuarios/${usuario.id}/editar`)}>
                          Editar
                        </Button>
                        <Button variant="primary" onClick={() => resetPassword(usuario.email)}>
                          Recuperar Contraseña
                        </Button>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade pt-3"
                      id="profile-change-password"
                    >
                    </div>
                  </div>
                  {/* End Bordered Tabs */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
      <Footer />
    </>
  );
}

export default UsuariosId;