/* React */
import React from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'


const Footer = () => {

  const fechaActual = new Date();
  const year = fechaActual.getFullYear();

  return (
    <footer id="footer" className="footer">
      <div className="copyright">
        © Copyright <strong><span>{`Pacific Rov ${year}`}</span></strong>. Todos los derechos reservados.
      </div>
      <div className="credits">
        Hecho con ❤️  <a href="https://solveraustral.com/" target='_blank'>Solver Austral</a>
      </div>
    </footer>
  );
}

export default Footer;