/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

import { Card, Button, Form, Modal } from 'react-bootstrap';

import { useForm } from 'react-hook-form';

/* React Router  */
import { Link, useNavigate, useParams } from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Time */
import moment from 'moment-timezone';


/*Firestore */
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import { getStorage, ref, uploadBytes } from "firebase/storage";

const LInspeccionesEditar = () => {

    /* Parametros */
    const { id, idCentro, idServicio, idInspeccion } = useParams();

    /*Time */
    const date = moment().tz('America/Santiago').toDate();

    /*Modal de espera mientras de Editar Inspección */
    const [showModal, setShowModal] = useState(false);

    /* Forms */
    const [formData, setFormData] = useState({
        name: '',
        composicion: '',
        anclaje: '',
        suelo: '',
        profundidad: '',
        video_url: '',
        obs_photo1: '',
        obs_photo2: '',
        obs_photo3: '',
        obs_photo4: '',
        obs_photo5: '',
        obs_photo6: '',
        obs_photo7: '',
        obs_photo8: '',
        obs_photo9: '',
        obs_photo10: '',
        observaciones: '',
        observacion_leve: '',
        comentarios: '',
        lev_observaciones: '',
    })

    /* Obtener los datos de la inspección */
    const [inspeccion, setInspeccion] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [empresa, setEmpresa] = useState([]);
    const [centro, setCentro] = useState([]);
    const [servicio, setServicio] = useState([]);

    useEffect(() => {
        // Función para manejar la finalización de las consultas
        let completedQueries = 0;

        const handleQueryCompletion = () => {
            completedQueries++;
            if (completedQueries === 4) {
                setIsLoading(false);
            }
        };

        // Consulta para obtener los datos de la empresa
        const getEmpresa = async () => {
            try {
                const empresaRef = doc(db, "empresa", id);
                const empresaSnap = await getDoc(empresaRef);

                // Verificar si el documento existe
                if (empresaSnap.exists()) {
                    const empresaData = empresaSnap.data();
                    setEmpresa(empresaData);
                } else {
                    console.log("No existe la empresa");
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener la empresa:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos del centro
        const getCentro = async () => {
            try {
                const centroRef = doc(db, "centro", idCentro);
                const centroSnap = await getDoc(centroRef);

                if (centroSnap.exists()) {
                    const centroData = centroSnap.data();
                    setCentro(centroData);
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener el centro:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos del servicio
        const getServicio = async () => {
            try {
                const servicioRef = doc(db, "servicios", idServicio);
                const servicioSnap = await getDoc(servicioRef);

                if (servicioSnap.exists()) {
                    const servicioData = servicioSnap.data();
                    setServicio(servicioData);
                }

                handleQueryCompletion();
            } catch (e) {
                console.log("Error al obtener el servicio:", e);
                handleQueryCompletion();
            }
        };

        // Consulta para obtener los datos de la inspección
        const fetchInspeccion = async () => {
            if (!idInspeccion) return;

            try {
                const inspeccionRef = doc(db, "linspecciones", idInspeccion);
                const inspeccionDoc = await getDoc(inspeccionRef);

                if (inspeccionDoc.exists()) {
                    const inspeccionData = inspeccionDoc.data();
                    const folioServicioRef = inspeccionData.folio_servicio;
                    const servicioDoc = await getDoc(folioServicioRef);

                    if (servicioDoc.exists()) {
                        const servicioData = servicioDoc.data();
                        const servicio = servicioData.folio;
                        const inspeccionConFolio = {
                            id: inspeccionDoc.id,
                            ...inspeccionData,
                            servicio
                        };
                        setInspeccion(inspeccionConFolio);
                    } else {
                        console.log("No existe el documento de servicio asociado");
                    }
                } else {
                    setInspeccion(null);
                }

                handleQueryCompletion();
            } catch (error) {
                console.log('Error al obtener la inspección:', error);
                handleQueryCompletion();
            }
        };

        // Ejecutar todas las consultas al cargar el componente
        getEmpresa();
        getCentro();
        getServicio();
        fetchInspeccion();
    }, []);

    useEffect(() => {
        if (inspeccion) {
            setFormData(
                {
                    name: inspeccion.nombre,
                    composicion: inspeccion.composicion,
                    anclaje: inspeccion.anclaje,
                    suelo: inspeccion.suelo,
                    profundidad: inspeccion.profundidad,
                    video_url: inspeccion.video_url,
                    obs_photo1: inspeccion.ob_photo1,
                    obs_photo2: inspeccion.ob_photo2,
                    obs_photo3: inspeccion.ob_photo3,
                    obs_photo4: inspeccion.ob_photo4,
                    obs_photo5: inspeccion.ob_photo5,
                    obs_photo6: inspeccion.ob_photo6,
                    obs_photo7: inspeccion.ob_photo7,
                    obs_photo8: inspeccion.ob_photo8,
                    obs_photo9: inspeccion.ob_photo9,
                    obs_photo10: inspeccion.ob_photo10,
                    observaciones: inspeccion.observacion,
                    observacion_leve: inspeccion.observacion_leve,
                    comentarios: inspeccion.comentario,
                    lev_observaciones: inspeccion.lev_observacion,
                }
            );
        }
    }, [inspeccion]);

    /* navigate */
    const navigate = useNavigate();

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        let newFormData = { ...formData };
      
        if (name === 'observaciones') {
          newFormData = {
            ...newFormData,
            observaciones: value,
            observacion_leve: '', // Borra el contenido de "Observación Leve"
          };
        } else if (name === 'observacion_leve') {
          newFormData = {
            ...newFormData,
            observaciones: '', // Borra el contenido de "Observación Crítica"
            observacion_leve: value,
          };
        } else {
          newFormData = {
            ...newFormData,
            [name]: value,
          };
        }      
        setFormData(newFormData);
      };
      
    /* FileUpload */
    const storage = getStorage();

    const handleFileUpload = async (event, fieldName) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const allowedExtensions = ['jpg', 'jpeg', 'png'];

        if (allowedExtensions.includes(fileExtension)) {
            // Continuar con la carga del archivo       
            setFormData((prevState) => ({
                ...prevState,
                [fieldName]: {
                    file: file,
                    fileExtension: fileExtension,
                }
            }));
        } else {
            alert('Solo se permiten imágenes JPG, PNG o JPEG.');
            // Resetear el input de archivo
            event.target.value = null;
        }
    };

    /* Submit */
    const handleSubmit = async () => {
        try {
            const inspeccionesRef = doc(db, "linspecciones", idInspeccion)
            console.log("handleSubmit function called.");
            if (!formData.name) {
                alert('Rellene los campos obligatorios');
                return;
            }
            setShowModal(true);

            let status = "Sin Observación";

            if (formData.lev_observaciones) {
            status = "Observación Levantada";
            } else if (formData.observacion_leve) {
            status = "Observación Leve"; 
            } else if (formData.observaciones) {
            status = "Observación Crítica";
            }

            const docData = {
                nombre: formData.name,
                composicion: formData.composicion,
                anclaje: formData.anclaje,
                suelo: formData.suelo,
                profundidad: formData.profundidad,
                video_url: formData.video_url,
                ob_photo1: formData.obs_photo1,
                ob_photo2: formData.obs_photo2,
                ob_photo3: formData.obs_photo3,
                ob_photo4: formData.obs_photo4,
                ob_photo5: formData.obs_photo5,
                ob_photo6: formData.obs_photo6,
                ob_photo7: formData.obs_photo7,
                ob_photo8: formData.obs_photo8,
                ob_photo9: formData.obs_photo9,
                ob_photo10: formData.obs_photo10,
                observacion: formData.observaciones,
                observacion_leve: formData.observacion_leve,
                comentario: formData.comentarios,
                lev_observacion: formData.lev_observaciones,
                date_update: date,
                folio: inspeccion.folio,
                status: status,
            };

            await updateDoc(inspeccionesRef, docData);
            setShowModal(false);

            alert(`La Inspección de Linea N°${inspeccion.folio} ha sido actualizada`);
            navigate(`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}/inspecciones-linea/${idInspeccion}`)
        } catch (error) {
            alert(`Error al agregar la nueva inspección: ${error}`);
        }
    };

    // Mostrar "Cargando..." mientras se están obteniendo los datos
    if (isLoading) {
        return (
            <div>Cargando...</div>
        );
    }

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Editar Inspección de Línea</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Inicio</Link>
                            </li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}`}>{empresa.name}</Link></li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}`}>{centro.name}</Link></li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}`}>{`Servicio N°${servicio.folio}`}</Link></li>
                            <li className='breadcrumb-item active'>{`Inspección N°${inspeccion.folio}`}</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Inspecciones de Línea</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nombre<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Ingresa el nombre" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Composición Fondeo</Form.Label>
                                            <Form.Control type="text" name="composicion" value={formData.composicion} onChange={handleChange} placeholder="Ingresa composición" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Anclaje</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="anclaje"
                                                value={formData.anclaje}
                                                onChange={handleChange}
                                                placeholder="Selecciona el tipo de anclaje"                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="Ancla">Ancla</option>
                                                <option value="Perno Costa">Perno Costa</option>
                                                <option value="Peso Muerto">Peso Muerto</option>
                                                <option value="Muerto Ancla">Muerto Ancla</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tipo Suelo</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="suelo"
                                                value={formData.suelo}
                                                onChange={handleChange}
                                                placeholder="Selecciona el tipo de suelo"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="Arena">Arena</option>
                                                <option value="Roca">Roca</option>
                                                <option value="Piedra">Piedra</option>
                                                <option value="Fango">Fango</option>
                                                <option value="No observado">No observado</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Profundidad</Form.Label>
                                            <Form.Control type="number" name="profundidad" value={formData.profundidad} onChange={handleChange} placeholder="Ingresa la profundidad" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Video Link</Form.Label>
                                            <Form.Control type="text" name="video_url" value={formData.video_url} onChange={handleChange} placeholder="Ingresa link de video" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Comentarios</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="comentarios" value={formData.comentarios} onChange={handleChange} placeholder="Ingresa comentarios" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Crítica</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="observaciones" value={formData.observaciones} onChange={handleChange} placeholder="Ingresa observaciones" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observación Leve</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="observacion_leve" value={formData.observacion_leve} onChange={handleChange} placeholder="Ingresa observaciones" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Levantantamiento Observaciones</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="lev_observaciones" value={formData.lev_observaciones} onChange={handleChange} placeholder="Ingresa levantamiento observaciones" />
                                        </Form.Group>
                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>
                                        </div>
                                        <Modal
                                            show={showModal}
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Actulizando Inspección</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {`La Inspección de Línea N°${inspeccion.folio} se está actualizando, por favor espere...`}
                                            </Modal.Body>
                                            <Modal.Footer>
                                            </Modal.Footer>
                                        </Modal>
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default LInspeccionesEditar;