/* React */
import React, { useState, useEffect } from "react";

/* CSS */
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/boxicons/css/boxicons.min.css";
import "../assets/vendor/quill/quill.snow.css";
import "../assets/vendor/quill/quill.bubble.css";
import "../assets/vendor/remixicon/remixicon.css";
import "../assets/vendor/simple-datatables/style.css";
import "../assets/css/style.css";

import { Card, Button, Form} from "react-bootstrap";

/* Rutas */
import { useParams, Link } from "react-router-dom";

/* React Router  */
import { useNavigate } from "react-router-dom";

/* Components */
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

/*Firestore */
import { collection, doc, query, where, getDocs, addDoc, orderBy, limit, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";

const ServicioCrear = () => {

    const navigate = useNavigate();

    const { id, idCentro } = useParams();

    //const { register, formState: { errors } } = useForm();
    //Servicios
    const [usersAdmins, setusersAdmins] = useState([]);
    const [usersColabs, setusersColab] = useState([]);

    //centro
    const [centro, setCentro] = useState([]);
    const [empresa, setEmpresa] = useState([]);

    //Loading
    const [isLoading, setIsLoading] = useState(true);

    //Folio Max
    const [folioMax, setFolioMax] = useState(0);

    let usersResp = []

    useEffect(() => {
        const usuariosAdminsRef = collection(db, "usuarios");
        const fetchUsuarios = async () => {
            const usuariosAdminsQuery = query(usuariosAdminsRef, where("rol", "==", "Administrador"));
            try {
                const adminsSnapshot = await getDocs(usuariosAdminsQuery);
                const adminsList = adminsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setusersAdmins(adminsList);
            }
            catch (error) {
                console.log('Error al obtener los usuarios administradores:', error);
            }
        };

        const fetchUsuariosColabs = async () => {
            const usuariosColabQuery = query(usuariosAdminsRef, where("rol", "==", "Colaborador"));
            try {
                const colabSnapshot = await getDocs(usuariosColabQuery);
                const colabList = colabSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setusersColab(colabList);
            }
            catch (error) {
                console.log('Error al obtener los usuarios colaboradores:', error);
            }
        };

        // Función para obtener el próximo folio
        const getNextFolio = async () => {
            try {
                // Referencia a la colección 
                const serviciosRef = collection(db, "servicios");

                // Consulta ordenada por folio descendente 
                const q = query(
                    serviciosRef,
                    orderBy("folio", "desc"),
                    limit(1)
                );

                // Ejecutar la consulta
                const querySnapshot = await getDocs(q);

                // Mapear los datos
                const serviciosNum = querySnapshot.docs.map(doc => doc.data())

                setFolioMax(serviciosNum[0]['folio'] + 1);

            } catch (error) {
                console.log(error);

            }
        };

        const fetchDataCentro = async () => {
            const centroRef = doc(db, "centro", idCentro)
            const centroSnapshot = await getDoc(centroRef)
            if (!centroSnapshot.exists()) return;
            const centroData = centroSnapshot.data()
            setCentro(centroData)
            const empresaRef = doc(db, "empresa", centroData.empresa.id)
            const empresaSnapshot = await getDoc(empresaRef)
            if (!empresaSnapshot.exists()) return;
            const empresaData = empresaSnapshot.data()
            setEmpresa(empresaData)

            setIsLoading(false);
        }

        fetchDataCentro();  
        getNextFolio();
        fetchUsuarios();
        fetchUsuariosColabs();
    }, []);

    usersResp = usersAdmins.concat(usersColabs)

    /* Forms */
    const [formData, setFormData] = useState({
        autoriza: '',
        centro: '',
        comentario: '',
        date: '',
        date_create: '',
        date_update: '',
        date_inicio_faena: '',
        date_fin_faena: '',
        etapa: '',
        folio: '',
        informe: '',
        responsable: '',
        revisa: '',
        tipo: '',
        rov: '',    
    })

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
            folio: folioMax,
        }));
    };

    /* Submit */
    const handleSubmit = async () => {

        if (!formData.autoriza || 
            !formData.revisa || 
            !formData.responsable || 
            !formData.date_create || 
            !formData.etapa || 
            !formData.rov ||
            !formData.date_fin_faena ||
            !formData.date_inicio_faena) {
            alert('Los campos son obligatorios');
            return;
        }

        const msjsError = [
            "El usuario que revisa no puede ser el mismo usuario que autoriza",
            "El usuario que autoriza no puede ser el mismo usuario Piloto Rov",
            "El usuario Piloto Rov no puede ser el mismo usuario revisa",
        ]

        if (formData.autoriza == formData.revisa) {
            alert(msjsError[0]);
            return;
        } else if (formData.autoriza == formData.responsable) {
            alert(msjsError[1]);
            return;
        } else if (formData.revisa == formData.responsable) {
            alert(msjsError[2]);
            return;
        }

        try {
            console.log("handleSubmit function called.");

            //Hora de chile
            const date = new Date(`${formData.date_create}T15:30:00.000-03:00`);
            const date_inicio_faena = new Date(`${formData.date_inicio_faena}T15:30:00.000-03:00`);
            const date_fin_faena = new Date(`${formData.date_fin_faena}T15:30:00.000-03:00`);

            await addDoc(collection(db, "servicios"), {
                autoriza: doc(db, "usuarios/", formData.autoriza),
                centro: doc(db, "centro/", idCentro),
                date: date,
                date_inicio_faena: date_inicio_faena,
                date_fin_faena: date_fin_faena,
                date_create: serverTimestamp(),
                etapa: formData.etapa,
                folio: formData.folio,
                responsable: doc(db, "usuarios/", formData.responsable),
                revisa: doc(db, "usuarios/", formData.revisa),
                tipo: formData.tipo,
                rov: formData.rov
            });

            alert(`El servicio ${formData.folio} ha sido guardado`);
            navigate(`/empresas/${id}/centros/${idCentro}`);
        } catch (error) {
            alert(`Error al agregar nuevo cliente o servicio: ${error}`);
        }
    };

    if (isLoading) {
        return (
            <div>Cargando...</div>
        )
    }  

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Crear Servicio</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Inicio</Link>
                            </li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}`}>{empresa.name}</Link></li>
                            <li className="breadcrumb-item active"><Link to={`/empresas/${id}/centros/${idCentro}`}>{centro.name}</Link></li>                            
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Servicio</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3 d-none" >
                                            <Form.Label>Centro</Form.Label>
                                            <Form.Control type="text" name="centro" value={idCentro} disabled />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="date" value={formData.date_create} onChange={handleChange} name="date_create" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha Inicio Faena<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="date" value={formData.date_inicio_faena} onChange={handleChange} name="date_inicio_faena" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha Término Faena<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="date" value={formData.date_fin_faena} onChange={handleChange} name="date_fin_faena" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tipo de Servicio</Form.Label>
                                            <Form.Control as="select" value={formData.tipo} name="tipo" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                <option value="Certificación Anual">Certificación Anual</option>
                                                <option value="Verificación Semestral">Verificación Semestral</option>                                              
                                                <option value="Levantamiento observaciones">Levantamiento observaciones</option>
                                                <option value="Certificación de inicio de ciclo">Certificación de inicio de ciclo</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Etapa<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.etapa} name="etapa" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                <option value="Inspección">Inspección</option>
                                                <option value="Cargando Datos">Cargando Datos</option>
                                                <option value="Completado">Completado</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Autoriza<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.autoriza} name="autoriza" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                {usersAdmins.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Revisa<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.revisa} name="revisa" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                {usersAdmins.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Piloto Rov<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.responsable} name="responsable" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                {usersResp.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group> 
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Rov utilizado<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="rov" value={formData.rov} onChange={handleChange} placeholder="Ingresa el nombre" />
                                        </Form.Group>                               
                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>                                            
                                        </div>
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <Footer />
                </section>
            </main>
        </>
    )

};

export default ServicioCrear;
