/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

/* Bootstrap */
import { Button } from 'react-bootstrap';

/* Tabs */
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/*Firestore */
import { collection, doc, getDoc, query, where, getDocs, deleteDoc } from "firebase/firestore";
import { db } from '../firebase';

/* Rutas */
import { useParams } from 'react-router-dom';

/* Link */
import { Link, useNavigate } from 'react-router-dom';

/*Context */
import Context from "../context";

/* Export CSV */
import { mkConfig, generateCsv, download } from "export-to-csv";

/* Google Maps */
import GoogleMapsComponent from '../components/GoogleMaps';

const CentrosId = () => {

  const { user } = useContext(Context);

  const { id, idCentro } = useParams();

  const [tabIndex, setTabIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  // Centros
  const [centro, setCentro] = useState([]);
  const [empresa, setEmpresa] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  //Servicios
  const [servicios, setServicios] = useState([]);

  useEffect(() => {

    const fetchEmpresa = async () => {
      if (!id) return;

      const empresaRef = doc(db, "empresa", id);
      const empresaSnapshot = await getDoc(empresaRef);


      if (empresaSnapshot.exists()) {
        const empresaData = empresaSnapshot.data();
        const nombreEmpresa = empresaData.name;
        const idEmpresa = empresaSnapshot.id;

        const emp = {
          name: nombreEmpresa,
          id: idEmpresa
        };

        setEmpresa(emp);
      }
    };

    const fetchCentro = async () => {
      if (!idCentro) return;
      const centroRef = doc(db, "centro", idCentro);
      try {
        const centroDoc = await getDoc(centroRef);
        if (centroDoc.exists()) {
          setCentro({ id: centroDoc.id, ...centroDoc.data() });
        } else {
          setCentro(null);
        }
      } catch (error) {
        console.log('Error al obtener la empresa:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchServicios = async () => {
      const centroRef = doc(db, "centro", idCentro);
      const seriviciosRef = collection(db, "servicios");
      const serviciosQuery = query(seriviciosRef, where("centro", "==", centroRef));
      try {
        const serviciosSnapshot = await getDocs(serviciosQuery);
        const serviciosList = serviciosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setServicios(serviciosList);
      }
      catch (error) {
        console.log('Error al obtener los servicios:', error);
      }
    };

    const fecthDocumentos = async () => {
      const centroRef = doc(db, "centro", idCentro);
      const documentosRef = collection(db, "documentos");
      const documentosQuery = query(documentosRef, where("centro", "==", centroRef));
      try {
        const documentosSnapshot = await getDocs(documentosQuery);
        const documentosList = documentosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDocumentos(documentosList);
      }
      catch (error) {
        console.log('Error al obtener los documentos:', error);
      }
    };

    fetchServicios();
    fetchCentro();
    fetchEmpresa();
    fecthDocumentos();
  }, []);

  if (isLoading) {
    return (
      <div>Cargando...</div>
    )
  }

  /* Unix to Date */
  const unixTimeToDateString = (unixTime) => {
    const date = new Date(unixTime * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Agrega un cero inicial si es necesario
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  //Numero random para que se cargue bien la imagen
  const randomNumber = Math.floor(Math.random() * 10000);

  // Función para eliminar un documento
  const deleteDocumento = async (idDoc) => {
    // Muestra un cuadro de diálogo de confirmación al usuario
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este documento?");

    if (confirmDelete) {
      const inspeccionRef = doc(db, "documentos", idDoc);

      try {
        await deleteDoc(inspeccionRef);
        alert('Documento eliminado correctamente.');
        window.location.reload();
      } catch (error) {
        console.error('Error al eliminar el documento: ', error);
      }
    } else {
      console.log('Eliminación cancelada por el usuario.');
    }
  };

  /* <CSV></CSV> */
  const downloadCsv = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "servicios" });
    const filterServiciosCsv = servicios.map(servicio => ({
      folio: servicio.folio,
      fecha: unixTimeToDateString(servicio.date.seconds),
      etapa: servicio.etapa,
      tipo: servicio.tipo,
    }));
    const csv = generateCsv(csvConfig)(filterServiciosCsv);
    download(csvConfig)(csv);
  };

  return (
    <>
      <Navbar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Centro</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Inicio</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/empresas/${empresa.id}`}>{empresa.name}</Link>
              </li>
              <li className="breadcrumb-item active">{centro.name}</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src={centro.img_centro + "?=" + randomNumber}
                    alt="Centro"
                    className="rounded-circle"                 
                  />
                  <h2 style={{ paddingBottom: '10px' }}>{centro.name}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body pt-3">

                  {/* Bordered Tabs */}
                  <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} >
                    <TabList className="nav nav-tabs nav-tabs-bordered">
                      <Tab className="nav-link active">Datos Centro</Tab>
                      <Tab className="nav-link ">Descripción</Tab>                     
                      <Tab className="nav-link ">Imagen</Tab>
                      <Tab className="nav-link ">Mapa</Tab>
                    </TabList>
                    {/*Descripcion */}
                    <TabPanel className="tab-content pt-2">
                      <div
                        className=" show active profile-overview"
                        id="profile-overview"
                      >
                        <h5 className="card-title">Centro</h5>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label ">Nombre</div>
                          <div className="col-lg-9 col-md-8">{centro.name}</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Empresa</div>
                          <div className="col-lg-9 col-md-8">{empresa.name}</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Dirección</div>
                          <div className="col-lg-9 col-md-8">{centro.address}</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Región</div>
                          <div className="col-lg-9 col-md-8">{centro.region}</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Status</div>
                          <div className="col-lg-9 col-md-8">{centro.status}</div>
                        </div>
                      </div>
                      <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                      </div>
                    </TabPanel>
                    {/* Descripcion */}
                    <TabPanel className="tab-content pt-2">
                      <div
                        className="tab-pane fade show active profile-overview"
                        id="profile-overview"
                      >
                        <h5 className="card-title">Descripción</h5>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            {centro.description}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Formato de Jaulas</div>
                          <div className="col-lg-9 col-md-8">{centro.jaulas}</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Formato de Módulos</div>
                          <div className="col-lg-9 col-md-8">{centro.modulos}</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Número de Módulos</div>
                          <div className="col-lg-9 col-md-8">{centro.nModulos}</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 label">Mapa del centro</div>
                          <div className="col-lg-9 col-md-8">
                            <Link to={`${centro.mapa_centro}?=${randomNumber}`} download>
                              Descargar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </TabPanel>                  
                    {/* Imagenes */}
                    <TabPanel className="tab-content pt-2">
                      <h5 className="card-title">Imagen</h5>
                      <img src={centro.img_centro + "?=" + randomNumber} className='img-fluid'>
                      </img>
                      <div className="row" style={{ paddingTop: '15px' }}>
                        <div className="col-lg-3 col-md-4 label">Video</div>
                        <div className="col-lg-9 col-md-8">
                          <Link to={centro.video + "?=" + randomNumber} download>
                            Ver video
                          </Link>
                        </div>
                      </div>
                    </TabPanel>
                    {/* Coordenadas */}
                    <TabPanel className="tab-content pt-2">
                      <div
                        className="tab-pane fade show active profile-overview"
                        id="profile-overview"
                      >
                        <h5 className="card-title">Mapa</h5>
                        <div className="row">
                        {centro.geopoint1_lat && (
                          <div>
                            <GoogleMapsComponent
                              name={centro.name}
                              description={`Latitud: ${centro.geopoint1_lat} Longitud: ${centro.geopoint1_lon}`}
                              coordinates={{ lat: centro.geopoint1._lat, lng: centro.geopoint1._long }}
                            />
                          </div>
                        )}
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                  {/* End Bordered Tabs */}
                  {user[0].rol === "Administrador" && (
                    <Button variant="primary" onClick={() => navigate(`/empresas/${id}/centros/${idCentro}/editar`)}>
                      Editar
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            {/* Documentos */}
            <div className="col-12 " >
              <div className="card recent-sales overflow-auto table-responsive">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown">
                  </a>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    Documentos
                  </h5>
                  <table className="table table-borderless datatable">
                    <thead>
                      <tr>
                        <th scope="col" className="d-none d-sm-table-cell">Folio</th>
                        <th scope="col">Nombre</th>                        
                        {user[0].rol === "Administrador" && (
                          <>
                            <th scope="col">Editar</th>
                            <th scope="col">Eliminar</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {documentos.map((documento, index) => (
                        <tr key={index}>
                          <td className="d-none d-sm-table-cell">{documento.folio}</td>
                          <td><Link to={documento.enlace + "?=" + randomNumber} target="_blank">{documento.name}</Link></td>                          
                          {user[0].rol === "Administrador" && (
                            <>
                              <td>
                                <Button className="btn btn-primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/empresas/${id}/centros/${idCentro}/editar-documento/${documento.id}`)}>
                                  Editar
                                </Button>
                              </td>
                              <td>
                                <Button className="btn btn-danger" style={{ fontSize: '0.65rem' }} onClick={() => deleteDocumento(documento.id)}>
                                  Eliminar
                                </Button>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                    {user[0].rol === "Administrador" && (
                      <Button variant="primary" onClick={() => navigate(`/empresas/${id}/centros/${idCentro}/crear-documento`)}>
                        Añadir Documento
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* End Centros */}
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            {/* Servicios */}
            <div className="col-12 " >
              <div className="card recent-sales overflow-auto table-responsive">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown">
                  </a>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    Servicios Inspección de Línea
                  </h5>
                  <table className="table table-borderless datatable">
                    <thead>
                      <tr>
                        <th scope="col">Folio</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Etapa</th>
                        <th scope="col" className="d-none d-sm-table-cell">Tipo</th>
                        <th scope="col">Entrar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {servicios.map((servicio, index) => (
                        <tr key={index}>
                          <td>{servicio.folio}</td>
                          <td>{unixTimeToDateString(servicio.date.seconds)}</td>
                          <td>
                            <span
                              className={`badge ${servicio.etapa === "Completado" ? "bg-success" : servicio.etapa === "Inspección" ? "bg-danger" : "bg-warning"
                                }`}
                            >
                              {servicio.etapa}
                            </span>
                          </td>
                          <td className="d-none d-sm-table-cell">{servicio.tipo}</td>
                          <td>
                            <Button className="btn btn-primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/empresas/${id}/centros/${idCentro}/servicios/${servicio.id}`)}>
                              Entrar
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                    {user[0].rol === "Administrador" && (
                      <Button variant="primary" onClick={() => navigate(`/empresas/${id}/centros/${idCentro}/crear-servicio`)}>
                        Añadir Servicio
                      </Button>
                    )}
                    <button className="btn btn-primary" onClick={downloadCsv}>Exportar CSV</button>
                  </div>
                </div>
              </div>
            </div>
            {/* End Centros */}
          </div>
        </section>
      </main>
      {/* End #main */}
      <Footer />
    </>
  );
}

export default CentrosId;