/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

/* Bootstrap */
import { Button } from 'react-bootstrap';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Rutas */
import { useParams } from 'react-router-dom';

/* Importar Navegate */
import { Link, useNavigate } from 'react-router-dom';

/*Firestore */
import { collection, doc, getDoc, query, where, getDocs} from "firebase/firestore";
import { db } from '../firebase';

/*Context */
import Context from "../context";

/* Export CSV */
import { mkConfig, generateCsv, download } from "export-to-csv";

const EmpresasId = () => {

  const { user } = useContext(Context);

  const { id } = useParams();

  const navigate = useNavigate();

  /* Ordenar los centros */
  const [sortType, setSortType] = useState("asc");
  const [sortField, setSortField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [empresa, setEmpresa] = useState(null);
  const [centros, setCentros] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEmpresa = async () => {
      if (!id) return;
      const empresaRef = doc(db, "empresa", id);
      try {
        const empresaDoc = await getDoc(empresaRef);
        if (empresaDoc.exists()) {
          setEmpresa({ id: empresaDoc.id, ...empresaDoc.data() });
        } else {
          setEmpresa(null);
        }
      } catch (error) {
        console.log('Error al obtener la empresa:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEmpresa();
  }, []);

  useEffect(() => {
    const fetchCentro = async () => {
      const empresaRef = doc(db, "empresa", id);
      const centrosRef = collection(db, "centro");
      const centrosQuery = query(centrosRef, where("empresa", "==", empresaRef));

      try {
        const querySnapshot = await getDocs(centrosQuery);
        console.log(querySnapshot);
        const centross = [];

        querySnapshot.forEach((doc) => {
          centross.push({
            id: doc.id,
            ...doc.data()
          });
        });

        console.log(centross);
        setCentros(centross);
      } catch (error) {
        console.log('Error al obtener los centros:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCentro();
  }, []);

  console.log("centros", centros)

  if (isLoading) {
    return (
      <div>Cargando...</div>
    )
  }

  //Numero random para que se cargue bien la imagen
  const randomNumber = Math.floor(Math.random() * 10000);

  /* Funciones para ordenar los centros  */
  const handleSort = (field) => {
    // Verifica si se está ordenando por el mismo campo para invertir el tipo de ordenamiento
    const newSortType = sortField === field && sortType === "asc" ? "desc" : "asc";

    // Actualiza el campo y el tipo de ordenamiento
    setSortField(field);
    setSortType(newSortType);

    // Realiza el ordenamiento de los centros según el campo y el tipo de ordenamiento
    const sortedCentros = [...centros].sort((a, b) => {
      const valueA = getValueByField(a, field);
      const valueB = getValueByField(b, field);

      if (valueA < valueB) {
        return newSortType === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return newSortType === "asc" ? 1 : -1;
      }
      return 0;
    });
    // Actualiza los centros ordenados
    setCentros(sortedCentros);
  };

  // Función auxiliar para obtener el valor de un campo anidado
  const getValueByField = (object, field) => {
    const fields = field.split(".");
    let value = object;

    fields.forEach((f) => {
      value = value[f];
    });
    return value;
  };

  /* Paginación */
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCentros = centros.slice(indexOfFirstItem, indexOfLastItem);

  /* <CSV></CSV> */
  const downloadCsv = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "centros" });
    const filterCentroCsv = centros.map(centro => ({
      centro: centro.name,
      status: centro.status,
    }));
    const csv = generateCsv(csvConfig)(filterCentroCsv);
    download(csvConfig)(csv);
  };

  return (
    <>
      <Navbar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Empresa</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Inicio</Link>
              </li>
              <li className="breadcrumb-item active">{empresa.name}</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src={empresa.logo + "?=" + randomNumber}
                    alt="Empresa"
                    className="rounded-circle"
                  />
                  <h2 style={{ paddingBottom: '10px' }}>{empresa.name}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body pt-3">
                  {/* Bordered Tabs */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Descripción
                      </button>
                    </li>
                    <li className="nav-item">
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <div
                      className="tab-pane fade show active profile-overview"
                      id="profile-overview"
                    >
                      <h5 className="card-title">Empresa</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label ">Nombre</div>
                        <div className="col-lg-9 col-md-8">{empresa.name}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Rut</div>
                        <div className="col-lg-9 col-md-8">{empresa.rut}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Dirección</div>
                        <div className="col-lg-9 col-md-8">{empresa.address}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Status</div>
                        <div className="col-lg-9 col-md-8">{empresa.status}</div>
                      </div>
                      <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                        {user[0].rol === "Administrador" && (
                          <Button variant="primary" onClick={() => navigate(`/empresas/${empresa.id}/editar`)}>
                            Editar
                          </Button>
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade pt-3"
                      id="profile-change-password"
                    >
                    </div>
                  </div>
                  {/* End Bordered Tabs */}
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            {/* Centros */}
            <div className="col-12 " >
              <div className="card recent-sales overflow-auto table-responsive">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown">
                  </a>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    Centros
                  </h5>
                  <table className="table table-borderless datatable">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Centro</th>
                        <th scope="col">Status</th>
                        <th scope="col">Entrar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentCentros.map((centro, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <div>#{index + 1}</div>
                          </th>
                          <td>
                            <div className="text-primary">
                              {centro.name}
                            </div>
                          </td>
                          <td>
                            <span
                              className={`badge ${centro.status === "Activo" ? "bg-success" : "bg-danger"
                                }`}
                            >
                              {centro.status}
                            </span>
                          </td>
                          <td>

                            <Button className="btn btn-primary" style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/empresas/${id}/centros/${centro.id}`)}>
                              Entrar
                            </Button>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination">
                    <button
                      className="btn btn-link"
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      Anterior
                    </button>
                    <button
                      className="btn btn-link"
                      disabled={indexOfLastItem >= centros.length}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      Siguiente
                    </button>
                  </div>
                  <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                    {user[0].rol === "Administrador" && (
                      <Button variant="primary" onClick={() => navigate(`/empresas/${id}/centros-crear`)}>
                        Añadir Centro
                      </Button>
                    )}
                    <button className="btn btn-primary" onClick={downloadCsv}>Exportar CSV</button>
                  </div>
                </div>
              </div>
            </div>
            {/* End Empresas */}
          </div>
        </section>
      </main>
      {/* End #main */}
      <Footer />
    </>
  );
}

export default EmpresasId;