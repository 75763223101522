// Importar la biblioteca jsPDF
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

/*Firestore */
import { collection, doc as docSer, getDoc, query, where, getDocs, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
import { getStorage, ref, uploadBytes, } from "firebase/storage";

const servicePDF = async (service, centro, empresa, inspecciones, documentos, responsable, idServicio) => {

  const unixTimeToDateString = (unixTime) => {
    try {
      const date = new Date(unixTime.seconds * 1000);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Agrega un cero inicial si es necesario
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      return `${formattedDay}-${formattedMonth}-${year}`;
    }
    catch (error) {
      return '';
    }
  }

  try {
    const generatePDf = async () => {

      console.time("servicePDF"); // Iniciar temporizador
      // Calidad de imagenes
      const imageQuality = 0.7;

      // Crear una nueva instancia de jsPDF
      var doc = new jsPDF("p", "mm", "letter", true);

      // Funciones
      // Obtener la extensión de un archivo
      const getExtension = (filename) => {
        const extension = filename.split('.').pop().toUpperCase();
        if (extension === 'JPG' || extension === 'JPEG') {
          return 'JPEG';
        } else {
          return extension;
        }
      }

      // Agregar un footer 
      const addFooter = () => {
        const pageCount = doc.internal.getNumberOfPages(); // Obtener el número de páginas actual

        for (let i = 2; i <= pageCount; i++) {
          doc.setPage(i); // Establecer la página actual

          // Agregar el footer al final de la página
          doc.setFontSize(11);
          doc.setFont('arial', 'normal');
          doc.setTextColor(0, 0, 0);
          const footer = `PACIFIC ROV ${currentYear} - CENTRO ${centro.name.toUpperCase()} - N° ${service.folio} - PÁGINA ${i} de ${pageCount}`
          doc.text(footer, 110, 260, { maxWidth: 160, align: "center", });
          doc.addImage('https://storage.googleapis.com/pacificrov-7015f.appspot.com/pdf/logo_desvanecido_640x360.png', 'PNG', 180, 20, 20, 15);
        }
      }

      // A gregar espacio después del guión 
      const agregarEspacioDespuesGuion = (cadena) => {
        return cadena.replace(/-(\w)/g, '- $1');
      }

      // Obtener año actual
      const now = new Date();
      const currentYear = now.getFullYear();

      const fontCalibri = fetch('/fonts/calibri/Calibri-Bold.ttf')
      const fontCalibreBuffer = fontCalibri.arrayBuffer // Ruta al archivo de fuente

      //Ancho máximo 
      var maxWidth = 170;

      doc.setFontSize(12);

      doc.setFont("helvetica", "bold");

      doc.text('ROBÓTICA SUBMARINA', 17, 20);

      doc.addImage('https://storage.googleapis.com/pacificrov-7015f.appspot.com/pdf/icon_1_640x360.png', 'PNG', 19, 23, 9, 9);
      doc.addImage('https://storage.googleapis.com/pacificrov-7015f.appspot.com/pdf/icon_2_640x360.png', 'PNG', 33, 23, 9, 9);
      doc.addImage('https://storage.googleapis.com/pacificrov-7015f.appspot.com/pdf/icon_3_640x360.png', 'PNG', 48, 23, 9, 9);
      doc.addImage('https://storage.googleapis.com/pacificrov-7015f.appspot.com/pdf/logo_640x360.png', 'PNG', 180, 20, 20, 15);

      // insertar rectanfunlo de color #174d8a
      doc.setFillColor(23, 77, 138);
      doc.rect(0, 50, 160, 220, 'F'); // Crea un rectángulo con el color de relleno especificado

      doc.addImage('https://storage.googleapis.com/pacificrov-7015f.appspot.com/pdf/pacific_640x360.png', 'PNG', 85, 170, 70, 70);

      // CERTIFICACIÓN
      doc.setFontSize(16);

      // Tipo de Certificación
      doc.setTextColor(255, 255, 255);

      doc.text(`INFORME INSPECCIÓN DE LÍNEA DE FONDEO`, 17, 75);
      doc.text(`PARA ${service.tipo.toUpperCase()}`, 17, 83)

      // Datos del centro 
      doc.setTextColor(255, 179, 29);
      doc.setFontSize(14);
      doc.text(`CENTRO - ${centro.name.toUpperCase()}`, 17, 105);
      doc.text(`${empresa.name.toUpperCase()}`, 17, 111);

      doc.setTextColor(255, 255, 255);
      doc.text(`${centro.region}, Chile`, 17, 117);

      doc.setTextColor(255, 255, 255);
      doc.setFontSize(14);

      // Responsable
      doc.text(`Servicio Realizado ${responsable}`, 17, 180);
      doc.text(`Fecha Inspección - ${unixTimeToDateString(service.date)}`, 17, 186);

      doc.setFontSize(30);
      doc.text(`INFORME`, 17, 207);
      doc.text(`PACIFIC ROV N°${service.folio}`, 17, 222);

      doc.setFontSize(12);
      doc.setFont('arial', 'normal')
      doc.setTextColor(0, 0, 0);
      doc.text(`Inspección de línea de fondeo PACIFIC ROV ${currentYear}`, 210, 267, null, 90);

      /* PAGINA Descripcion General ------------------------------------------------------------------------------------------------ */
      doc.addPage(1);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold')
      doc.setTextColor(50, 97, 139);
      doc.text(`DESCRIPCION GENERAL`, 75, 40, { align: "justify", maxWidth: maxWidth });

      var nModulos = centro.nModulos; // Puedes cambiar este valor según tus necesidades.
      var textoModulos = (nModulos === "1") ? "1 módulo" : nModulos + "módulos"

      var jaulas = centro.jaulas; // Puedes cambiar este valor según tus necesidades.
      var textoJaulas = (jaulas === 1) ? `1 jaula de ${centro.modulos}` : `${jaulas} jaulas de ${centro.modulos}`

      // Datos para la tabla
      var data = [
        ['Fecha de inicio de faena', unixTimeToDateString(service.date_inicio_faena)],
        ['Fecha de termino de faena', unixTimeToDateString(service.date_fin_faena)],
        ['Cantidad de Modulo', textoModulos],
        ['Cantidad de Jaulas / formato', textoJaulas],
        ['Servicio solicitado por departamento', 'Dpto. de Operaciones'],
        ['Tipo de inspección', 'Inspección Submarina'],
        ['Piloto ROV', responsable],
        ['ROV utilizado', service.rov],
        [{
          content: `Descripción: \n${centro.description}`,
          colSpan: 2,
          styles: {
            autoSize: 'text'
          }
        }],
      ];
      // Opciones de estilo para la tabla
      var options = {
        theme: 'striped', // Estilo striped (rayas)
        headerStyles: {
          fillColor: '#32618b', // Color de fondo del encabezado
          fontSize: 11,
          fontStyle: 'bold',
          halign: 'center',
          valign: 'middle'

        },
        alternateRowStyles: {
          fillColor: '#f0f0f0',
          fontSize: 9,
          fontStyle: 'normal',
          halign: 'center',
          valign: 'middle'
        },
        cellWidth: '50',
      };

      // Calcular el ancho de cada columna
      var columnWidth = doc.internal.pageSize.getWidth() / data[0].length;

      // Generar la tabla en el documento PDF
      doc.autoTable({
        head: [
          [
            {
              content: "                                                               DESCRIPCION GENERAL",
              colSpan: 2,
            },
          ],
        ],
        body: data
        , // Cuerpo de la tabla sin el encabezado
        columnStyles: {
          0: {
            cellWidth: 80
          },

        },
        startY: 50, // Posición vertical inicial de la tabla
        margin: { left: 25, right: 20 }, // Posición horizontal inicial de la tabla
        columnWidth: columnWidth, // Ancho fijo para todas las columnas
        styles: options // Estilos de la tabla
      });

      /* PAGINA Ubicación Geográfico  --------------------------------------------------------------------------------*/
      //Titulo
      if (centro.mapa_centro !== "") {
        doc.addPage(2)
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold')
        doc.setTextColor(50, 97, 139);
        doc.text(`   UBICACIÓN GEOGRÁFICA`, 75, 40, { align: "justify", maxWidth: maxWidth });
        // Obtén el tamaño del documento
        var anchoPagina = doc.internal.pageSize.width;     

        // Obtén el tamaño de la imagen
        var anchoImagen = 150;  // Reemplaza con el ancho real de tu imagen
        var altoImagen = 112.5;    // Reemplaza con el alto real de tu imagen

        // Calcula las coordenadas para centrar la imagen
        var x = (anchoPagina - anchoImagen) / 2;      

        // Agrega la imagen al documento en las coordenadas calculadas
        doc.addImage(centro.mapa_centro, getExtension(centro.mapa_centro), x, 60, anchoImagen, altoImagen);
      }

      /* PAGINA Metodologia de trabajo --------------------------------------------------------------------------------*/
      doc.addPage(3)
      //Titulo 
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold')
      doc.setTextColor(50, 97, 139);
      doc.text(`METODOLOGIA DE TRABAJO`, 75, 40, { align: "justify", maxWidth: maxWidth });

      doc.setFontSize(11);
      doc.setFont('arial', 'normal')
      doc.setTextColor(0, 0, 0);

      //Primer parrafo
      var text = "    Para la inspección submarina de estructuras de estructuras se efectuaron registros visuales mediante ROV de cada una de las líneas de fondeo de las estructuras identificadas del módulo de cultivo, portón alimentador y ensilaje, diferenciando los elementos principales en 3 tramos de acuerdo con lo establecido en Res. Ex. N° 1821 y sus modificaciones (Subpesca, 2020)."
      // Dividir el texto en líneas que se ajusten al ancho máximo
      var textlines = doc.splitTextToSize(text, maxWidth);
      // Agregar las líneas justificadas al documento
      doc.text(textlines, 25, 60, { align: "justify", maxWidth: maxWidth });

      //Segundo Parrafo
      var text = "    De la información entregada por el titular se le logro identificar la cantidad de líneas de fondeo para cada estructura y se asignó una nomenclatura para tipo de línea a modo de poder realizar una fácil identificación en el desarrollo"
      var textlines = doc.splitTextToSize(text, maxWidth);
      // Agregar las líneas justificadas al documento
      doc.text(textlines, 25, 85, { align: "justify", maxWidth: maxWidth });

      doc.addImage("https://storage.googleapis.com/pacificrov-7015f.appspot.com/pdf/metodologia_640x360.png", "PNG", 25, 105);

      doc.text("Donde", 25, 170);
      doc.text("- Tramo 1: Jaula - Boya ", 25, 177);
      doc.text("- Tramo 2: Boya – Anclaje principal (incluyendo red)", 25, 184);
      doc.text("- Tramo 3: Anclaje principal una retenida (anclaje auxiliar)", 25, 191);

      /* PAGINA Documentos --------------------------------------------------------------------------------*/
      if (documentos.length !== 0) {
        doc.addPage(4)

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(50, 97, 139);
        doc.text('DOCUMENTOS ANEXOS', 80, 40, { align: 'justify', maxWidth: maxWidth });

        // Datos para la tabla

        documentos.sort((a, b) => (a.folio > b.folio) ? 1 : -1);

        // Hipervinculos
        doc.setFontSize(11);
        doc.setFont('arial');
        doc.setTextColor(0, 0, 255);

        for (var i = 0; i < documentos.length; i++) {
          var documento = documentos[i];
          var x = 27; // Definir la posición x del enlace
          var y = 55 + i * 7; // Ajustar la posición y según sea necesario           
          doc.textWithLink("- " + documento.name, x, y, { url: documento.enlace })
        }
      }

      // PAGINA TABLA GENERAL INSPECCIONES--------------------------------------------------------------------------------------------------
      doc.addPage(4);

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold')
      doc.setTextColor(50, 97, 139);
      doc.text(`TABLA GENERAL INSPECCIONES`, 72, 40, { align: 'justify', maxWidth: maxWidth });

      // Datos para la tabla
      var data = [
        ['Linea', 'Comp. Fondeo', 'Anclaje', 'Suelo', 'Profundidad', 'Observación', 'Comentario', 'Estado']
      ];

      //Ordenar por folio de menor a mayor
      inspecciones.sort((a, b) => (a.folio > b.folio) ? 1 : -1);

      for (var i = 0; i < inspecciones.length; i++) {
        var inspeccion = inspecciones[i];
        var rowData = [
          inspeccion.nombre,
          agregarEspacioDespuesGuion(inspeccion.composicion),
          inspeccion.anclaje,
          inspeccion.suelo,
          inspeccion.profundidad,
          inspeccion.observacion_leve ? inspeccion.observacion_leve : inspeccion.observacion,
          inspeccion.comentario,
          inspeccion.status
        ];
        data.push(rowData);
      }

      // Función para agregar la tabla
      const addTableLineas = () => {
        // Opciones de estilo para la tabla
        var options = {
          theme: 'striped', // Estilo striped (rayas)
          headerStyles: {
            fillColor: '#32618b' // Color de fondo del encabezado
          },
          alternateRowStyles: {
            fillColor: '#f0f0f0' // Color de fondo de las filas alternas
          },
          cellWidth: '50',
        };

        // Calcular el ancho de cada columna
        var columnWidth = doc.internal.pageSize.getWidth() / data[0].length;

        // Generar la tabla en el documento PDF
        doc.autoTable({
          head: [data[0]], // Encabezado de la tabla
          body: data.slice(1), // Cuerpo de la tabla sin el encabezado
          didParseCell: function (data) {
            if (data.column.index === 7) { // Comprueba si la celda está en la columna "Estado"
              if (data.cell.raw === "Sin Observación") {
                data.cell.styles.textColor = [40, 167, 69]; // Verde
              } else if (data.cell.raw === "Observación Leve") {
                data.cell.styles.textColor = [255, 193, 7]; // Cambia el color de fondo a gris (#f0f0f0)
              } else if (data.cell.raw === "Observación Crítica") {
                data.cell.styles.textColor = [220, 53, 69]; // Cambia el color de fondo a gris (#f0f0f0)
              } else if (data.cell.raw === "Observación Levantada") {
                data.cell.styles.textColor = [0, 123, 255]; // Cambia el color de fondo a gris (#f0f0f0)
              }
            }
          },
          columnStyles: {
            0: { cellWidth: 15 },
            1: { cellWidth: 15 },
            2: { cellWidth: 30 },
          },
          fontSize: 8,
          startY: 45, // Posición vertical inicial de la tabla
          margin: { left: 25, right: 20, bottom: 30, top: 45 }, // Posición horizontal inicial de la tabla
          columnWidth: columnWidth, // Ancho fijo para todas las columnas
          styles: options, // Estilos de la tabla
          rowPageBreak: 'avoid'
        });
      }

      // Llamar a la función para agregar la tabla
      addTableLineas();

      // PAGINA TABLA GENERAL INSPECCIONES OBSERVADAS--------------------------------------------------------------------------------------------------
      doc.addPage(4);

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold')
      doc.setTextColor(50, 97, 139);
      doc.text(`TABLA RESUMEN DE LÍNEAS DE FONDEOS OBSERVADAS `, 50, 40, { align: 'justify', maxWidth: maxWidth });

      // Datos para la tabla
      var data = [
        ['Linea', 'Observación', 'Estado']
      ];

      //Filtrar inspecciones donde status sea difernte a "Sin Observación"
      const inspeccionesFiltradas = inspecciones.filter((inspeccion) => {
        return inspeccion.observacion_leve !== '' || inspeccion.observacion !== '';
      });

      inspeccionesFiltradas.sort((a, b) => (a.folio > b.folio) ? 1 : -1);

      for (var i = 0; i < inspeccionesFiltradas.length; i++) {
        var inspeccion = inspeccionesFiltradas[i];
        var rowData = [
          inspeccion.nombre,
          inspeccion.observacion_leve ? inspeccion.observacion_leve : inspeccion.observacion,
          inspeccion.status
        ];
        data.push(rowData);
      }

      // Función para agregar la tabla
      const addTableLineas1 = () => {
        // Opciones de estilo para la tabla
        var options = {
          theme: 'striped', // Estilo striped (rayas)
          headerStyles: {
            fillColor: '#32618b' // Color de fondo del encabezado
          },
          alternateRowStyles: {
            fillColor: '#f0f0f0' // Color de fondo de las filas alternas
          },
          cellWidth: '50',
        };

        // Calcular el ancho de cada columna
        var columnWidth = doc.internal.pageSize.getWidth() / data[0].length;

        // Generar la tabla en el documento PDF
        doc.autoTable({
          head: [data[0]], // Encabezado de la tabla
          body: data.slice(1), // Cuerpo de la tabla sin el encabezado
          didParseCell: function (data) {
            if (data.column.index === 2) { // Comprueba si la celda está en la columna "Estado"
              if (data.cell.raw === "Sin Observación") {
                data.cell.styles.textColor = [40, 167, 69]; // Verde
              } else if (data.cell.raw === "Observación Leve") {
                data.cell.styles.textColor = [255, 193, 7]; // Cambia el color de fondo a gris (#f0f0f0)
              } else if (data.cell.raw === "Observación Crítica") {
                data.cell.styles.textColor = [220, 53, 69]; // Cambia el color de fondo a gris (#f0f0f0)
              } else if (data.cell.raw === "Observación Levantada") {
                data.cell.styles.textColor = [0, 123, 255]; // Cambia el color de fondo a gris (#f0f0f0)
              }
            }
          },
          columnStyles: {
            0: { cellWidth: 15 },
          },
          fontSize: 8,
          startY: 45, // Posición vertical inicial de la tabla
          margin: { left: 25, right: 20, bottom: 30, top: 45 }, // Posición horizontal inicial de la tabla
          columnWidth: columnWidth, // Ancho fijo para todas las columnas
          styles: options, // Estilos de la tabla
          rowPageBreak: 'avoid'
        });
      }

      // Llamar a la función para agregar la tabla
      addTableLineas1();
      /* INSPECCIONES PHOTOS --------------------------------------------------------------------------------------------------------------*/

      let page = 5;
      let inspecciones_data = [];

      inspecciones.forEach((inspeccion) => {
        let imagesWithComments = [];
        for (let i = 1; i <= 10; i++) {
          if (inspeccion[`photo${i}`] !== "") {
            const url = inspeccion[`photo${i}`];
            const comment = inspeccion[`ob_photo${i}`];
            const tempImage = new Image();
            tempImage.src = url;
            imagesWithComments.push({ "url": url, "comment": comment, "height": tempImage.height, "width": tempImage.width });
          }
        }
        //validar de que imagesWithComments no este vacio
        if (imagesWithComments.length !== 0) {
          inspecciones_data.push({
            "linea": inspeccion.nombre,
            "folio": inspeccion.folio,
            "imagesWithComments": imagesWithComments
          });
        }
      });

      //Ordenar por folio de menor a mayor
      inspecciones_data.sort((a, b) => (a.folio > b.folio) ? 1 : -1);

      for (let i = 0; i < inspecciones_data.length; i++) {
        doc.addPage(page + i);
        if (i === 0) {
          doc.setFontSize(12);
          doc.setFont('helvetica', 'bold')
          doc.setTextColor(50, 97, 139);
          doc.text(`REGISTRO FOTOGRÁFICO`, 80, 35, { align: 'justify', maxWidth: maxWidth });
          doc.setFontSize(11);
          doc.text(`Linea: ${inspecciones_data[i].linea}`, 25, 50);
          // Calcular el ancho disponible para las imágenes
          const availableWidth = doc.internal.pageSize.getWidth() - 20;
          console.log(availableWidth,) // Restar los márgenes izquierdo y derecho

          // Calcular el tamaño de las imágenes proporcionalmente al ancho disponible
          const imageWidth = 62.56

          // Calcular la cantidad de filas necesarias
          const rowCount = Math.ceil(inspecciones_data[i].imagesWithComments.length / 2);

          // Calcular el espacio vertical disponible para las imágenes
          const availableHeight = doc.internal.pageSize.getHeight() - 20; // Restar los márgenes superior e inferior

          const totalHeight = rowCount * imageWidth + (rowCount - 1) * 10; // Sumar alturas de imágenes y espacios entre filas
          const verticalMargin = (availableHeight - totalHeight) / 2; // Calcular margen vertical

          // Iterar sobre las imágenes y comentarios y agregarlos al documento PDF
          let currentColumn = 33;
          let imageHeight = 0;
          let currentRow = verticalMargin + 10;
          let maxImageHeight = 0; // Variable para almacenar la altura máxima de las imágenes en una fila
          // Sumar margen vertical y margen superior
          currentRow = 55;
          for (let j = 0; j < inspecciones_data[i].imagesWithComments.length; j++) {
            const { url, comment, height, width } = inspecciones_data[i].imagesWithComments[j];

            imageHeight = (imageWidth * 300) / 600;

            // Actualizar la altura máxima si se supera el valor actual
            if (imageHeight > maxImageHeight) {
              maxImageHeight = imageHeight;
            }

            // Cargar la imagen como un objeto Image en el documento PDF

            //try por si la foto no existe
            try {
              doc.addImage(url, getExtension(url), currentColumn, currentRow, imageWidth, 31.2, null, 'FAST', imageQuality);
            } catch (error) {
              console.log("error foto", error)
              alert("Error al cargar la foto" + url, error)
            }

            // Agregar el comentario debajo de la imagen
            doc.setFontSize(8);
            doc.setFont('arial', 'normal')
            doc.setTextColor(0, 0, 0);

            const commentLines = doc.splitTextToSize(comment, imageWidth);
            doc.text(currentColumn, currentRow + 35.1, commentLines);

            // Actualizar la posición actual en función de la columna y la fila actual
            currentColumn += imageWidth + 15; // Agregar espacio horizontal entre columnas
            if (currentColumn + imageWidth > doc.internal.pageSize.getWidth()) {
              // Cambiar a la siguiente fila si se ha alcanzado el final de la columna actual
              if ((j + 1) % 2 === 0) {
                // Cambiar a la siguiente fila utilizando la altura máxima de las imágenes en la fila actual
                currentColumn = 33;
                currentRow += maxImageHeight + 8; // Agregar espacio vertical entre filas
                maxImageHeight = 0; // Reiniciar la altura máxima para la nueva fila
              } // Agregar espacio vertical entre filas
            }

            // Verificar si se ha alcanzado el límite de 4 fotos en la fila actual

          }
        }

        else {

          doc.setFont('helvetica', 'bold')
          doc.setTextColor(50, 97, 139);
          doc.setFontSize(11);

          doc.text(`Linea: ${inspecciones_data[i].linea}`, 25, 35);

          // Calcular el tamaño de las imágenes proporcionalmente al ancho disponible
          const imageWidth = 62.56

          // Calcular la cantidad de filas necesarias
          const rowCount = Math.ceil(inspecciones_data[i].imagesWithComments.length / 2);

          // Calcular el espacio vertical disponible para las imágenes
          const availableHeight = doc.internal.pageSize.getHeight() - 20; // Restar los márgenes superior e inferior        
          const totalHeight = rowCount * imageWidth + (rowCount - 1) * 10; // Sumar alturas de imágenes y espacios entre filas
          const verticalMargin = (availableHeight - totalHeight) / 2; // Calcular margen vertical

          // Iterar sobre las imágenes y comentarios y agregarlos al documento PDF
          let currentColumn = 33;
          let currentRow = verticalMargin + 10;
          let imageHeight = 0;
          let maxImageHeight = 0; // Variable para almacenar la altura máxima de las imágenes en una fila
          // Sumar margen vertical y margen superior
          currentRow = 55;
          for (let j = 0; j < inspecciones_data[i].imagesWithComments.length; j++) {
            const { url, comment, height, width } = inspecciones_data[i].imagesWithComments[j];

            // Crear una imagen en memoria para calcular las dimensiones proporcionales   
            imageHeight = (imageWidth * 300) / 600;

            // Actualizar la altura máxima si se supera el valor actual
            if (imageHeight > maxImageHeight) {
              maxImageHeight = imageHeight;
            }

            try {
              doc.addImage(url, getExtension(url), currentColumn, currentRow, imageWidth, 31.2, null, 'FAST', imageQuality);
            } catch (error) {
              console.log("error foto", error)
              alert("Error al cargar la foto" + url, error)
            }

            // Agregar el comentario debajo de la imagen
            doc.setFontSize(8);
            doc.setFont('arial', 'normal')
            doc.setTextColor(0, 0, 0);
            const commentLines = doc.splitTextToSize(comment, imageWidth);
            doc.text(currentColumn, currentRow + 35.1, commentLines);

            // Actualizar la posición actual en función de la columna y la fila actual
            currentColumn += imageWidth + 5; // Agregar espacio horizontal entre columnas
            if (currentColumn + imageWidth > doc.internal.pageSize.getWidth()) {
              // Cambiar a la siguiente fila si se ha alcanzado el final de la columna actual
              if ((j + 1) % 2 === 0) {
                // Cambiar a la siguiente fila utilizando la altura máxima de las imágenes en la fila actual
                currentColumn = 33;
                currentRow += maxImageHeight + 8; // Agregar espacio vertical entre filas
                maxImageHeight = 0; // Reiniciar la altura máxima para la nueva fila
              } // Agregar espacio vertical entre filas
            }

            // Verificar si se ha alcanzado el límite de 4 fotos en la fila actual

          }
        }
      }

      // CONCLUSIONES --------------------------------------------------------------------------------------------------------------

      doc.addPage(page);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold')
      doc.setTextColor(50, 97, 139);
      doc.text(`CONCLUSIONES`, 80, 40, { align: 'justify', maxWidth: maxWidth });

      doc.setFontSize(11);
      doc.setFont('arial', 'normal')
      doc.setTextColor(0, 0, 0);
      if (service.comentario === undefined || service.comentario === "") {
        doc.text(`Sin conclusiones`, 25, 50, { maxWidth: 170, align: "justify" })
      } else {
        var texto = service.comentario;
        var maxWidth = 170;
        var lineHeight = 5;  // Ajusta según tu necesidad

        // Divide el texto en líneas
        var lines = doc.splitTextToSize(texto, maxWidth);

        // Ajusta la última línea a la izquierda
        var lastLine = lines.pop();
        doc.text(lines, 25, 50, { align: "justify", maxWidth: maxWidth, lineHeight: lineHeight });
        doc.text(lastLine, 25, 50 + (lines.length * lineHeight));  
      }

      // FOOTER
      addFooter();

      /* Print PDF */

      // Guardar el documento PDF en Storage

      // Obtener la cadena Base64 del documento PDF    
      const pdfB64 = doc.output('datauristring');

      // Convertir la cadena Base64 a un Blob
      const pdfBlob = await fetch(pdfB64).then(res => res.blob());

      // Guardar el documento PDF en Storage
      const storage = getStorage();
      const storageRef = ref(storage, `servicios/${idServicio}_informe.pdf`);
      await uploadBytes(storageRef, pdfBlob);     

      // Actualizar la referencia al informe en la base de datos
      const servicioRef = docSer(db, 'servicios', idServicio);
      await updateDoc(servicioRef, {
        informe: `https://storage.googleapis.com/pacificrov-7015f.appspot.com/servicios/${idServicio}_informe.pdf`,
        date_update: new Date()
      });   

      console.timeEnd("servicePDF");

          //recargar la pagina
          window.location.reload();
        }

    generatePDf();

    return false;
  } catch (error) {
    console.log("error", error)
    alert("Error al generar el informe" + error, error)
  }

}

export default servicePDF;

