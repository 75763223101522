/* React */
import React, { useState, useEffect } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css'

import { Card, Button,  Form, Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link, useNavigate, useParams} from 'react-router-dom';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Authentication */
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

/*Firestore */
import { collection, doc, addDoc,  getDocs} from "firebase/firestore";
import {db} from '../firebase';

const UsuariosCrear = () =>{

  const { id } = useParams();

  const navigate = useNavigate();

  const [empresas, setEmpresas] = useState([]); 

  const getEmpresas = async () => {
    try{
      const querySnapshot = await getDocs(collection(db, "empresa"));
      const empresas = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setEmpresas(empresas);
    }catch(error){
      console.log("Error al cargar empresas",error);
    }
  };

  useEffect(() => {
    getEmpresas();
  }, []);

  /* Aunteticacion */
  const auth = getAuth();

  const [users , setUsers] = useState({
    name: '',
    email: '',   
    role: '',
  });

  const handleChange = async (e) =>{
    const { name, value } = e.target;       
    setUsers((prevState) => ({
        ...prevState,
        [name]: value,
    })); 
  };

  const generatePassword = (length) => {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*()-_';
  
    let password = '';
  
    const allCharacters = uppercaseLetters + lowercaseLetters + numbers + symbols;
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allCharacters.length);
      password += allCharacters[randomIndex];
    }
  
    return password;
  }     

  /* Recuperar contraseña */
  const resetPassword = async (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Correo de recuperación de contraseña enviado a " + email)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("Error al enviar el correo de recuperación de contraseña")
        console.log(errorCode, errorMessage)
      });
  };

  const handleSubmit = async () => {      
    try {

      if(!users.name || !users.email || !users.role ){
        alert("Rellene nos campos obligatorios");
        return
      }  

      const { user } = await createUserWithEmailAndPassword(auth, users.email, generatePassword(12));
      // Crear el usuario con el correo electrónico y una contraseña generada
     
      const data = {
        name: users.name,
        email: users.email,
        rol: users.role,      
        status: "Activo",          
        uid: user.uid, 
      }

      if(users.role === 'Cliente'){      
        data.empresa = doc(db, "empresa", users.empresa);
      }

      const docRef = await addDoc(collection(db, "usuarios"), data);    
      resetPassword(users.email); // Enviar correo de recuperación de contraseña 
      
      alert(`${users.name} ha sido gurdado con éxito`);
      navigate(`/usuarios/${docRef.id}`);
    } catch (error) {
      // Manejar el error en caso de que ocurra
      console.error("Error al crear el usuario:", error);
      if(error == 'FirebaseError: Firebase: Error (auth/email-already-in-use).'){
        alert(`Usuario no creado. El email ${users.email} ya se encuentra en uso`);
      }else{
        alert(`Usuario no creado. ${error}`);
      }   
      
    }
  };

  console.log(users);

  return (
      <>
        <Navbar/>
          <main id="main" className="main ">
          <div className="pagetitle">
            <h1>Crear Usuarios</h1>
            <Breadcrumb>
              <BreadcrumbItem >
                <Link to="/" style={{ textDecoration: 'none' }}>
                  Inicio
                </Link>                
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/usuarios">
                  Usuarios
                </Link>  
              </BreadcrumbItem>
              <BreadcrumbItem active to="/usuarios-crear">Crear Usuarios</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <section className="section">
          <div className="row" style={{display: 'flex', justifyContent: 'center', paddingRight: '20px'}}>
              <div class="col-12">
              <Card className="card" style={{ maxWidth:"100%"}}>
              <div className="card-body">
              <h5 className="card-title">Usuario</h5>

              {/* Vertical Form */}
              <Form >
                <Form.Group className="mb-3" >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" name='name' value={users.name} onChange={handleChange} placeholder="Ingresa el nombre" />
                </Form.Group>

                <Form.Group className="mb-3" >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name='email' value={users.email} onChange={handleChange}  placeholder="Ingresa el email" />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>Rol</Form.Label>
                  <Form.Control as="select" name='role' value={users.role} onChange={handleChange} required  id="status">
                      <option value="">Selecciona una opción</option>
                      <option>Cliente</option>
                      <option>Colaborador</option>
                      <option>Administrador</option>
                      <option>Piloto</option>
                    </Form.Control>
                </Form.Group>
                {users.role === 'Cliente' && ( // Condición para mostrar el campo empresa
                  <Form.Group className="mb-3" >
                  <Form.Label>Empresa</Form.Label>
                  <Form.Control as="select" name='empresa' value={users.empresa} onChange={handleChange} required  id="status">
                      <option value="">Selecciona una opción</option>
                      {empresas.map((empresa) => (
                        <option key={empresa.id} value={empresa.name}>{empresa.name}</option>
                      ))}                     
                    </Form.Control>
                </Form.Group>
                )}
                <div className="text-center" style={{ display:"flex", gap:"5px"}}>
                  <Button variant="primary" onClick={handleSubmit}>
                    Guardar
                  </Button>               
                </div>
              </Form>
              {/* Vertical Form */}
            </div>
          </Card> 
          </div>         
          </div>    
        </section>           
        </main>  
        <Footer/>             
      </>
  );
}

export default UsuariosCrear;
