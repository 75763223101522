/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

/* React Router Rom */
import { useNavigate, Link } from 'react-router-dom';

/* Firebase Auth */
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

//FireStore
import { collection, where, query, getDocs } from "firebase/firestore";
import { db } from '../firebase';

import Context from "../context";

const Login = () => {

    const navigate = useNavigate();
  
    const [state, setState] = useState({
        username: '',
        password: ''
    });

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    //Variables globales de usuario
    const { user, setUser } = useContext(Context);

    useEffect(() => {

        if (user.length > 0 || user === null) {
            navigate('/');
        }
    }, [user]);


    const getUserIdByEmail = async (email) => {
        const querySnapshot = await getDocs(query(collection(db, 'usuarios'), where('email', '==', email)));
        const userDoc = querySnapshot.docs[0];

        if (userDoc.exists()) {
            const userData = userDoc.data();
            const userId = userDoc.id;

            return { uid: userData.uid, rol: userData.rol, name: userData.name, id: userId };
        } else {
            return ''; // o cualquier valor que desees en caso de que el usuario no exista
        }
    };

    const auth = getAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        signInWithEmailAndPassword(auth, state.username.trim(), state.password)
            .then(async (userCredential) => {
                // Signed in              
                const userFirebase = userCredential.user;
                const name = await getUserIdByEmail(userFirebase.email);
                const currentUser = [...user];
                currentUser.push(name);
                setUser(currentUser);
                console.log(user)
                alert(`Inicio de sesión exitoso! ${user}`);
                navigate("/")
            })
            .catch((error) => {
                alert("Contraseña o usuario incorrecto")
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });
    };

    return (
        <div>
            <main>
                <div className="container">
                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                    <div className="d-flex justify-content-center py-4">
                                        <a href="index.html" className="logo d-flex align-items-center w-auto">
                                            <img src={process.env.PUBLIC_URL + '/img/logo.jpg'} alt="" />
                                            <span className="d-none d-lg-block">Pacific Rov</span>
                                        </a>
                                    </div>{/* End Logo */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">Inicio de sesión</h5>
                                                <p className="text-center small">Ingresa tu correo y contraseña para ingresar</p>
                                            </div>
                                            <form className="row g-3 needs-validation" noValidate>
                                                <div className="col-12">
                                                    <label htmlFor="yourUsername" className="form-label">Email</label>
                                                    <input type="email"
                                                        name="username"
                                                        className="form-control"
                                                        id="yourUsername"
                                                        onChange={handleChange}
                                                        required />
                                                    <div className="invalid-feedback">Ingresa tu correo</div>
                                                </div>
                                                <div className="col-12">
                                                    <label htmlFor="yourPassword" className="form-label">Contraseña</label>
                                                    <input type="password"
                                                        name="password"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        id="yourPassword"
                                                        required />
                                                    <div className="invalid-feedback">Ingresa tu contraseña</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="remember" defaultValue="true" id="rememberMe" />
                                                        <label className="form-check-label" htmlFor="rememberMe">Recuérdame</label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <Link to="/reset" >Olvidé mi contraseña</Link>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" onClick={handleSubmit}>Login</button>
                                                </div>
                                                {/*<div className="col-12">
                                <p className="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                                </div>*/}
                                            </form>
                                        </div>
                                    </div>
                                    <div className="credits">
                                        Diseñado por <a href="https://solveraustral.com/">Solver Austral</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>{/* End #main */}
            <a  className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
        </div>
    );
}

export default Login; 