const regionOptions = [
    'XV Región de Arica y Parinacota',
    'I Región de Tarapacá',
    'II Región de Antofagasta',
    'III Región de Atacama',
    'IV Región de Coquimbo',
    'V Región de Valparaíso',
    'RM Región Metropolitana',
    "VI Región O'Higgins",
    'VII Región del Maule',
    'XVI Región de Ñuble',
    'VIII Región del Biobío',
    'IX Región de La Araucanía',
    'XIV Región de Los Ríos',
    'X Región de Los Lagos',
    'XI Región de Aysén',
    'XII Región de Magallanes',
];

export default regionOptions;  