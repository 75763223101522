/* React */
import React, { useState, useEffect } from "react";

/* CSS */
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/boxicons/css/boxicons.min.css";
import "../assets/vendor/quill/quill.snow.css";
import "../assets/vendor/quill/quill.bubble.css";
import "../assets/vendor/remixicon/remixicon.css";
import "../assets/vendor/simple-datatables/style.css";
import "../assets/css/style.css";

import { Card, Button, Form } from "react-bootstrap";

/* React Router  */
import { useNavigate, useParams, Link } from "react-router-dom";

/* Components */
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

/*Firestore */
import { collection, doc, getDoc, query, where, getDocs, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";


const ServicioEditar = () => {

    const { id, idCentro, idServicio } = useParams();
    const [servicio, setServicio] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [centro, setCentro] = useState([]);
    const [empresa, setEmpresa] = useState([]);
    const [responsable, setResponsable] = useState([]);
    const [revisa, setRevisa] = useState([]);
    const [autoriza, setAutoriza] = useState([]);

    const navigate = useNavigate();

    const unixTimeToDateString = (unixTime) => {
        try {
            const date = new Date(unixTime.seconds * 1000);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            // Agrega un cero inicial si es necesario
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;

            return `${year}-${formattedMonth}-${formattedDay}`;
        }
        catch (error) {
            return '';
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const servicioRef = doc(db, "servicios", idServicio);
            const centroRef = doc(db, "centro", idCentro);
    
            const [servicioSnapshot, centroSnapshot] = await Promise.all([
                getDoc(servicioRef),
                getDoc(centroRef)
            ]);
    
            if (servicioSnapshot.exists()) {
                const servicioData = servicioSnapshot.data();
                const autorizaId = servicioData.autoriza ? servicioData.autoriza.id || '' : '';
                const revisaId = servicioData.revisa ? servicioData.revisa.id || '' : '';
                const responsableId = servicioData.responsable ? servicioData.responsable.id || '' : '';
                setServicio({ id: servicioSnapshot.id, 
                            ...servicioData,
                            autoriza: autorizaId,
                            revisa: revisaId,
                            responsable: responsableId,});
    
            }
    
            if (centroSnapshot.exists()) {
                const centroData = centroSnapshot.data();
                setCentro(centroData);
    
                const empresaRef = doc(db, "empresa", centroData.empresa.id);
                const empresaSnapshot = await getDoc(empresaRef);
    
                if (empresaSnapshot.exists()) {
                    const empresaData = empresaSnapshot.data();
                    setEmpresa(empresaData);
                }
            }
    
            setIsLoading(false);
        };
    
        fetchData();
    }, [idServicio, idCentro ]);

    console.log("servicio", servicio)
    

    //const { register, formState: { errors } } = useForm();
    //Servicios
    const [usersAdmins, setusersAdmins] = useState([]);
    const [usersColabs, setusersColab] = useState([]);

    let usersResp = []

    useEffect(() => {
        const usuariosAdminsRef = collection(db, "usuarios");
        const fetchUsuarios = async () => {
            const usuariosAdminsQuery = query(usuariosAdminsRef, where("rol", "==", "Administrador"));
            try {
                const adminsSnapshot = await getDocs(usuariosAdminsQuery);
                const adminsList = adminsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setusersAdmins(adminsList);
            }
            catch (error) {
                console.log('Error al obtener los usuarios administradores:', error);
            }
        };

        const fetchUsuariosColabs = async () => {
            const usuariosColabQuery = query(usuariosAdminsRef, where("rol", "==", "Colaborador"));
            try {
                const colabSnapshot = await getDocs(usuariosColabQuery);
                const colabList = colabSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setusersColab(colabList);
            }
            catch (error) {
                console.log('Error al obtener los usuarios colaboradores:', error);
            }
        };

        fetchUsuarios();
        fetchUsuariosColabs();
    }, []);

    usersResp = usersAdmins.concat(usersColabs)


    /* Forms */
    const [formData, setFormData] = useState({
        autoriza: '',
        centro: '',
        comentario: '',
        date: '',
        date_create: '',
        date_update: '',
        date_inicio_faena: '',
        date_fin_faena: '',
        etapa: '',
        folio: '',
        informe: '',
        responsable: '',
        revisa: '',
        tipo: '',
        rov: '',
    })

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    /* Submit */
    const handleSubmit = async () => {

        if (!formData.date || 
            !formData.tipo || 
            !formData.etapa || 
            !formData.autoriza || 
            !formData.revisa || 
            !formData.responsable ||
            !formData.date_inicio_faena ||
            !formData.date_fin_faena) {
            alert("Debe completar todos los campos obligatorios");
            return;
        }

        const msjsError = [
            "El usuario que revisa no puede ser el mismo usuario que autoriza",
            "El usuario que autoriza no puede ser el mismo usuario Piloto Rov",
            "El usuario Piloto Rov no puede ser el mismo usuario revisa",
        ]

        if (formData.autoriza == formData.revisa) {
            alert(msjsError[0]);
            return;
        } else if (formData.autoriza == formData.responsable) {
            alert(msjsError[1]);
            return;
        } else if (formData.revisa == formData.responsable) {
            alert(msjsError[2]);
            return;
        }

        const date = new Date(`${formData.date}T15:30:00.000-03:00`);

        const servicioRef = doc(db, "servicios", idServicio)
        updateDoc(servicioRef, {
            date: date,
            date_update: serverTimestamp(),
            tipo: formData.tipo,
            etapa: formData.etapa,
            autoriza: doc(db, "usuarios", formData.autoriza),
            revisa: doc(db, "usuarios", formData.revisa),
            responsable: doc(db, "usuarios", formData.responsable),
            comentario: formData.comentario,
            date_inicio_faena: new Date(`${formData.date_inicio_faena}T15:30:00.000-03:00`),
            date_fin_faena: new Date(`${formData.date_fin_faena}T15:30:00.000-03:00`),
            rov: formData.rov,
        });

        alert(`El servicio ${servicio.folio} ha sido actualizado correctamente.`);

        navigate(`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}`)


        try {
            console.log("handleSubmit function called.");

        } catch (error) {
            alert(`Error al agregar nuevo cliente o servicio: ${error}`);
        }  
    };

    useEffect(() => {
        const fetchDataCentro = async () => {
            const centroRef = doc(db, "centro", idCentro)
            const centroSnapshot = await getDoc(centroRef)
            if (!centroSnapshot.exists()) return;
            const centroData = centroSnapshot.data()
            setCentro(centroData)
            const empresaRef = doc(db, "empresa", centroData.empresa.id)
            const empresaSnapshot = await getDoc(empresaRef)
            if (!empresaSnapshot.exists()) return;
            const empresaData = empresaSnapshot.data()
            setEmpresa(empresaData)

            setIsLoading(false);
        }
        fetchDataCentro();
    }, [])

    useEffect(() => {
        if (servicio) {
            setFormData({
                date : unixTimeToDateString(servicio.date),
                tipo : servicio.tipo,
                etapa : servicio.etapa,
                autoriza : servicio.autoriza,
                revisa : servicio.revisa,
                responsable : servicio.responsable,
                comentario : servicio.comentario,
                date_inicio_faena: unixTimeToDateString(servicio.date_inicio_faena),
                date_fin_faena: unixTimeToDateString(servicio.date_fin_faena),
                rov : servicio.rov,
            });
        }
        setIsLoading(false);
    }, [servicio]);

    if (isLoading) {
        return (
            <div>Cargando...</div>
        )
    }
    
    console.log(formData)
    console.log("autoriza", formData.autoriza)
    console.log("revisa", formData.revisa)
    console.log("responsable", formData.responsable)

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Editar Servicio</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Inicio</Link>
                            </li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}`}>{empresa.name}</Link></li>
                            <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}`}>{centro.name}</Link></li>
                            <li className="breadcrumb-item active">
                                <Link to= {`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}`}>
                                    {`Servicio N°${servicio.folio}`}
                                </Link>
                            </li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Servicio Folio: {servicio.folio}</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="date" value={formData.date} onChange={handleChange} name="date" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha Inicio Faena<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="date" value={formData.date_inicio_faena} onChange={handleChange} name="date_inicio_faena" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha Término Faena<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="date" value={formData.date_fin_faena} onChange={handleChange} name="date_fin_faena" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tipo de Servicio</Form.Label>
                                            <Form.Control as="select" value={formData.tipo} name="tipo" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                <option value="Certificación Anual">Certificación Anual</option>
                                                <option value="Verificación Semestral">Verificación Semestral</option>                                              
                                                <option value="Levantamiento observaciones">Levantamiento observaciones</option>
                                                <option value="Certificación de inicio de ciclo">Certificación de inicio de ciclo</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Etapa</Form.Label>
                                            <Form.Control as="select" value={formData.etapa} name="etapa" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                <option value="Inspección">Inspección</option>
                                                <option value="Cargando Datos">Cargando Datos</option>
                                                <option value="Completado">Completado</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Autoriza<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.autoriza} name="autoriza" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                {usersAdmins.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Revisa<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.revisa} name="revisa" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                {usersAdmins.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Piloto Rov<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.responsable} name="responsable" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                {usersResp.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Rov utilizado<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" name="rov" value={formData.rov} onChange={handleChange} placeholder="Ingresa el nombre" />
                                        </Form.Group> 
                                        <Form.Group className="mb-3">
                                            <Form.Label>Comentario</Form.Label>
                                            <Form.Control as="textarea" value={formData.comentario} name="comentario" onChange={handleChange}>
                                            </Form.Control>
                                        </Form.Group>
                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>                                       
                                        </div>
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <Footer />
                </section>
            </main>
        </>
    )

};

export default ServicioEditar;