/* React */
import React from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

import { Accordion } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const Sidebar = () => {

  return (
    <>
      {/* ======= Sidebar ======= */}
      <aside id="sidebar" className="sidebar">
        <Accordion className="sidebar-nav" id="sidebar-nav">
          {/* Usuario */}
          <Accordion.Item className='nav-item' eventKey="Empresas">
            <Accordion.Header className="nav-link ">
              <i class="bi bi-file-person"></i><span> Usuarios</span>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: '0.85rem' }}>
              <ul>
                <li>
                  <i className="bi bi-circle" style={{ fontSize: '0.5rem' }}></i>
                  <Link to="/usuarios" style={{ textDecoration: 'none' }}>
                    &nbsp; Ver Usuarios
                  </Link>
                </li>
                <li>
                  <i className="bi bi-circle" style={{ fontSize: '0.5rem' }}></i>
                  <Link to="/usuarios-crear" style={{ textDecoration: 'none' }}>
                    &nbsp;Crear Usuario
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {/* Usuario */}
          {/* Empresas */}
          <Accordion.Item className='nav-item' eventKey="Empresas">
            <Accordion.Header className="nav-link ">
              <i class="bi bi-building-fill"></i><span> Empresas</span>
            </Accordion.Header>
            <Accordion.Body style={{ fontSize: '0.85rem' }}>
              <ul >
                <li >
                  <i className="bi bi-circle" style={{ fontSize: '0.5rem' }}></i>
                  <Link to="/empresas-crear" style={{ textDecoration: 'none' }}>
                    &nbsp;Crear Empresa
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {/* Empresas */}
          {/* Centros */}
          <Accordion.Item className='nav-item' eventKey="centros">
            <Accordion.Header className="nav-link ">
              <Link to='/centros'>
                <i className="bi bi-grid-3x2"></i><span> Centros</span>
              </Link>
            </Accordion.Header>
          </Accordion.Item>
          {/* End Centros */}
          {/* Centros */}
          <Accordion.Item className='nav-item mb-1' eventKey="servicios">
            <Accordion.Header className="nav-link ">
              <Link to='/servicios'>
                <i className="bi bi-file-earmark-check-fill"></i><span> Servicios</span>
              </Link>
            </Accordion.Header>
          </Accordion.Item>
          {/* End Centros */}
           {/* Ayuda */}
          <Accordion.Item className='nav-item mb-1' eventKey="servicios">
            <Accordion.Header className="nav-link ">
              <Link to='https://pacificrov.tawk.help/'>
                <i className="bi bi-question-circle-fill"></i><span> Ayuda</span>
              </Link>
            </Accordion.Header>
          </Accordion.Item>
          {/* End Centros */}
          {/* End Blank Page Nav */}
        </Accordion>        
      </aside>
      {/* End Sidebar*/}

    </>
  );
}

export default Sidebar;
