/* React */
import React, { useState, useEffect, useContext } from 'react';

/* CSS */
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/quill/quill.snow.css';
import '../assets/vendor/quill/quill.bubble.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/simple-datatables/style.css';
import '../assets/css/style.css';

/* Bootstrap */
import { Button } from 'react-bootstrap';

/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Importar Navegate */
import { Link, useNavigate, useParams } from 'react-router-dom';

/*Firestore */
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';

/*Slider */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

/*Context */
import Context from "../context";

const LInspeccionesId = () => {

  const { user } = useContext(Context);

  const { id, idCentro, idServicio, idInspeccion } = useParams();

  const navigate = useNavigate();

  const [inspeccion, setInspeccion] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [empresa, setEmpresa] = useState([]);
  const [centro, setCentro] = useState([]);
  const [servicio, setServicio] = useState([]);

  useEffect(() => {
    // Función para manejar la finalización de las consultas
    let completedQueries = 0;

    const handleQueryCompletion = () => {
      completedQueries++;
      if (completedQueries === 4) {
        setIsLoading(false);
      }
    };

    // Consulta para obtener los datos de la empresa
    const getEmpresa = async () => {
      try {
        const empresaRef = doc(db, "empresa", id);
        const empresaSnap = await getDoc(empresaRef);

        // Verificar si el documento existe
        if (empresaSnap.exists()) {
          const empresaData = empresaSnap.data();
          setEmpresa(empresaData);
        } else {
          console.log("No existe la empresa");
        }

        handleQueryCompletion();
      } catch (e) {
        console.log("Error al obtener la empresa:", e);
        handleQueryCompletion();
      }
    };

    // Consulta para obtener los datos del centro
    const getCentro = async () => {
      try {
        const centroRef = doc(db, "centro", idCentro);
        const centroSnap = await getDoc(centroRef);

        if (centroSnap.exists()) {
          const centroData = centroSnap.data();
          setCentro(centroData);
        }

        handleQueryCompletion();
      } catch (e) {
        console.log("Error al obtener el centro:", e);
        handleQueryCompletion();
      }
    };

    // Consulta para obtener los datos del servicio
    const getServicio = async () => {
      try {
        const servicioRef = doc(db, "servicios", idServicio);
        const servicioSnap = await getDoc(servicioRef);

        if (servicioSnap.exists()) {
          const servicioData = servicioSnap.data();
          setServicio(servicioData);
        }

        handleQueryCompletion();
      } catch (e) {
        console.log("Error al obtener el servicio:", e);
        handleQueryCompletion();
      }
    };

    // Consulta para obtener los datos de la inspección
    const fetchInspeccion = async () => {
      if (!idInspeccion) return;

      try {
        const inspeccionRef = doc(db, "linspecciones", idInspeccion);
        const inspeccionDoc = await getDoc(inspeccionRef);

        if (inspeccionDoc.exists()) {
          const inspeccionData = inspeccionDoc.data();
          const folioServicioRef = inspeccionData.folio_servicio;
          const servicioDoc = await getDoc(folioServicioRef);

          if (servicioDoc.exists()) {
            const servicioData = servicioDoc.data();
            const servicio = servicioData.folio;
            const inspeccionConFolio = {
              id: inspeccionDoc.id,
              ...inspeccionData,
              servicio
            };
            setInspeccion(inspeccionConFolio);
          } else {
            console.log("No existe el documento de servicio asociado");
          }
        } else {
          setInspeccion(null);
        }

        handleQueryCompletion();
      } catch (error) {
        console.log('Error al obtener la inspección:', error);
        handleQueryCompletion();
      }
    };

    // Ejecutar todas las consultas al cargar el componente
    getEmpresa();
    getCentro();
    getServicio();
    fetchInspeccion();

  }, []);

  //Numero random para que se cargue bien la imagen
  const randomNumber = Math.floor(Math.random() * 10000);

  // Fotos
  const photos = [
    inspeccion.photo1,
    inspeccion.photo2,
    inspeccion.photo3,
    inspeccion.photo4,
    inspeccion.photo5,
    inspeccion.photo6,
    inspeccion.photo7,
    inspeccion.photo8,
    inspeccion.photo9,
    inspeccion.photo10,
  ];

  //Slider
  const PrevArrow = ({ onClick }) => (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <FaChevronLeft />
    </div>
  );

  const NextArrow = ({ onClick }) => (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <FaChevronRight />
    </div>
  );

  // Slider cantidad de fotos
  const countComments = () => {
    let count = 0;
    for (let i = 1; i <= 10; ++i) {
      if (inspeccion['ob_photo' + i] !== "") {
        count++;
      }
    }
    if (count > 2) {
      return 2;
    } else {
      return count;
    }
  };
    //Setting Slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: countComments(),
    slidesToScroll: countComments(),
    responsive: [
      {
        breakpoint: 1024, // Puedes ajustar este valor según tus necesidades
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },      
    ],
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <Navbar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Inspecciones de Línea</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Inicios</Link>
              </li>
              {/* Vista Administrador */}
              {user[0].rol === "Administrador" && (
                <>
                  <li className="breadcrumb-item"><Link to={`/empresas/${id}`}>{empresa.name}</Link></li>
                  <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}`}>{centro.name}</Link></li>
                  <li className="breadcrumb-item"><Link to={`/empresas/${id}/centros/${idCentro}/servicios/${idServicio}`}>{`Servicio N°${servicio.folio}`}</Link></li>
                </>
              )}
              {/* Vista Colaborador*/}
              {user[0].rol === "Colaborador" && (
                 <li className="breadcrumb-item"><Link to={`/servicios/${idServicio}`}>{`Servicio N°${servicio.folio}`}</Link></li>
                )}              
              <li className='breadcrumb-item active'>{`Inspección N°${inspeccion.folio}`}</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <h2 style={{ paddingBottom: '10px' }}>{`Inspección N°${inspeccion.folio}`}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body pt-3">
                  {/* Bordered Tabs */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Descripción
                      </button>
                    </li>
                    <li className="nav-item">
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <div
                      className="tab-pane fade show active profile-overview"
                      id="profile-overview"
                    >
                      <h5 className="card-title">Inspección</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label ">Folio</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.folio}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Folio Servicio</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.servicio}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Nombre Línea</div>
                        <div className="col-lg-9 col-md-8">{`${inspeccion.nombre}`}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Composición Fondeo</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.composicion}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Anclaje</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.anclaje}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Profundidad</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.profundidad}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Suelo</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.suelo}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Link Video</div>
                        <div className="col-lg-9 col-md-8">
                          <Link to={inspeccion.video_url}>
                            {inspeccion.video_url}
                          </Link>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Status</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.status}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Comentario</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.comentario}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Observación Crítica</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.observacion}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Observación Leve</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.observacion_leve}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Levantantamiento Observación</div>
                        <div className="col-lg-9 col-md-8">{inspeccion.lev_observacion}</div>
                      </div>
                      <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                        {user[0].rol === "Administrador" && (
                          <Button variant="primary" onClick={() => navigate(`editar`)}>
                            Editar
                          </Button>
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade pt-3"
                      id="profile-change-password"
                    >
                    </div>
                  </div>
                  {/* End Bordered Tabs */}
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            {/* fotos */}
            <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
              <div className="col-12">
                <div className="card" >
                  <div className="card-body pt-3" >
                    <h5 className="card-title">Fotos</h5>
                    <Slider {...settings}                           
                      prevArrow={<PrevArrow />}
                      nextArrow={<NextArrow />}                        
                      className="centered-slider ">
                      {photos.map((photo, index) => (
                        (inspeccion[`ob_photo${index + 1}`] || photo) && (
                          <div key={index + 1}>
                            <img src={`${photo}?=${randomNumber}`} alt={`Foto ${index + 1}`} height={360} />
                            <p><b>{`Foto ${index + 1}`}</b></p>
                            <p>{inspeccion[`ob_photo${index + 1}`] === '' ? 'Sin comentario' : inspeccion[`ob_photo${index + 1}`]}</p>
                          </div>
                        )
                      ))}
                    </Slider>
                    <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                      <p></p>
                    </div>
                    {user[0].rol === "Administrador" && (
                      // Si el usuario es un administrador
                      <>
                        {photos.some(photo => photo !== "") && (
                          // Si la variable 'editar' es verdadera
                          <Button variant="primary" onClick={() => navigate(`editar-photos`)}>
                            Editar Fotos
                          </Button>
                        )}
                        {photos.every(photo => photo === "") && (
                          // Si al menos una foto no está vacía
                          <Button variant="primary" onClick={() => navigate(`cargar-photos`)}>
                            Cargar Fotos
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
      <Footer />
    </>
  );
}

export default LInspeccionesId;