/* React */
import React, { useState, useEffect } from "react";

/* CSS */
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/boxicons/css/boxicons.min.css";
import "../assets/vendor/quill/quill.snow.css";
import "../assets/vendor/quill/quill.bubble.css";
import "../assets/vendor/remixicon/remixicon.css";
import "../assets/vendor/simple-datatables/style.css";
import "../assets/css/style.css";

import { Card, Button, Form, Modal } from "react-bootstrap";

/* Rutas */
import { useParams } from "react-router-dom";

/* React Router  */
import { useNavigate, Link } from "react-router-dom";

/* Components */
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

/*Firestore */
import { collection, doc, query, getDocs, getDoc, addDoc, orderBy, limit, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { set } from "react-hook-form";

const DocumentosCrear = () => {

    const navigate = useNavigate();

    const { id, idCentro } = useParams();

    /* FileUpload */
    const storage = getStorage();

    //const { register, formState: { errors } } = useForm();
    //Servicios
    const [usersAdmins, setusersAdmins] = useState([]);
    const [usersColabs, setusersColab] = useState([]);

    //Folio Max
    const [folioMax, setFolioMax] = useState(0);

    let usersResp = []

    const [centro, setCentro] = useState([]);
    const [empresa, setEmpresa] = useState([]);

    // Carga de pagina 
    const [isLoading, setIsLoading] = useState(true);

    /*Modal de espera mientras de Guarda documento */
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
       
        // Función para obtener el próximo folio
        const getNextFolio = async () => {
            try {
                // Referencia a la colección 
                const serviciosRef = collection(db, "documentos");

                // Consulta ordenada por folio descendente 
                const q = query(
                    serviciosRef,
                    orderBy("folio", "desc"),
                    limit(1)
                );

                // Ejecutar la consulta
                const querySnapshot = await getDocs(q);

                // Mapear los datos
                const serviciosNum = querySnapshot.docs.map(doc => doc.data())

                setFolioMax(serviciosNum[0]['folio'] + 1);

            } catch (error) {
                console.log(error);

            }
        };

        const fetchEmpresa = async () => {
            if (!id) return;
      
            const empresaRef = doc(db, "empresa", id);
            const empresaSnapshot = await getDoc(empresaRef);
      
      
            if (empresaSnapshot.exists()) {
              const empresaData = empresaSnapshot.data();
              const nombreEmpresa = empresaData.name;
              const idEmpresa = empresaSnapshot.id;
      
              const emp = {
                name: nombreEmpresa,
                id: idEmpresa
              };
      
              setEmpresa(emp);
            }
          };
      
          const fetchCentro = async () => {
            if (!idCentro) return;
            const centroRef = doc(db, "centro", idCentro);
            try {
              const centroDoc = await getDoc(centroRef);
              if (centroDoc.exists()) {
                setCentro({ id: centroDoc.id, ...centroDoc.data() });
              } else {
                setCentro(null);
              }
            } catch (error) {
              console.log('Error al obtener la empresa:', error);
            } finally {
              setIsLoading(false);
            }
          };
        
        fetchEmpresa();
        fetchCentro();
        getNextFolio();      
    }, []);

    usersResp = usersAdmins.concat(usersColabs)


    /* Forms */
    const [formData, setFormData] = useState({
        folio: "",
        name: "",     
        tipo_doc: "",
        enlace: "",
        file: "",
        fileExtension:"",     
    })

    /* Cargar */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
            folio: folioMax,
        }));
    };

    // El archivo tendrá una restricción hasta 20MB
    const MAX_FILE_SIZE_BYTES = 20 * 1024 * 1024; // 20 MB en bytes

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        
        if (file) {
            // Verificar el tamaño del archivo
            if (file.size <= MAX_FILE_SIZE_BYTES) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        // Verificar si la extensión es jpg o png       
        // Continuar con la carga del archivo
        setFormData((prevState) => ({
            ...prevState,
            file: file,
            fileExtension: fileExtension,
        }));
    } else {        
        // Resetear el input de archivo
        alert('El archivo es demasiado grande. El tamaño máximo permitido es 20 MB.');
        event.target.value = null;
    }}    
    };

    /* Submit */
    const handleSubmit = async () => {

        // Campos obligatorios
        if (formData.name === "" || formData.tipo_doc === "") {
            alert("Todos los campos son obligatorios");
            return;
        }
        
        setShowModal(true);
        try {
            console.log("handleSubmit function called.");

            //Hora de chile
            const date = new Date(`${formData.date_create}T15:30:00.000-03:00`);

            const docRef = await addDoc(collection(db, "documentos"), {
                date_update: serverTimestamp(),
                date_create: serverTimestamp(),  
                name: formData.name,             
                folio: folioMax,               
                tipo_doc: formData.tipo_doc,
                enlace: formData.enlace,
                centro: doc(db, "centro", idCentro),
            });

            const docId = docRef.id;
            const documentRef = doc(db, "documentos", docId);

            if(formData.fileExtension !== ""){

                if (formData.fileExtension === 'jpg' || formData.fileExtension === 'png' || formData.fileExtension === 'jpeg') {
                    const storageRef = ref(storage, `docs/${docId}.${formData.fileExtension}`);
                    await uploadBytes(storageRef, formData.file);
                    const downloadUrl = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/docs/${docId}_640x360.${formData.fileExtension}`

                    const data = { enlace: downloadUrl };
                    await updateDoc(documentRef, data);
                } else {
                    const storageRef = ref(storage, `docs/${docId}.${formData.fileExtension}`);
                    await uploadBytes(storageRef, formData.file);
                    const downloadUrl = `https://storage.googleapis.com/pacificrov-7015f.appspot.com/docs/${docId}.${formData.fileExtension}`

                    const data = { enlace: downloadUrl };
                    await updateDoc(documentRef, data);
                }
            }

            setShowModal(false);

            alert(`El documento ${formData.folio} ha sido guardado`);
            navigate(`/empresas/${id}/centros/${idCentro}`);
        } catch (error) {
            alert(`Error al agregar documento: ${error}`);
        }
    };   

    if (isLoading) {
        return (
          <div>Cargando...</div>
        )
      }
    

    return (
        <>
            <Navbar />
            <main id="main" className="main ">
                <div className="pagetitle">
                    <h1>Añadir Documento</h1>
                    <nav>
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Inicio</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`/empresas/${empresa.id}`}>{empresa.name}</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to ={`/empresas/${empresa.id}/centros/${centro.id}`}>
                            {centro.name}
                            </Link>
                        </li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
                        <div className="col-12">
                            <Card className="card " style={{ maxWidth: "100%" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Documento</h5>
                                    {/* Vertical Form */}
                                    <Form>
                                        <Form.Group className="mb-3 d-none" >
                                            <Form.Label>Centro</Form.Label>
                                            <Form.Control type="text" name="centro" value={idCentro} disabled />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nombre<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control type="text" value={formData.name} onChange={handleChange} name="name" />
                                        </Form.Group>                                       
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tipo de Archivo<span style={{ color: 'red' }}> *</span></Form.Label>
                                            <Form.Control as="select" value={formData.tipo_doc} name="tipo_doc" onChange={handleChange}>
                                                <option value="">Seleccionar</option>
                                                <option value="Link">Link</option>
                                                <option value="Adjunto">Archivo Adjunto</option>
                                            </Form.Control>
                                        </Form.Group>
                                        {/* Archivo adjuntos */}
                                        {formData.tipo_doc === 'Adjunto' && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>Adjuntar Archivo <span style={{ fontSize: '0.65rem' }}>Hasta 20MB </span></Form.Label>
                                                <Form.Control type="file" name="document" required id="document" onChange={(event) => handleFileUpload(event, "docId")} />
                                            </Form.Group>
                                        )}
                                        {/* Link */}
                                        {formData.tipo_doc === 'Link' && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>Enlace Documento</Form.Label>
                                                <Form.Control type="text" value={formData.enlace} name="enlace" onChange={handleChange} />
                                            </Form.Group>
                                        )}

                                        <div className="text-center" style={{ display: "flex", gap: "5px" }}>
                                            <Button variant="primary" onClick={handleSubmit}>
                                                Guardar
                                            </Button>
                                        </div>
                                        <Modal
                                            show={showModal}
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Guardando Documento</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {`La Documento N°${formData.folio} se está guardando, por favor espere...`}
                                            </Modal.Body>
                                            <Modal.Footer>
                                            </Modal.Footer>
                                        </Modal>
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <Footer />
                </section>
            </main>
        </>
    )

};

export default DocumentosCrear;
